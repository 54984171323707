<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Kinerja Aset</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Infografis Aset</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardbiru">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Lokasi Aset</h3><br>&nbsp;

                                    <div class="card-tools">
                                        <div class="input-group input-group-sm">
                                            <input type="text" class="form-control" placeholder="Pencarian Lokasi"
                                                v-model="carikata">
                                            <div class="input-group-append">
                                                <div class="btn btn-primary">
                                                    <i class="fas fa-search"></i>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                            <div
                                                class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                                <div class="card bg-white d-flex flex-fill">
                                                    <div class="card-header text-muted border-bottom-0">
                                                        {{ datalist.kode }}
                                                    </div>
                                                    <div class="card-body pt-2"
                                                        :style="['background-image: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.6)), url(' + folder + datalist.filenya_dokumentasi + ');background-repeat: no-repeat;background-size: 100% auto;background-position: center top;']">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <h2 class="lead"><b>{{ datalist.nama }}</b></h2>
                                                                <p class="text-sm">
                                                                    <b>Jumlah Gedung: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahgedung == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]">{{
                                                                            datalist.jumlahgedung }}</span><br>
                                                                    <b>Jumlah Lantai: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahlantai == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]">{{
                                                                            datalist.jumlahlantai }}</span><br>
                                                                    <b>Jumlah Ruangan: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahruangan == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]">{{
                                                                            datalist.jumlahruangan }}</span><br>
                                                                    <b>Jumlah Infrastruktur Gedung Terdata: </b><span
                                                                        :class="[(datalist.jumlahdatainfrasterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]">{{
                                                                            datalist.jumlahdatainfrasterdata }}</span><br>
                                                                    <b>Status Infrastruktur Gedung Terdata: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahdatainfrasterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]"
                                                                        :style="[(datalist.jumlahdatainfrasterdata == 'belum ada data' ? 'color: ' + datalist.warnainfras : 'color: ' + datalist.warnainfras)]">{{
                                                                            datalist.nama_infras }} ({{
                                                                            G_numFormatKoma(datalist.jumlahskorinfrasterdata)
                                                                        }})</span><br>
                                                                    <b>Jumlah Aset Fisik Terdata:</b>
                                                                    <span
                                                                        :class="[(datalist.jumlahdataasetterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]">{{
                                                                            datalist.jumlahdataasetterdata }}</span><br>
                                                                    <b>Status Aset Fisik Terdata: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahdatainfrasterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]"
                                                                        :style="[(datalist.jumlahdataasetterdata == 'belum ada data' ? 'color: ' + datalist.warnaaset : 'color: ' + datalist.warnaaset)]">{{
                                                                            datalist.nama_aset }} ({{
                                                                            G_numFormatKoma(datalist.jumlahskorasetterdata)
                                                                        }})</span><br>
                                                                </p>
                                                                <div class="text-left">
                                                                    <a href="#" @click="prosesdataGedung(datalist.id)"
                                                                        class="btn btn-sm btn-sm bg-primary text-white">
                                                                        <i class="fas fa-chevron-circle-right"></i>
                                                                        Detail
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="col-12 col-sm-6 col-md-8 d-flex align-items-stretch flex-column">
                                                <div class="card bg-light d-flex flex-fill">
                                                    <div class="card-body pt-2">
                                                        <div class="row">
                                                            <div class="col-6">
                                                                <h6><b>Perawatan Fisik Aset</b></h6>
                                                                <p class="text-muted text-sm">
                                                                    <b>Jumlah Perawatan Fisik Aset Terdata: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahdatakondisiterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]">{{
                                                                            datalist.jumlahdatakondisiterdata }}</span><br>
                                                                    <b>Status: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahdatakondisiterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]"
                                                                        :style="[(datalist.jumlahdatakondisiterdata == 'belum ada data' ? 'color: ' + datalist.warnakondisi : 'color: ' + datalist.warnakondisi)]">{{
                                                                            datalist.nama_kondisi }}</span>
                                                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                                                    <li class="large"><span class="fa-li"><i
                                                                                class="fas fa-lg fa-clipboard-check"></i></span>
                                                                        Nilai Skor: {{
                                                                            G_numFormatKoma(datalist.jumlahskorkondisiterdata)
                                                                        }}
                                                                    </li>
                                                                    <li>&nbsp;</li>
                                                                    <li>
                                                                        <div class="col-sm-12 text-left">
                                                                            <div class="progress">
                                                                                <div class="progress-bar progress-bar-striped bg-success"
                                                                                    role="progressbar" aria-valuenow="0"
                                                                                    aria-valuemin="0" aria-valuemax="99"
                                                                                    :style="'width:' + datalist.jumlahskorkondisiterdata + '%'">
                                                                                    {{
                                                                                        G_numFormatKoma(datalist.jumlahskorkondisiterdata)
                                                                                    }}%
                                                                                </div>
                                                                                <div class="progress-bar progress-bar-striped bg-warning"
                                                                                    role="progressbar"
                                                                                    :style="'width:' + (100 - datalist.jumlahskorkondisiterdata) + '%'"
                                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                                    aria-valuemax="99">{{
                                                                                        G_numFormatKoma(100 -
                                                                                            datalist.jumlahskorkondisiterdata)
                                                                                    }}%</div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>&nbsp;</li>
                                                                    <li class="small text-hijau"><span class="fa-li"><i
                                                                                class="fas fa-lg fa-chart-line text-hijau"></i></span>
                                                                        Capaian kinerja
                                                                    </li>
                                                                    <li class="small text-warning"><span
                                                                            class="fa-li"><i
                                                                                class="fas fa-lg fa-chart-line text-warning"></i></span>
                                                                        Deviasi ke ideal
                                                                    </li>
                                                                </ul>
                                                                </p>
                                                                <div class="card-footer">
                                                                    <div class="text-left">
                                                                        <a href="#"
                                                                            @click="prosesdataGedungFisik(datalist.id)"
                                                                            class="btn btn-sm btn-sm bg-primary text-white">
                                                                            <i class="fas fa-chevron-circle-right"></i>
                                                                            Detail
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <h6><b>Perawatan Fungsi Aset</b></h6>
                                                                <p class="text-muted text-sm">
                                                                    <b>Jumlah Perawatan Fungsi Aset Terdata: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahdatafungsiterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]">{{
                                                                            datalist.jumlahdatafungsiterdata }}</span><br>
                                                                    <b>Status: </b>
                                                                    <span
                                                                        :class="[(datalist.jumlahdatafungsiterdata == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]"
                                                                        :style="[(datalist.jumlahdatafungsiterdata == 'belum ada data' ? 'color: ' + datalist.warnafungsi : 'color: ' + datalist.warnafungsi)]">{{
                                                                            datalist.nama_fungsi }}</span>
                                                                <ul class="ml-4 mb-0 fa-ul text-muted">
                                                                    <li class="large"><span class="fa-li"><i
                                                                                class="fas fa-lg fa-clipboard-check"></i></span>
                                                                        Nilai Skor: {{
                                                                            G_numFormatKoma(datalist.jumlahskorfungsiterdata)
                                                                        }}
                                                                    </li>
                                                                    <li>&nbsp;</li>
                                                                    <li>
                                                                        <div class="col-sm-12 text-left">
                                                                            <div class="progress">
                                                                                <div class="progress-bar progress-bar-striped bg-success"
                                                                                    role="progressbar" aria-valuenow="0"
                                                                                    aria-valuemin="0" aria-valuemax="99"
                                                                                    :style="'width:' + datalist.jumlahskorfungsiterdata + '%'">
                                                                                    {{
                                                                                        G_numFormatKoma(datalist.jumlahskorfungsiterdata)
                                                                                    }}%
                                                                                </div>
                                                                                <div class="progress-bar progress-bar-striped bg-warning"
                                                                                    role="progressbar"
                                                                                    :style="'width:' + (100 - datalist.jumlahskorfungsiterdata) + '%'"
                                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                                    aria-valuemax="99">{{
                                                                                        G_numFormatKoma(100 -
                                                                                            datalist.jumlahskorfungsiterdata)
                                                                                    }}%</div>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>&nbsp;</li>
                                                                    <li class="small text-hijau"><span class="fa-li"><i
                                                                                class="fas fa-lg fa-chart-line text-hijau"></i></span>
                                                                        Capaian kinerja
                                                                    </li>
                                                                    <li class="small text-warning"><span
                                                                            class="fa-li"><i
                                                                                class="fas fa-lg fa-chart-line text-warning"></i></span>
                                                                        Deviasi ke ideal
                                                                    </li>
                                                                </ul>
                                                                </p>
                                                                <div class="card-footer">
                                                                    <div class="text-left">
                                                                        <a href="#"
                                                                            @click="prosesdataGedungFungsi(datalist.id)"
                                                                            class="btn btn-sm btn-sm bg-primary text-white">
                                                                            <i class="fas fa-chevron-circle-right"></i>
                                                                            Detail
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="card-footer clearfix">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            lokasiluser: JSON.parse(localStorage.getItem("loksistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
            datamaster: [],
            datapesanmaster: [],
            carikata: '',
            folder: process.env.VUE_APP_URL_API + 'view_oripro-GambarAset?f=',
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        prosesdataGedungFisik(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/DetailKinerjaAsetGedungFisik/" + bytes);
        },
        prosesdataGedungFungsi(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/DetailKinerjaAsetGedungFungsi/" + bytes);
        },
        prosesdataGedung(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/DetailKinerjaAsetGedung/" + bytes);
        },
        async AmbilData() {
            this.halamanloading = true;
            var kodelokasi = this.lokasiluser;
            if (kodelokasi == 0) {
                kodelokasi = '';
            }
            this.datamaster = [];
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("kinaset_oripro-GetKinerjaLokasi?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>