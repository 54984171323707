<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Manajemen Data Pegawai</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/MPegawai">Data Pegawai</a></li>
                                <li class="breadcrumb-item active">Proses Data</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Formulir Input Data Pegawai</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>NIP <small class="text-info"> (*Isikan NIP Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nip"
                                                    name="nip" placeholder="NIP" v-model="nip">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama <small class="text-info"> (*Isikan Nama Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-book-reader"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama"
                                                    name="nama" placeholder="Nama" v-model="nama">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Organisasi / OPD <small class="text-info"> (*Pilih
                                                    Data)</small></label>
                                            <v-select :options="organisasi" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedOrganisasi"
                                                @update:modelValue="onorganisasiChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Divisi / Bidang <small class="text-info"> (*Pilih
                                                    Data)</small></label>
                                            <v-select :options="bidang" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedBidang"
                                                @update:modelValue="onbidangChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="col-sm-12">&nbsp;</div>
                                            <label>Unit / SubBidang <small class="text-info"> (*Pilih
                                                    Data)</small></label>
                                            <v-select :options="subbidang" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedSubBidang"
                                                @update:modelValue="onsubbidangChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="col-sm-12">&nbsp;</div>
                                            <label>Jabatan <small class="text-info"> (*Pilih
                                                    Data)</small></label>
                                            <v-select :options="jabatan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedJabatan"
                                                @update:modelValue="onjabatanChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="col-sm-12">&nbsp;</div>
                                            <label>Pangkat <small class="text-info"> (*Isikan Pangkat
                                                    Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-user-tie"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pangkat"
                                                    name="pangkat" placeholder="Pangkat" v-model="pangkat">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="col-sm-12">&nbsp;</div>
                                            <label>Level Pengguna<small class="text-info"> (*Pilih
                                                    Level Pengguna Pegawai)</small></label>
                                            <v-select :options="level" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedLevel"
                                                @update:modelValue="onlevelChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Atasan Langsung <small class="text-info"> (*Pilih
                                                    Data - Kosongkan Jika Tidak Ada)</small></label>
                                            <v-select :options="atasan_langsung" :reduce="(label) => label.code"
                                                label="label" v-model="defaultSelectedAtasanLangsung"
                                                @update:modelValue="onatasan_langsungChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tanggal Bergabung <small class="text-info"> (*Isikan Tanggal
                                                    Bergabung Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-calendar-alt"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm" id="tgl_gabung"
                                                    name="tgl_gabung" placeholder="Tanggal Bergabung"
                                                    v-model="tgl_gabung">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tanggal Mulai Kontrak <small class="text-info"> (*Isikan Tanggal
                                                    Mulai Kontrak Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-calendar-check"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm"
                                                    id="tgl_kontrak_mulai" name="tgl_kontrak_mulai"
                                                    placeholder="Tanggal Mulai Kontrak" v-model="tgl_kontrak_mulai">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tanggal Selesai Kontrak <small class="text-info"> (*Isikan Tanggal
                                                    Selesai Kontrak Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-calendar-times"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm"
                                                    id="tgl_kontrak_selesai" name="tgl_kontrak_selesai"
                                                    placeholder="Tanggal Selesai Kontrak" v-model="tgl_kontrak_selesai">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Akses Lokasi <small class="text-info"> (*Pilih
                                                    Akses Lokasi Yang Menjadi Tanggungjawab Pegawai)</small></label>
                                            <v-select :options="lokasi" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedLokasi"
                                                @update:modelValue="onlokasiChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat <small class="text-info"> (*Isikan Alamat
                                                    Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-address-card"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="alamat"
                                                    name="alamat" placeholder="Alamat" v-model="alamat">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Email <small class="text-info"> (*Isikan Email
                                                    Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-envelope"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="email"
                                                    name="email" placeholder="Email" v-model="email">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>No. Telepon <small class="text-info"> (*Isikan No. Telepon
                                                    Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-phone-square"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="telp"
                                                    name="telp" placeholder="Telp" v-model="telp">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Username <small class="text-info"> (*Isikan Username
                                                    Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-grin-beam"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm"
                                                    id="username_user" name="username_user" placeholder="Username"
                                                    v-model="username_user" :readonly="(secretencData != 'Tambah')">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Password <small class="text-info"> (*Isikan Password
                                                    Pegawai)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i class="fas fa-key"></i></span>
                                                </div>
                                                <input type="password" class="form-control form-control-sm"
                                                    id="password_user" name="password_user" placeholder="Password"
                                                    v-model="password_user" :readonly="(secretencData != 'Tambah')">
                                            </div>
                                        </div>
                                        <!--GAMBAR 1-->
                                        <div class="col-sm-12">
                                            <label>Foto<small class="text-info"> (*images files)</small>
                                            </label>
                                            <div class="col-sm-12" v-if="folder_foto != ''">
                                                <ul class="users-list clearfix">
                                                    <li>
                                                        <img :src="folder_foto" alt="User Image"
                                                            class="img-size-75 img-circle animation__wobble"
                                                            id="foto_profile">
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_foto" name="fileToUploadfilenya_foto"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_foto" name="filenya_foto" placeholder="File Dokumentasi"
                                                    v-model="filenya_foto">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-10">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_foto">
                                                            <span id="tulisanpersenfilenya_foto">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_foto"></span>
                                                </div>
                                                <div class="col-sm-2 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfileuser('filenya_foto', 'image/*')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfileuser('filenya_foto')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--GAMBAR 1 END-->
                                    </div>
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    <button type="button" class="btn btn-sm btn-success float-left text-white lebar"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')"><i
                                            class="fas fa-plus-square"></i> Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success float-left text-white lebar"
                                        v-if="(countdata > 0)" @click="update()"><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-secondary float-left text-white lebar"
                                        @click="batalproses()"><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import vSelect from 'vue-select';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, swal, vSelect
    },
    data() {
        return {
            validasi: false,
            halamanloading: true,
            datamaster: [],
            datapesan: [],
            countdata: 0,
            nip: '',
            nama: '',
            organisasi: [],
            dataorganisasi: [],
            defaultSelectedOrganisasi: {
                code: 0,
                label: 'Pilih Salah Satu',
            },
            selectedorganisasi: 0,

            bidang: [],
            databidang: [],
            defaultSelectedBidang: {
                code: 0,
                label: 'Pilih Salah Satu',
            },
            selectedbidang: 0,

            subbidang: [],
            datasubbidang: [],
            defaultSelectedSubBidang: {
                code: 0,
                label: 'Pilih Salah Satu',
            },
            selectedsubbidang: 0,

            jabatan: [],
            datajabatan: [],
            defaultSelectedJabatan: {
                code: 0,
                label: 'Pilih Salah Satu',
            },
            selectedjabatan: 0,

            atasan_langsung: [],
            dataatasan_langsung: [],
            defaultSelectedAtasanLangsung: {
                code: 0,
                label: 'Pilih Salah Satu(-)',
                tag: 0,
            },
            selectedatasan_langsung: 0,

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Lokasi',
                tag: 0,
            },
            selectedlokasi: 0,

            level: [],
            datalevel: [],
            defaultSelectedLevel: {
                code: 0,
                label: 'Pilih Salah Satu',
            },
            selectedlevel: 0,

            pangkat: '',
            tgl_gabung: '',
            tgl_kontrak_mulai: '',
            tgl_kontrak_selesai: '',
            alamat: '',
            email: '',
            telp: '',
            filenya_foto: '',
            attach_filenya_foto: '',
            username_user: '',
            password_user: '',
            folder_foto: '',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
        }
    },
    methods: {
        onorganisasiChange(a) {
            try {
                this.selectedorganisasi = this.organisasi[a].tag;
                this.KosongkanBidang();
                this.KosongkanSubBidang();
                this.AmbilDataBidangTerpilih(this.selectedorganisasi);
                this.AmbilDataAtasanTerpilih(this.selectedorganisasi, this.selectedbidang, this.selectedsubbidang, this.selectedlevel, this.secretencData);
            }
            catch (err) {
                return;
            }
        },
        onbidangChange(a) {
            try {
                this.selectedbidang = this.bidang[a].tag;
                this.AmbilDataSubBidangTerpilih(this.selectedorganisasi, this.selectedbidang);
                this.AmbilDataAtasanTerpilih(this.selectedorganisasi, this.selectedbidang, this.selectedsubbidang, this.selectedlevel, this.secretencData);
            }
            catch (err) {
                return;
            }
        },
        onsubbidangChange(a) {
            try {
                this.selectedsubbidang = this.subbidang[a].tag;
                this.AmbilDataAtasanTerpilih(this.selectedorganisasi, this.selectedbidang, this.selectedsubbidang, this.selectedlevel, this.secretencData);
            }
            catch (err) {
                return;
            }
        },
        onjabatanChange(a) {
            try {
                this.selectedjabatan = this.jabatan[a].tag;
                this.AmbilDataAtasanTerpilih(this.selectedorganisasi, this.selectedbidang, this.selectedsubbidang, this.selectedlevel, this.secretencData);
            }
            catch (err) {
                return;
            }
        },
        onatasan_langsungChange(a) {
            try {
                this.selectedatasan_langsung = this.atasan_langsung[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onlevelChange(a) {
            try {
                this.selectedlevel = this.level[a].tag;
                this.AmbilDataAtasanTerpilih(this.selectedorganisasi, this.selectedbidang, this.selectedsubbidang, this.selectedlevel, this.secretencData);
            }
            catch (err) {
                return;
            }
        },
        async AmbilDataOrganisasi() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("morg_oripro-GetOrganisasiInstitusi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataorganisasi = [];
                        this.organisasi = [];
                        this.dataorganisasi = Response.data.content.data;
                        this.dataorganisasi.forEach((item) => {
                            this.organisasi.push({
                                label: item.nama,
                                code: this.dataorganisasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });

                        if (this.leveluser < 4) {
                            this.organisasi.unshift({
                                label: 'Pilih Salah Satu',
                                code: '0',
                            });
                        }
                        if (this.leveluser >= 4) {
                            this.defaultSelectedOrganisasi = {
                                code: this.kdeorganisasi,
                                label: this.nmkdorganisasi,
                            };
                            this.selectedorganisasi = this.kdeorganisasi;

                            this.defaultSelectedBidang = {
                                code: this.kdebidang,
                                label: this.nmkdbidang,
                            };
                            this.selectedbidang = this.kdebidang;

                            this.defaultSelectedSubBidang = {
                                code: this.kdesubbidang,
                                label: this.nmkdsubbidang,
                            };
                            this.selectedsubbidang = this.kdesubbidang;
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("morg_oripro-GetJabatanInstitusi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajabatan = [];
                        this.jabatan = [];
                        this.datajabatan = Response.data.content.data;
                        this.datajabatan.forEach((item) => {
                            this.jabatan.push({
                                label: item.nama,
                                code: this.datajabatan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jabatan.unshift({
                            label: 'Pilih Salah Satu',
                            code: '0',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLokasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lokasi.unshift({
                            label: 'Semua Lokasi',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI5 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI5.get("m_peg_oripro-GetLevelPegawaiNonSuperAdmin?kdlevellebihrendah=" + this.leveluser + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalevel = [];
                        this.level = [];
                        this.datalevel = Response.data.content.data;
                        this.datalevel.forEach((item) => {
                            this.level.push({
                                label: item.nama,
                                code: this.datalevel.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.level.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        AmbilDataAtasanTerpilih(kdorganisasi, kdbidang, kdsubbidang, kdlevellebihtinggi, kdid) {

            if (this.secretencData == 'Tambah') {
                var kode_kdid = '';
            }
            else {
                var kode_kdid = kdid;
            }

            if (kdorganisasi === undefined) {
                this.KosongkanAtasan();
                return false;
            }
            else {
                var kode_kdorganisasi = kdorganisasi;
            }

            if (kdbidang === undefined) {
                this.KosongkanAtasan();
                return false;
            }
            else {
                var kode_kdbidang = kdbidang;
            }

            if (kdsubbidang === undefined) {
                this.KosongkanAtasan();
                return false;
            }
            else {
                var kode_kdsubbidang = kdsubbidang;
            }

            if (kdlevellebihtinggi === undefined) {
                this.KosongkanAtasan();
                return false;
            }
            else {
                var kode_kdlevellebihtinggi = kdlevellebihtinggi;
            }
            this.halamanloading = true;
            const mainAPI3 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            //alert(kode_kdlevellebihtinggi);
            if (kode_kdlevellebihtinggi <= 4) {
                mainAPI3.get("m_peg_oripro-GetPegawaiNonSuperAdmin?kdlevellebihtinggi=" + kode_kdlevellebihtinggi + "&random=" + random).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.dataatasan_langsung = [];
                            this.atasan_langsung = [];
                            this.dataatasan_langsung = Response.data.content.data;
                            this.dataatasan_langsung.forEach((item) => {
                                this.atasan_langsung.push({
                                    label: item.nama + '(' + item.namajabatan + ')',
                                    code: this.dataatasan_langsung.indexOf(item) + 1,
                                    tag: item.id,
                                });
                            });
                            this.atasan_langsung.unshift({
                                label: 'Pilih Salah Satu (-)',
                                code: 0,
                                tag: 0,
                            });
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.ClearlocalStorage();
                    this.halamanloading = false;
                });
            }
            else {
                mainAPI3.get("m_peg_oripro-GetPegawaiNonSuperAdmin?kdorganisasi=" + kode_kdorganisasi
                    + "&kdbidang=" + kode_kdbidang + "&kdsubbidang=&kdlevellebihtinggi=" + kode_kdlevellebihtinggi + "&id=" + kode_kdid + "&random=" + random).then(
                        Response => {
                            this.datapesan = Response.data.message;
                            if (this.datapesan == 'data diketemukan') {
                                this.dataatasan_langsung = [];
                                this.atasan_langsung = [];
                                this.dataatasan_langsung = Response.data.content.data;
                                this.dataatasan_langsung.forEach((item) => {
                                    this.atasan_langsung.push({
                                        label: item.nama + '(' + item.namajabatan + ')',
                                        code: this.dataatasan_langsung.indexOf(item) + 1,
                                        tag: item.id,
                                    });
                                });
                                this.atasan_langsung.unshift({
                                    label: 'Pilih Salah Satu (-)',
                                    code: 0,
                                    tag: 0,
                                });
                            }
                            this.halamanloading = false;
                        }
                    ).catch(error => {
                        console.log(error);
                        this.ClearlocalStorage();
                        this.halamanloading = false;
                    });
            }
        },
        async AmbilDataBidangTerpilih(kdorganisasi) {
            var kode_bidang = '';
            if (kdorganisasi === undefined) {
                this.KosongkanBidang();
                return false;
            }
            else {
                var kode = kdorganisasi;
            }

            if (this.leveluser >= 4) {
                kode_bidang = this.kdebidang;
            }
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("morg_oripro-GetBidangInstitusi?kdorganisasi=" + kode + "&kd=" + kode_bidang + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databidang = [];
                        this.bidang = [];
                        this.databidang = Response.data.content.data;
                        this.databidang.forEach((item) => {
                            this.bidang.push({
                                label: item.nama,
                                code: this.databidang.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });

                        if (this.leveluser < 4) {
                            this.bidang.unshift({
                                label: 'Pilih Salah Satu',
                                code: '0',
                            });
                            if (this.secretencData == 'Tambah') {
                                this.selectedbidang = 0;
                                this.defaultSelectedBidang = {
                                    code: 0,
                                    label: 'Pilih Salah Satu',
                                };
                            }
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        async AmbilDataSubBidangTerpilih(kdorganisasi, kdbidang) {
            var kode_subbidang = '';
            if (kdorganisasi === undefined) {
                this.KosongkanBidang();
                this.KosongkanSubBidang();
                return false;
            }
            else if (kdorganisasi == 0) {
                this.KosongkanBidang();
                this.KosongkanSubBidang();
                return false;
            }
            else if (kdbidang === undefined) {
                this.KosongkanSubBidang();
                return false;
            }
            else if (kdbidang == 0) {
                this.KosongkanSubBidang();
                return false;
            }
            else {
                var kode = kdorganisasi;
                var kodebidang = kdbidang;
            }

            if (this.leveluser >= 4) {
                kode_subbidang = this.kdesubbidang;
            }
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("morg_oripro-GetSubBidangInstitusi?kdorganisasi=" + kode + "&kdbidang=" + kodebidang + "&kd=" + kode_subbidang + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasubbidang = [];
                        this.subbidang = [];
                        this.datasubbidang = Response.data.content.data;
                        this.datasubbidang.forEach((item) => {
                            this.subbidang.push({
                                label: item.nama,
                                code: this.datasubbidang.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });

                        if (this.leveluser < 4) {
                            this.subbidang.unshift({
                                label: 'Pilih Salah Satu',
                                code: '0',
                            });

                            if (this.secretencData == 'Tambah') {
                                this.selectedsubbidang = 0;
                                this.defaultSelectedSubBidang = {
                                    code: 0,
                                    label: 'Pilih Salah Satu',
                                };
                            }
                        }

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        KosongkanBidang() {
            this.databidang = [];
            this.bidang = [];
            this.defaultSelectedBidang = {
                code: 0,
                label: 'Pilih Salah Satu',
            };
            this.selectedbidang = 0;
            this.KosongkanSubBidang();
        },
        KosongkanAtasan() {
            this.atasan_langsung = [];
            this.dataatasan_langsung = [];
            this.defaultSelectedAtasanLangsung = {
                code: 0,
                label: 'Pilih Salah Satu(-)',
                tag: 0,
            };
            this.selectedatasan_langsung = 0;
        },
        KosongkanSubBidang() {
            this.datasubbidang = [];
            this.subbidang = [];
            this.defaultSelectedSubBidang = {
                code: 0,
                label: 'Pilih Salah Satu',
            };
            this.selectedsubbidang = 0;
        },
        initializeSelect2() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("m_peg_oripro-GetbyIDPegawai?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    //console.log(Response.data.content.data);
                    this.nip = Response.data.content.data[0].nip;
                    this.nama = Response.data.content.data[0].nama;
                    this.defaultSelectedJabatan = {
                        code: Response.data.content.data[0].jabatan,
                        label: Response.data.content.data[0].namajabatan,
                        tag: Response.data.content.data[0].jabatan,
                    };
                    this.selectedjabatan = Response.data.content.data[0].jabatan;
                    this.pangkat = Response.data.content.data[0].pangkat;
                    this.tgl_gabung = Response.data.content.data[0].tgl_gabung;
                    this.tgl_kontrak_mulai = Response.data.content.data[0].tgl_kontrak_mulai;
                    this.tgl_kontrak_selesai = Response.data.content.data[0].tgl_kontrak_selesai;

                    this.defaultSelectedLevel = {
                        code: Response.data.content.data[0].level,
                        label: Response.data.content.data[0].namalevel,
                        tag: Response.data.content.data[0].level,
                    };
                    this.selectedlevel = Response.data.content.data[0].level;
                    if (Response.data.content.data[0].lokasi == 0) {
                        this.defaultSelectedLokasi = {
                            label: 'Semua Lokasi',
                            code: 0,
                            tag: 0,
                        };
                    }
                    else {
                        this.defaultSelectedLokasi = {
                            code: Response.data.content.data[0].lokasi,
                            label: Response.data.content.data[0].namalokasi,
                            tag: Response.data.content.data[0].lokasi,
                        };
                    }

                    this.selectedlokasi = Response.data.content.data[0].lokasi;

                    this.alamat = Response.data.content.data[0].alamat;
                    this.email = Response.data.content.data[0].email;
                    this.telp = Response.data.content.data[0].telp;
                    this.filenya_foto = Response.data.content.data[0].foto;
                    this.folder_foto = process.env.VUE_APP_URL_API + 'view_oripro-GambarUser?f=' + Response.data.content.data[0].foto;
                    this.username_user = Response.data.content.data[0].username;
                    this.password_user = Response.data.content.data[0].password;

                    this.countdata = Response.data.countdata;

                    this.defaultSelectedOrganisasi = {
                        code: Response.data.content.data[0].organisasi,
                        label: Response.data.content.data[0].namaorganisasi,
                        tag: Response.data.content.data[0].organisasi,
                    };
                    this.selectedorganisasi = Response.data.content.data[0].organisasi;
                    this.KosongkanBidang();
                    this.KosongkanSubBidang();
                    this.defaultSelectedBidang = {
                        code: Response.data.content.data[0].bidang,
                        label: Response.data.content.data[0].namabidang,
                        tag: Response.data.content.data[0].bidang,
                    };
                    this.selectedbidang = Response.data.content.data[0].bidang;
                    this.AmbilDataBidangTerpilih(this.selectedorganisasi);

                    this.defaultSelectedSubBidang = {
                        code: Response.data.content.data[0].subbidang,
                        label: Response.data.content.data[0].namasubbidang,
                        tag: Response.data.content.data[0].subbidang,
                    };
                    this.selectedsubbidang = Response.data.content.data[0].subbidang;
                    this.AmbilDataSubBidangTerpilih(this.selectedorganisasi, this.selectedbidang);


                    this.AmbilDataAtasanTerpilih(this.selectedorganisasi, this.selectedbidang, this.selectedsubbidang, this.selectedlevel, Response.data.content.data[0].id);
                    this.defaultSelectedAtasanLangsung = {
                        code: Response.data.content.data[0].atasan_langsung,
                        label: Response.data.content.data[0].namaatasan_langsung + '(' + Response.data.content.data[0].namajabatanatasan_langsung + ')',
                        tag: Response.data.content.data[0].atasan_langsung,
                    };
                    this.selectedatasan_langsung = Response.data.content.data[0].atasan_langsung;
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.halamanloading = false;
            })
        },
        validasidata() {
            if (document.getElementById('nip').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nip').focus();
                return false;
            }
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if (this.selectedorganisasi == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Organisasi',
                });
                return false;
            }
            if (this.selectedorganisasi === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Organisasi',
                });
                return false;
            }
            if (this.selectedbidang == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Bidang',
                });
                return false;
            }
            if (this.selectedbidang === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Bidang',
                });
                return false;
            }
            if (this.selectedsubbidang == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data SubBidang',
                });
                return false;
            }
            if (this.selectedsubbidang === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data SubBidang',
                });
                return false;
            }
            if (this.selectedjabatan == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Jabatan',
                });
                return false;
            }
            if (this.selectedjabatan === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Jabatan',
                });
                return false;
            }

            if (this.selectedlevel == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Level Pengguna',
                });
                return false;
            }
            if (this.selectedlevel === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data Level Pengguna',
                });
                return false;
            }
            if (document.getElementById('pangkat').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('pangkat').focus();
                return false;
            }
            if (document.getElementById('tgl_gabung').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tgl_gabung').focus();
                return false;
            }
            if (document.getElementById('tgl_kontrak_mulai').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tgl_kontrak_mulai').focus();
                return false;
            }
            if (document.getElementById('tgl_kontrak_selesai').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tgl_kontrak_selesai').focus();
                return false;
            }
            if (document.getElementById('alamat').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('alamat').focus();
                return false;
            }
            if (document.getElementById('username_user').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('username_user').focus();
                return false;
            }
            if (document.getElementById('password_user').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('password_user').focus();
                return false;
            }

            this.validasi = true;
            return;
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var fd = new FormData();
            fd.append("nip", this.nip);
            fd.append("nama", this.nama);
            fd.append("organisasi", this.selectedorganisasi);
            fd.append("bidang", this.selectedbidang);
            fd.append("subbidang", this.selectedsubbidang);
            fd.append("jabatan", this.selectedjabatan);
            fd.append("lokasi", this.selectedlokasi);
            fd.append("level", this.selectedlevel);
            fd.append("atasan_langsung", this.selectedatasan_langsung);
            fd.append("pangkat", this.pangkat);
            fd.append("tgl_gabung", this.tgl_gabung);
            fd.append("tgl_kontrak_mulai", this.tgl_kontrak_mulai);
            fd.append("tgl_kontrak_selesai", this.tgl_kontrak_selesai);
            fd.append("alamat", this.alamat);
            fd.append("email", this.email);
            fd.append("telp", this.telp);
            fd.append("foto", document.getElementById('filenya_foto').value);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("m_peg_oripro-UpdatePegawai", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/MPegawai");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var fd = new FormData();
            fd.append("nip", this.nip);
            fd.append("nama", this.nama);
            fd.append("organisasi", this.selectedorganisasi);
            fd.append("bidang", this.selectedbidang);
            fd.append("subbidang", this.selectedsubbidang);
            fd.append("jabatan", this.selectedjabatan);
            fd.append("lokasi", this.selectedlokasi);
            fd.append("level", this.selectedlevel);
            fd.append("atasan_langsung", this.selectedatasan_langsung);
            fd.append("pangkat", this.pangkat);
            fd.append("tgl_gabung", this.tgl_gabung);
            fd.append("tgl_kontrak_mulai", this.tgl_kontrak_mulai);
            fd.append("tgl_kontrak_selesai", this.tgl_kontrak_selesai);
            fd.append("alamat", this.alamat);
            fd.append("email", this.email);
            fd.append("telp", this.telp);
            fd.append("username", this.username_user);
            fd.append("password", this.password_user);
            fd.append("foto", document.getElementById('filenya_foto').value);

            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("add_peg_oripro-AddPegawai", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/MPegawai");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        batalproses() {
            window.location.replace("/MPegawai");
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        clearfileuser(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", 'filenya_user');
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        uploadfileuser(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", 'filenya_user');
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
    },
    mounted() {
        this.initializeSelect2();
        this.getOtentifikasi();
        this.AmbilDataOrganisasi();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>