<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Detail Kinerja Aset {{ namalokasi }}</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/KinerjaAset">Infografis Aset</a></li>
                                <li class="breadcrumb-item active">Detail Kinerja Aset</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardbiru">
                                <div class="card-header border-transparent">
                                    <div class="row">
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control" placeholder="Pencarian Gedung"
                                                v-model="carikata">
                                        </div>
                                        <div class="col-sm-1 text-center">
                                            <div class="input-group-append text-center">
                                                <div class="btn btn-primary">
                                                    <i class="fas fa-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                <div class="card collapsed-card">
                                    <div class="cardgray card-header border-transparent">
                                        {{ urutlist + 1 }}. {{ datalist.kode }} - {{ datalist.nama }}
                                        <div class="card-tools">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                                    class="fas fa-plus text-white"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row invoice-info">
                                            <div class="col-sm-3 invoice-col">
                                                <img :src="folder + datalist.filenya_gedung"
                                                    class="img-size-75 animation__wobble" alt="Gedung Image"
                                                    style="height: 100px;"><br><br>
                                                <address>
                                                    <strong>Skor: <span class="text-info">{{
                                                        G_numFormatKoma(datalist.skor) }}</span> </strong><br>
                                                    <strong>Tanggal Data: <span class="text-info">{{
                                                        G_formatDateNonWIB(datalist.tanggal_data) }}</span>
                                                    </strong><br>
                                                </address>
                                            </div>
                                            <div class="col-sm-3 invoice-col">
                                                <address>
                                                    <strong><span class="text-info">{{ datalist.id_gedung }}</span>
                                                    </strong><br>
                                                    <strong>Luas: <span class="text-info">{{
                                                        G_numFormatKoma(datalist.luas_m2) }}</span> m<sup>2</sup>
                                                    </strong><br>
                                                    <strong>Panjang: <span class="text-info">{{
                                                        G_numFormatKoma(datalist.panjang) }}</span> m </strong><br>
                                                    <strong>Lebar: <span class="text-info">{{
                                                        G_numFormatKoma(datalist.lebar) }}</span> m </strong><br>
                                                    <strong>Tinggi: <span class="text-info">{{
                                                        G_numFormatKoma(datalist.tinggi) }}</span> m </strong><br>
                                                    <strong>Bentuk Bangunan: <span class="text-info">{{
                                                        datalist.nmbentuk_bangunan }}</span> </strong><br>
                                                    <strong>Status Penggunaan: <span class="text-info">{{
                                                        datalist.nmstatus_penggunaan }}</span> </strong><br>
                                                    <strong>Jumlah Lantai: <span class="text-info">{{
                                                        datalist.jumlah_lantai }}</span> </strong><br>
                                                    <strong>Penanggungjawab: <span class="text-info">{{
                                                        datalist.nmpj_aset }}</span> </strong><br>
                                                </address>
                                            </div>
                                            <div class="col-sm-3 invoice-col">
                                                <address>
                                                    <strong><span class="text-success">Struktur Bangunan</span>
                                                    </strong><br>
                                                    <strong>Struktur Atap: <span class="text-info">{{
                                                        datalist.nmstruktur_atap }}</span> </strong><br>
                                                    <strong>Atap: <span class="text-info">{{ datalist.nmatap }}</span>
                                                    </strong><br>
                                                    <strong>Struktur Dinding: <span class="text-info">{{
                                                        datalist.nmstruktur_dinding }}</span> </strong><br>
                                                    <strong>Dinding: <span class="text-info">{{ datalist.nmdinding
                                                            }}</span> </strong><br>
                                                    <strong>Struktur Lantai: <span class="text-info">{{
                                                        datalist.nmstruktur_lantai }}</span> </strong><br>
                                                    <strong>Lantai: <span class="text-info">{{ datalist.nmlantai
                                                            }}</span> </strong><br>
                                                    <strong>Struktur Pondasi: <span class="text-info">{{
                                                        datalist.nmstruktur_pondasi }}</span> </strong><br>
                                                    <strong>Pondasi: <span class="text-info">{{ datalist.nmpondasi
                                                            }}</span> </strong><br>
                                                </address>
                                            </div>
                                            <div class="col-sm-3 invoice-col">
                                                <address>
                                                    <strong><span class="text-success">Utilitas Bangunan</span>
                                                    </strong><br>
                                                    <strong>Sumber Air Bersih: <span class="text-info">{{
                                                        datalist.nmair_bersih }}</span> </strong><br>
                                                    <strong>Pengelolaan Limbah: <span class="text-info">{{
                                                        datalist.nmpengelolaan_limbah }}</span> </strong><br>
                                                    <strong>Limbah: <span class="text-info">{{ datalist.nmlimbah
                                                            }}</span> </strong><br>
                                                    <strong>Pengelolaan Sampah: <span class="text-info">{{
                                                        datalist.nmpengelolaan_sampah }}</span> </strong><br>
                                                    <strong>Parkir: <span class="text-info">{{ datalist.nmparkir
                                                            }}</span> </strong><br>
                                                    <strong>Luas Parkir: <span class="text-info">{{
                                                        G_numFormatKoma(datalist.luas_parkir) }}</span>
                                                        m<sup>2</sup></strong><br>
                                                    <strong>Daya Listrik: <span class="text-info">{{
                                                        G_numFormatKoma(datalist.daya_listrik) }}</span>
                                                        Watt</strong><br>
                                                    <strong>Kondisi: <span class="text-info">{{ datalist.nmkondisi
                                                            }}</span> </strong><br>
                                                </address>
                                            </div>
                                        </div>
                                        <template v-for="(datalistlantai, urutlistlantai) in datalist.z_detail_lantai"
                                            :key="urutlistlantai">
                                            <div class="card collapsed-card">
                                                <div class="cardinfo card-header border-transparent">
                                                    {{ urutlistlantai + 1 }}. {{ datalistlantai.kode }} - {{
                                                        datalistlantai.nama }}
                                                    <div class="card-tools">
                                                        <button type="button" class="btn btn-tool"
                                                            data-card-widget="collapse"><i
                                                                class="fas fa-plus text-white"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body p-3">
                                                    <div class="row invoice-info">
                                                        <div class="col-sm-3 invoice-col">
                                                            <img :src="folderlantai + datalistlantai.filenya_lantai"
                                                                class="img-size-75 animation__wobble" alt="Gedung Image"
                                                                style="height: 100px;"><br><br>
                                                            <address>
                                                                <strong>Skor: <span class="text-info">{{
                                                                    G_numFormatKoma(datalistlantai.skor) }}</span>
                                                                </strong><br>
                                                                <strong>Tanggal Data: <span class="text-info">{{
                                                                    G_formatDateNonWIB(datalistlantai.tanggal_data)
                                                                        }}</span>
                                                                </strong><br>
                                                            </address>
                                                        </div>
                                                        <div class="col-sm-3 invoice-col">
                                                            <address>
                                                                <strong><span class="text-info">{{
                                                                    datalistlantai.id_lantai
                                                                        }}</span>
                                                                </strong><br>
                                                                <strong>Luas: <span class="text-info">{{
                                                                    G_numFormatKoma(datalistlantai.luas_m2)
                                                                        }}</span>
                                                                    m<sup>2</sup>
                                                                </strong><br>
                                                                <strong>Panjang: <span class="text-info">{{
                                                                    G_numFormatKoma(datalistlantai.panjang)
                                                                        }}</span> m
                                                                </strong><br>
                                                                <strong>Lebar: <span class="text-info">{{
                                                                    G_numFormatKoma(datalistlantai.lebar) }}</span>
                                                                    m
                                                                </strong><br>
                                                                <strong>Tinggi: <span class="text-info">{{
                                                                    G_numFormatKoma(datalistlantai.tinggi) }}</span>
                                                                    m
                                                                </strong><br>
                                                                <strong>Bentuk Bangunan: <span class="text-info">{{
                                                                    datalistlantai.nmbentuk_bangunan }}</span>
                                                                </strong><br>
                                                                <strong>Status Penggunaan: <span class="text-info">{{
                                                                    datalistlantai.nmstatus_penggunaan }}</span>
                                                                </strong><br>
                                                                <strong>Jumlah Ruangan: <span class="text-info">{{
                                                                    datalistlantai.jumlah_ruangan }}</span>
                                                                </strong><br>
                                                                <strong>Penanggungjawab: <span class="text-info">{{
                                                                    datalistlantai.nmpj_aset }}</span> </strong><br>
                                                            </address>
                                                        </div>
                                                        <div class="col-sm-3 invoice-col">
                                                            <address>
                                                                <strong><span class="text-success">Struktur
                                                                        Bangunan</span>
                                                                </strong><br>
                                                                <strong>Struktur Atap: <span class="text-info">{{
                                                                    datalistlantai.nmstruktur_atap }}</span>
                                                                </strong><br>
                                                                <strong>Atap: <span class="text-info">{{
                                                                    datalistlantai.nmatap
                                                                        }}</span>
                                                                </strong><br>
                                                                <strong>Struktur Dinding: <span class="text-info">{{
                                                                    datalistlantai.nmstruktur_dinding }}</span>
                                                                </strong><br>
                                                                <strong>Dinding: <span class="text-info">{{
                                                                    datalistlantai.nmdinding
                                                                        }}</span> </strong><br>
                                                                <strong>Struktur Lantai: <span class="text-info">{{
                                                                    datalistlantai.nmstruktur_lantai }}</span>
                                                                </strong><br>
                                                                <strong>Lantai: <span class="text-info">{{
                                                                    datalistlantai.nmlantai
                                                                        }}</span> </strong><br>
                                                                <strong>Struktur Pondasi: <span class="text-info">{{
                                                                    datalistlantai.nmstruktur_pondasi }}</span>
                                                                </strong><br>
                                                                <strong>Pondasi: <span class="text-info">{{
                                                                    datalistlantai.nmpondasi
                                                                        }}</span> </strong><br>
                                                            </address>
                                                        </div>
                                                        <div class="col-sm-3 invoice-col">
                                                            <address>
                                                                <strong><span class="text-success">Utilitas
                                                                        Bangunan</span>
                                                                </strong><br>
                                                                <strong>Sumber Air Bersih: <span class="text-info">{{
                                                                    datalistlantai.nmair_bersih }}</span>
                                                                </strong><br>
                                                                <strong>Pengelolaan Limbah: <span class="text-info">{{
                                                                    datalistlantai.nmpengelolaan_limbah }}</span>
                                                                </strong><br>
                                                                <strong>Limbah: <span class="text-info">{{
                                                                    datalistlantai.nmlimbah
                                                                        }}</span> </strong><br>
                                                                <strong>Pengelolaan Sampah: <span class="text-info">{{
                                                                    datalistlantai.nmpengelolaan_sampah }}</span>
                                                                </strong><br>
                                                                <strong>Parkir: <span class="text-info">{{
                                                                    datalistlantai.nmparkir
                                                                        }}</span> </strong><br>
                                                                <strong>Luas Parkir: <span class="text-info">{{
                                                                    G_numFormatKoma(datalistlantai.luas_parkir)
                                                                        }}</span>
                                                                    m<sup>2</sup></strong><br>
                                                                <strong>Daya Listrik: <span class="text-info">{{
                                                                    G_numFormatKoma(datalistlantai.daya_listrik)
                                                                        }}</span>
                                                                    Watt</strong><br>
                                                                <strong>Kondisi: <span class="text-info">{{
                                                                    datalistlantai.nmkondisi
                                                                        }}</span> </strong><br>
                                                            </address>
                                                        </div>
                                                    </div>
                                                    <template
                                                        v-for="(datalistruangan, urutlistruangan) in datalistlantai.z_detail_ruangan"
                                                        :key="urutlistlantai">
                                                        <div class="card collapsed-card">
                                                            <div class="cardhijautua card-header border-transparent">
                                                                {{ urutlistruangan + 1 }}. {{ datalistruangan.kode }} -
                                                                {{
                                                                    datalistruangan.nama }}
                                                                <div class="card-tools">
                                                                    <button type="button" class="btn btn-tool"
                                                                        data-card-widget="collapse"><i
                                                                            class="fas fa-plus text-white"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div class="card-body p-3">
                                                                <div class="row invoice-info">
                                                                    <div class="col-sm-3 invoice-col">
                                                                        <img :src="folderruangan + datalistruangan.filenya_ruangan"
                                                                            class="img-size-75 animation__wobble"
                                                                            alt="Gedung Image"
                                                                            style="height: 100px;"><br><br>
                                                                        <address>
                                                                            <strong>Skor: <span class="text-info">{{
                                                                                G_numFormatKoma(datalistruangan.skor)
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Tanggal Data: <span
                                                                                    class="text-info">{{
                                                                                        G_formatDateNonWIB(datalistruangan.tanggal_data)
                                                                                    }}</span>
                                                                            </strong><br>
                                                                        </address>
                                                                    </div>
                                                                    <div class="col-sm-3 invoice-col">
                                                                        <address>
                                                                            <strong><span class="text-info">{{
                                                                                datalistruangan.id_ruangan
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Luas: <span class="text-info">{{
                                                                                G_numFormatKoma(datalistruangan.luas_m2)
                                                                                    }}</span>
                                                                                m<sup>2</sup>
                                                                            </strong><br>
                                                                            <strong>Panjang: <span class="text-info">{{
                                                                                G_numFormatKoma(datalistruangan.panjang)
                                                                                    }}</span> m
                                                                            </strong><br>
                                                                            <strong>Lebar: <span class="text-info">{{
                                                                                G_numFormatKoma(datalistruangan.lebar)
                                                                                    }}</span>
                                                                                m
                                                                            </strong><br>
                                                                            <strong>Tinggi: <span class="text-info">{{
                                                                                G_numFormatKoma(datalistruangan.tinggi)
                                                                                    }}</span>
                                                                                m
                                                                            </strong><br>
                                                                            <strong>Bentuk Bangunan: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmbentuk_bangunan
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Status Penggunaan: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmstatus_penggunaan
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Jumlah Aset Barang: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.jumlahaset
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Penanggungjawab: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmpj_aset }}</span>
                                                                            </strong><br>
                                                                        </address>
                                                                    </div>
                                                                    <div class="col-sm-3 invoice-col">
                                                                        <address>
                                                                            <strong><span class="text-success">Struktur
                                                                                    Bangunan</span>
                                                                            </strong><br>
                                                                            <strong>Struktur Atap: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmstruktur_atap
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Atap: <span class="text-info">{{
                                                                                datalistruangan.nmatap
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Struktur Dinding: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmstruktur_dinding
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Dinding: <span class="text-info">{{
                                                                                datalistruangan.nmdinding
                                                                                    }}</span> </strong><br>
                                                                            <strong>Struktur Lantai: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmstruktur_lantai
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Lantai: <span class="text-info">{{
                                                                                datalistruangan.nmlantai
                                                                                    }}</span> </strong><br>
                                                                            <strong>Struktur Pondasi: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmstruktur_pondasi
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Pondasi: <span class="text-info">{{
                                                                                datalistruangan.nmpondasi
                                                                                    }}</span> </strong><br>
                                                                        </address>
                                                                    </div>
                                                                    <div class="col-sm-3 invoice-col">
                                                                        <address>
                                                                            <strong><span
                                                                                    class="text-success">Penggunaan
                                                                                    Ruangan</span>
                                                                            </strong><br>
                                                                            <strong>Jenis Penggunaan: <span
                                                                                    class="text-info">{{
                                                                                        datalistruangan.nmjenis_penggunaan
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Tenant: <span class="text-info">{{
                                                                                datalistruangan.nmtenant
                                                                                    }}</span>
                                                                            </strong><br>
                                                                            <strong>Kondisi: <span class="text-info">{{
                                                                                datalistruangan.nmkondisi
                                                                                    }}</span> </strong><br>
                                                                        </address>
                                                                    </div>
                                                                </div>
                                                                <div class="table-responsive">
                                                                    <table class="table m-0" id="tabeldata">
                                                                        <thead>
                                                                            <tr>
                                                                                <th class="text-left"> Daftar Aset
                                                                                    Barang</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <template
                                                                            v-for="(datalistaset, urutlistaset) in datalistruangan.z_detail_aset"
                                                                            :key="urutlistaset">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>
                                                                                        <div class="row invoice-info">
                                                                                            <div
                                                                                                class="col-sm-3 invoice-col">
                                                                                                <label>Aset ke {{
                                                                                                    urutlistaset + 1
                                                                                                    }}</label><br>
                                                                                                <img :src="folder_fotoaset + datalistaset.filenya_aset"
                                                                                                    class="img-size-75 animation__wobble"
                                                                                                    alt="Gedung Image"
                                                                                                    style="height: 100px;"><br><br>
                                                                                                <address>
                                                                                                    <strong>Skor: <span
                                                                                                            class="text-info">{{
                                                                                                                G_numFormatKoma(datalistaset.skor)
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Tanggal
                                                                                                        Data:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                G_formatDateNonWIB(datalistaset.createddate)
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                </address>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-sm-3 invoice-col">
                                                                                                <address>
                                                                                                    <strong><span
                                                                                                            class="text-success">Identitas
                                                                                                            Barang</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Sifat: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmsifat_aset
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Klasifikasi:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmklasifikasi_aset
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Kategori:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmkategori_aset
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Jenis: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmjenis_aset
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Merk: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmmerk_aset
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Tipe: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmtipe_aset
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                </address>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-sm-3 invoice-col">
                                                                                                <address>
                                                                                                    <strong><span
                                                                                                            class="text-success">Perawatan
                                                                                                            Barang</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Jenis
                                                                                                        Perawatan:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmjenis_perawatan
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Durasi
                                                                                                        Perawatan: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmdurasi_perawatan
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Metode
                                                                                                        Perawatan: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmmetode_perawatan
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Alat
                                                                                                        Perawatan:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmalat_perawatan
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Jadwal Mulai
                                                                                                        Perawatan: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.jam_mulai
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Jadwal
                                                                                                        Selesai
                                                                                                        Perawatan: <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.jam_selesai
                                                                                                            }}</span>
                                                                                                    </strong><br>

                                                                                                </address>
                                                                                            </div>
                                                                                            <div
                                                                                                class="col-sm-3 invoice-col">
                                                                                                <address>
                                                                                                    <strong><span
                                                                                                            class="text-success">Lokasi
                                                                                                            & PJ
                                                                                                            Barang</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Latitude:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.latitude
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Longitude:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.longitude
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                    <strong>Penanggungjawab:
                                                                                                        <span
                                                                                                            class="text-info">{{
                                                                                                                datalistaset.nmpj_aset
                                                                                                            }}</span>
                                                                                                    </strong><br>
                                                                                                </address>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </template>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </template>
                            <div class="card-footer clearfix text-center">
                                <button type="button" class="btn btn-sm btn-primary float-left text-white lebar"
                                    @click="Kembali()"><i class="fas fa-reply"></i> Kembali</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu
    },
    data() {
        return {
            halamanloading: true,
            namalokasi: '',
            iduser: JSON.parse(localStorage.getItem("usistem")),
            lokasiluser: JSON.parse(localStorage.getItem("loksistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
            datamaster: [],
            datapesanmaster: [],
            carikata: '',
            folder: process.env.VUE_APP_URL_API + 'view_oripro-GambarGedung?f=',
            folderlantai: process.env.VUE_APP_URL_API + 'view_oripro-GambarLantai?f=',
            folderruangan: process.env.VUE_APP_URL_API + 'view_oripro-GambarRuangan?f=',
            folder_fotoaset: process.env.VUE_APP_URL_API + 'dataaset_oripro-GambarAset?f=',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        Kembali() {
            window.location.replace("/KinerjaAset/");
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async AmbilData() {
            this.halamanloading = true;
            this.datamaster = [];
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("m_oripro-GetbyIDLokasi?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.namalokasi = Response.data.content.data[0].nama;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            var random = Math.random();
            await mainAPI.get("kinaset_oripro-GetKinerjabyIDLokasi?kdlokasi=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })

        },

    },
    mounted() {
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>