<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="modal fade" id="costumModalviewpeta" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">View Lokasi</h4>
                    </div>
                    <div class="modal-body text-justify">
                        <p>
                            <button type="button" class="btn btn-tool" @click="tampilerror()" id="errordisplay"
                                style="display: none;"><i class="fas fa-plus"></i></button>
                        <div class="row">
                            <iframe :src="sourcenya" class="col-sm-12 text-center" style="height: 50vh;" frameborder="0"
                                id="maps" scrolling="no" @load="loadpeta()"></iframe>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-info btn-sm lebar" data-dismiss="modal"> <i
                                class="far fa-times-circle"></i>
                            Tutup</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->

        <div class="modal fade" id="costumModalpeta" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Setting Peta</h4>
                    </div>
                    <div class="modal-body text-justify">
                        <p>
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Nama Aset</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i
                                                class="fab fa-creative-commons-share"></i></span>
                                    </div>
                                    <input type="hidden" class="form-control form-control-sm" id="kodeaset"
                                        name="kodeaset" placeholder="Kode Aset" v-model="kodeaset" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="tabelaset"
                                        name="tabelaset" placeholder="Tabel Aset" v-model="tabelaset" readonly>
                                    <input type="text" class="form-control form-control-sm" id="namaaset"
                                        name="namaaset" placeholder="Nama Aset" v-model="namaaset" readonly>
                                </div>
                            </div>
                            <!--PETA 1-->
                            <div class="col-sm-12">
                                <label>File<small class="text-info"> (*geojson files)</small>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-file-image"></i></span>
                                    </div>
                                    <input type="file" class="form-control form-control-sm"
                                        id="fileToUploadfilenya_peta" name="fileToUploadfilenya_peta"
                                        placeholder="fileToUpload" style="font-size: smaller;" accept=".geojson">
                                    <input type="hidden" class="form-control form-control-sm" id="filenya_peta"
                                        name="filenya_peta" placeholder="File Peta" v-model="filenya_peta">
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <label>Opsi</label>
                                <div class="row">
                                    <div class="col-sm-9">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                id="persennyafilenya_peta">
                                                <span id="tulisanpersenfilenya_peta">0%</span>
                                            </div>
                                        </div>
                                        <br>
                                        <span class="text-success" id="suksesnyafilenya_peta"></span>
                                    </div>
                                    <div class="col-sm-3 text-center">
                                        <button type="button" class="btn btn-warning btn-sm lebar text-white"
                                            @click="uploadfilepeta('filenya_peta', '.geojson')">Upload
                                            File</button>
                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                            @click="clearfilepeta('filenya_peta')">Hapus File</button>
                                    </div>
                                </div>
                            </div>
                            <!--PETA 1 END-->
                            <div class="col-sm-12 float-left">
                                <label>Tipe Peta</label>
                                <v-select :options="tipepeta" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedTipePeta" @update:modelValue="onTipePetaChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <!--IKON PETA 1-->
                            <div class="col-sm-12" :style="[(selectedtipepeta == 2 ? ':block' : 'display:none')]">
                                <label>Ikon<small class="text-info"> (*rekomendasi images files png transparent 25px x
                                        25px)</small>
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-file-image"></i></span>
                                    </div>
                                    <input type="file" class="form-control form-control-sm"
                                        id="fileToUploadfilenya_ikon" name="fileToUploadfilenya_ikon"
                                        placeholder="fileToUpload" style="font-size: smaller;" accept="image/*">
                                    <input type="hidden" class="form-control form-control-sm" id="filenya_ikon"
                                        name="filenya_ikon" placeholder="File Ikon" v-model="filenya_ikon">
                                </div>
                            </div>
                            <div class="col-sm-12" :style="[(selectedtipepeta == 2 ? ':block' : 'display:none')]">
                                <label>Opsi</label>
                                <div class="row">
                                    <div class="col-sm-9">
                                        <div class="progress">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                id="persennyafilenya_ikon">
                                                <span id="tulisanpersenfilenya_ikon">0%</span>
                                            </div>
                                        </div>
                                        <br>
                                        <span class="text-success" id="suksesnyafilenya_ikon"></span>
                                    </div>
                                    <div class="col-sm-3 text-center">
                                        <button type="button" class="btn btn-warning btn-sm lebar text-white"
                                            @click="uploadfile('filenya_ikon', 'image/*')">Upload
                                            File</button>
                                        <button type="button" class="btn btn-danger btn-sm lebar"
                                            @click="clearfilepeta('filenya_ikon')">Hapus File</button>
                                    </div>
                                </div>
                            </div>
                            <!--IKON PETA 1 END-->
                            <div class="col-sm-6 mb-3" :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                <label>Fill Color</label>
                                <Vue3ColorPicker v-model="fill" mode="solid" :showColorList="false" :showEyeDrop="false"
                                    type="HEX" />
                            </div>
                            <div class="col-sm-6 mb-3" :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                <label>Stroke Color</label>
                                <Vue3ColorPicker v-model="stroke" mode="solid" :showColorList="false"
                                    :showEyeDrop="false" type="HEX" />
                            </div>
                            <div class="col-sm-6" :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                <label>Outline Width</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-arrows-alt-h"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="fill_width"
                                        name="fill_width" placeholder="Outline Width" v-model="fill_width">
                                </div>
                            </div>
                            <div class="col-sm-6" :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                <label>Tipe Fill Polygon</label>
                                <v-select :options="tipe_fill" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedTipeFill" @update:modelValue="onTipeFillChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6" :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                <label>Tipe Pattern Polygon</label>
                                <v-select :options="tipe_pattern" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedTipePattern" @update:modelValue="onTipePatternChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6" :style="[(selectedtipepeta == 1 ? ':block' : 'display:none')]">
                                <label>Sudut Derajat Pattern</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-clone"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="sudut" name="sudut"
                                        placeholder="Urutan Layer" v-model="sudut">
                                </div>
                            </div>
                            <div class="col-sm-6" :style="[(selectedtipepeta == 3 ? ':block' : 'display:none')]">
                                <label>Line Width</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-arrows-alt-h"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="stroke_width"
                                        name="stroke_width" placeholder="Line Width" v-model="stroke_width">
                                </div>
                            </div>
                            <div class="col-sm-6" :style="[(selectedtipepeta == 3 ? ':block' : 'display:none')]">
                                <label>Dash Line Start</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-ellipsis-h"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="dash_start"
                                        name="dash_start" placeholder="Dash Line Start" v-model="dash_start">
                                </div>
                            </div>
                            <div class="col-sm-6" :style="[(selectedtipepeta == 3 ? ':block' : 'display:none')]">
                                <label>Dash Line End</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-ellipsis-h"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="dash_end"
                                        name="dash_end" placeholder="Dash Line End" v-model="dash_end">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Urutan Layer</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-clone"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="urut" name="urut"
                                        placeholder="Urutan Layer" v-model="urut">
                                </div>
                            </div>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-primary btn-sm lebar" @click="simpandatapeta()"><i
                                class="fas fa-edit"></i>
                            Setting</button>
                        <button type="button" class="btn btn-info btn-sm lebar" data-dismiss="modal"> <i
                                class="far fa-times-circle"></i>
                            Batal</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Manajemen Peta Lokasi Aset</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                <li class="breadcrumb-item active">Data Lokasi Aset</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-8 float-left pb-2">
                                        <label>Pencarian</label>
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="col-sm-3 float-left pb-2">
                                        <label>Lokasi</label>
                                        <v-select :options="lokasi" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedLokasi" @update:modelValue="onlokasiChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 80px;">No</th>
                                                    <th>Lokasi</th>
                                                    <th style="width: 350px;">Data Peta</th>
                                                    <th style="width: 100px;">Urutan Lapisan Layer</th>
                                                    <th style="width: 130px;">Opsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td :class="datalist.class">{{ datalist.No }}</td>
                                                        <td :class="datalist.class">{{ datalist.nama }}</td>
                                                        <td :class="datalist.class + ' text-center'">
                                                            <span
                                                                :class="[(datalist.filenya_peta != 'peta spasial belum tersedia' ? 'badge badge-success' : 'badge badge-danger')]">
                                                                {{ datalist.tulisfilenya_peta }}
                                                            </span>
                                                        </td>
                                                        <td :class="datalist.class">{{ datalist.urut }}</td>
                                                        <td :class="datalist.class + ' text-center'">
                                                            <button type="button"
                                                                class="btn btn-success btn-sm lebar text-white" @click="LihatPeta(datalist.id, datalist.nama, datalist.tabel, datalist.filenya_peta
                                                                    , datalist.tipe_peta, datalist.fill, datalist.stroke
                                                                    , datalist.fill_width, datalist.stroke_width, datalist.dash_start
                                                                    , datalist.dash_end, datalist.filenya_ikon, datalist.urut
                                                                    , datalist.tipe_fill, datalist.tipe_pattern, datalist.sudut
                                                                    , datalist.namatipe_fill, datalist.namatipe_pattern
                                                                )"
                                                                v-if="datalist.filenya_peta != 'peta spasial belum tersedia'"><i
                                                                    class="far fa-eye"></i> Lihat Peta</button>
                                                            <button type="button"
                                                                class="btn btn-primary btn-sm lebar text-white"
                                                                @click="prosesdata(datalist.id, datalist.nama, datalist.tabel, datalist.filenya_peta
                                                                    , datalist.tipe_peta, datalist.fill, datalist.stroke
                                                                    , datalist.fill_width, datalist.stroke_width, datalist.dash_start
                                                                    , datalist.dash_end, datalist.filenya_ikon, datalist.urut
                                                                    , datalist.tipe_fill, datalist.tipe_pattern, datalist.sudut
                                                                    , datalist.namatipe_fill, datalist.namatipe_pattern)"><i class="fas fa-edit"></i> Setting
                                                                Peta</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div class="col-md-12 text-center" v-if="(datapesanmaster == 'data kosong')">
                                            Data
                                            Masih Kosong</div>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    Jumlah Lokasi: {{ G_numFormat(JumlahfilteredKataData) }}
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { inject } from 'vue';
import swal from 'sweetalert2';
import xlsx from 'xlsx/dist/xlsx.full.min';
import vSelect from 'vue-select';
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, swal, vSelect, Vue3ColorPicker
    },
    data() {
        return {
            halamanloading: true,
            carikata: '',
            tmpdatamaster: [],
            datamaster: [],
            datapesan: [],
            datapesanmaster: [],
            ekportexcel: [],
            kdorganisasi: JSON.parse(localStorage.getItem("orsistem")),

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            lokasiuser: JSON.parse(localStorage.getItem("loksistem")),
            nmlokasiuser: JSON.parse(localStorage.getItem("nmloksistem")),
            kodeaset: '',
            namaaset: '',

            tipepeta: [],
            datatipepeta: [],
            defaultSelectedTipePeta: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedtipepeta: 0,

            filenya_peta: '',
            fill: '#00BFFF',
            stroke: '#000000',
            fill_width: '1',
            stroke_width: '1',
            dash_start: '1',
            dash_end: '1',
            filenya_ikon: '',
            urut: '1',

            tipe_fill: [],
            datatipe_fill: [],
            defaultSelectedTipeFill: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedtipe_fill: 1,

            tipe_pattern: [],
            datatipe_pattern: [],
            defaultSelectedTipePattern: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedtipe_pattern: 1,
            sudut: '0',

            linknya: process.env.VUE_APP_URL_API,
            sourcenya: './maps.html',
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        loadpeta() {
            this.halamanloading = false;
        },
        uploadfilepeta(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            //alert(elmnt.files[0].name);
            //alert(elmnt.files[0].name.slice(-7));
            try {
                if (elmnt.files[0].name == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan File geojson..',
                    });
                    return false;
                }
                if (elmnt.files[0].name === undefined) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan File geojson..',
                    });
                    return false;
                }
                if (elmnt.files[0].name.slice(-7).toUpperCase() != 'GEOJSON') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Kesalahan Format Data Peta..',
                    });
                    return false;
                }

                var fd = new FormData();
                fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
                fd.append("folderToUpload", idnya);
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("oripro-FilesUpload", fd, {
                    onUploadProgress: function (uploadEvent) {
                        document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                        document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    }
                }).then(
                    function (res) {
                        document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                        if (res.data.filename != undefined) {
                            document.getElementById(idnya).value = res.data.filename;
                            //this.filenya_peta = document.getElementById(idnya).value;
                        }
                    }
                ).catch(function (e) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    document.getElementById(idnya).value = '';
                    console.log(e);
                }
                )
            } catch (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan File geojson..',
                });
                return false;
            }

        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                        this.filenya_ikon = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        onTipeFillChange(a) {
            try {
                this.filenya_peta = document.getElementById('filenya_peta').value;
                this.filenya_ikon = document.getElementById('filenya_ikon').value;
                this.selectedtipe_fill = this.tipe_fill[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onTipePatternChange(a) {
            try {
                this.filenya_peta = document.getElementById('filenya_peta').value;
                this.filenya_ikon = document.getElementById('filenya_ikon').value;
                this.selectedtipe_pattern = this.tipe_pattern[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onTipePetaChange(a) {
            try {
                this.filenya_peta = document.getElementById('filenya_peta').value;
                this.filenya_ikon = document.getElementById('filenya_ikon').value;
                this.selectedtipepeta = this.tipepeta[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        tampilerror() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Kesalahan pada load data peta..',
            });
            return false;
        },
        async AmbilData() {
            this.halamanloading = true;
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser >= 4) {
                kodelokasi = this.lokasiuser;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("maps_oripro-GetPetaLokasi?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.tmpdatamaster = [];
                        this.tmpdatamaster = Response.data.content.data;
                    }

                    this.datamaster = [];
                    for (let i = 0; i < this.tmpdatamaster.length; i++) {
                        this.datamaster.push({
                            "No": i + 1,
                            "id": this.tmpdatamaster[i].id,
                            "nama": this.tmpdatamaster[i].nama,
                            "tabel": this.tmpdatamaster[i].tabel,
                            "class": this.tmpdatamaster[i].class,
                            "filenya_peta": this.tmpdatamaster[i].filenya_peta,
                            "tulisfilenya_peta": this.tmpdatamaster[i].tulisfilenya_peta,
                            "tipe_peta": this.tmpdatamaster[i].tipe_peta,
                            "fill": this.tmpdatamaster[i].fill,
                            "stroke": this.tmpdatamaster[i].stroke,
                            "fill_width": this.tmpdatamaster[i].fill_width,
                            "stroke_width": this.tmpdatamaster[i].stroke_width,
                            "dash_start": this.tmpdatamaster[i].dash_start,
                            "dash_end": this.tmpdatamaster[i].dash_end,
                            "filenya_ikon": this.tmpdatamaster[i].filenya_ikon,
                            "urut": this.tmpdatamaster[i].urut,
                            "tipe_fill": this.tmpdatamaster[i].tipe_fill,
                            "tipe_pattern": this.tmpdatamaster[i].tipe_pattern,
                            "sudut": this.tmpdatamaster[i].sudut,
                            "namatipe_fill": this.tmpdatamaster[i].namatipe_fill,
                            "namatipe_pattern": this.tmpdatamaster[i].namatipe_pattern,
                        })
                        for (let z = 0; z < this.tmpdatamaster[i].gedung.length; z++) {
                            this.datamaster.push({
                                "No": (i + 1) + "." + (z + 1),
                                "id": this.tmpdatamaster[i].gedung[z].id,
                                "nama": this.tmpdatamaster[i].gedung[z].nama,
                                "tabel": this.tmpdatamaster[i].gedung[z].tabel,
                                "class": this.tmpdatamaster[i].gedung[z].class,
                                "filenya_peta": this.tmpdatamaster[i].gedung[z].filenya_peta,
                                "tulisfilenya_peta": this.tmpdatamaster[i].gedung[z].tulisfilenya_peta,
                                "tipe_peta": this.tmpdatamaster[i].gedung[z].tipe_peta,
                                "fill": this.tmpdatamaster[i].gedung[z].fill,
                                "stroke": this.tmpdatamaster[i].gedung[z].stroke,
                                "fill_width": this.tmpdatamaster[i].gedung[z].fill_width,
                                "stroke_width": this.tmpdatamaster[i].gedung[z].stroke_width,
                                "dash_start": this.tmpdatamaster[i].gedung[z].dash_start,
                                "dash_end": this.tmpdatamaster[i].gedung[z].dash_end,
                                "filenya_ikon": this.tmpdatamaster[i].gedung[z].filenya_ikon,
                                "urut": this.tmpdatamaster[i].gedung[z].urut,
                                "tipe_fill": this.tmpdatamaster[i].gedung[z].tipe_fill,
                                "tipe_pattern": this.tmpdatamaster[i].gedung[z].tipe_pattern,
                                "sudut": this.tmpdatamaster[i].gedung[z].sudut,
                                "namatipe_fill": this.tmpdatamaster[i].gedung[z].namatipe_fill,
                                "namatipe_pattern": this.tmpdatamaster[i].gedung[z].namatipe_pattern,
                            })
                            for (let c = 0; c < this.tmpdatamaster[i].gedung[z].lantai.length; c++) {
                                this.datamaster.push({
                                    "No": (i + 1) + "." + (z + 1) + "." + (c + 1),
                                    "id": this.tmpdatamaster[i].gedung[z].lantai[c].id,
                                    "nama": this.tmpdatamaster[i].gedung[z].lantai[c].nama,
                                    "tabel": this.tmpdatamaster[i].gedung[z].lantai[c].tabel,
                                    "class": this.tmpdatamaster[i].gedung[z].lantai[c].class,
                                    "filenya_peta": this.tmpdatamaster[i].gedung[z].lantai[c].filenya_peta,
                                    "tulisfilenya_peta": this.tmpdatamaster[i].gedung[z].lantai[c].tulisfilenya_peta,
                                    "tipe_peta": this.tmpdatamaster[i].gedung[z].lantai[c].tipe_peta,
                                    "fill": this.tmpdatamaster[i].gedung[z].lantai[c].fill,
                                    "stroke": this.tmpdatamaster[i].gedung[z].lantai[c].stroke,
                                    "fill_width": this.tmpdatamaster[i].gedung[z].lantai[c].fill_width,
                                    "stroke_width": this.tmpdatamaster[i].gedung[z].lantai[c].stroke_width,
                                    "dash_start": this.tmpdatamaster[i].gedung[z].lantai[c].dash_start,
                                    "dash_end": this.tmpdatamaster[i].gedung[z].lantai[c].dash_end,
                                    "filenya_ikon": this.tmpdatamaster[i].gedung[z].lantai[c].filenya_ikon,
                                    "urut": this.tmpdatamaster[i].gedung[z].lantai[c].urut,
                                    "tipe_fill": this.tmpdatamaster[i].gedung[z].lantai[c].tipe_fill,
                                    "tipe_pattern": this.tmpdatamaster[i].gedung[z].lantai[c].tipe_pattern,
                                    "sudut": this.tmpdatamaster[i].gedung[z].lantai[c].sudut,
                                    "namatipe_fill": this.tmpdatamaster[i].gedung[z].lantai[c].namatipe_fill,
                                    "namatipe_pattern": this.tmpdatamaster[i].gedung[z].lantai[c].namatipe_pattern,
                                })
                                for (let m = 0; m < this.tmpdatamaster[i].gedung[z].lantai[c].ruangan.length; m++) {
                                    this.datamaster.push({
                                        "No": (i + 1) + "." + (z + 1) + "." + (c + 1) + "." + (m + 1),
                                        "id": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].id,
                                        "nama": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].nama,
                                        "tabel": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].tabel,
                                        "class": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].class,
                                        "filenya_peta": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].filenya_peta,
                                        "tulisfilenya_peta": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].tulisfilenya_peta,
                                        "tipe_peta": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].tipe_peta,
                                        "fill": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].fill,
                                        "stroke": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].stroke,
                                        "fill_width": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].fill_width,
                                        "stroke_width": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].stroke_width,
                                        "dash_start": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].dash_start,
                                        "dash_end": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].dash_end,
                                        "filenya_ikon": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].filenya_ikon,
                                        "urut": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].urut,
                                        "tipe_fill": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].tipe_fill,
                                        "tipe_pattern": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].tipe_pattern,
                                        "sudut": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].sudut,
                                        "namatipe_fill": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].namatipe_fill,
                                        "namatipe_pattern": this.tmpdatamaster[i].gedung[z].lantai[c].ruangan[m].namatipe_pattern,
                                    })
                                }
                            }
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            this.halamanloading = true;
            if (this.leveluser < 4) {
                kodelokasi = '';
            }

            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLokasi?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        if (this.leveluser < 4) {
                            this.lokasi.unshift({
                                label: 'Semua Data',
                                code: 0,
                                tag: 0,
                            });
                        }
                        if (this.leveluser >= 4) {
                            this.defaultSelectedLokasi = {
                                code: this.lokasiuser,
                                label: this.nmlokasiuser,
                                tag: this.lokasiuser,
                            };
                            this.selectedlokas = this.lokasiuser;
                        }

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI5 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI5.get("mtipepeta_oripro-GetTipePeta?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipepeta = [];
                        this.tipepeta = [];
                        this.datatipepeta = Response.data.content.data;
                        this.datatipepeta.forEach((item) => {
                            this.tipepeta.push({
                                label: item.nama,
                                code: this.datatipepeta.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipepeta.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI6 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI6.get("mtipepeta_oripro-GetFillTipe?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipe_fill = [];
                        this.tipe_fill = [];
                        this.datatipe_fill = Response.data.content.data;
                        this.datatipe_fill.forEach((item) => {
                            this.tipe_fill.push({
                                label: item.nama,
                                code: this.datatipe_fill.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipe_fill.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI7 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI7.get("mtipepeta_oripro-GetJenisPattern?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipe_pattern = [];
                        this.tipe_pattern = [];
                        this.datatipe_pattern = Response.data.content.data;
                        this.datatipe_pattern.forEach((item) => {
                            this.tipe_pattern.push({
                                label: item.nama,
                                code: this.datatipe_pattern.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipe_pattern.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        prosesdata(kode, nama, tabel, filenya_peta, tipe_peta, fill, stroke, fill_width, stroke_width, dash_start, dash_end, filenya_ikon, urut, tipe_fill, tipe_pattern, sudut, namatipe_fill, namatipe_pattern) {
            var label = 'Pilih Salah Satu';
            this.kodeaset = kode;
            this.namaaset = nama;
            this.tabelaset = tabel;
            this.filenya_peta = filenya_peta;
            if (filenya_peta == 'peta spasial belum tersedia') {
                this.filenya_peta = '';
            }
            if (tipe_peta == 1) {
                label = 'Polygon';
            }
            else if (tipe_peta == 2) {
                label = 'Point';
            }
            else if (tipe_peta == 3) {
                label = 'Line';
            }
            else {
                label = 'Pilih Salah Satu';
            }

            if (tipe_fill == 1) {
                namatipe_fill = 'Fill';
            }
            else {
                namatipe_fill = 'Pattern';
            }

            if (tipe_pattern == 1) {
                namatipe_pattern = 'hatch';
            }
            else {
                namatipe_pattern = 'cross';
            }

            this.defaultSelectedTipePeta = {
                code: tipe_peta,
                label: label,
                tag: tipe_peta,
            };
            this.selectedtipepeta = tipe_peta;

            this.defaultSelectedTipeFill = {
                code: tipe_fill,
                label: namatipe_fill,
                tag: tipe_fill,
            };
            this.selectedtipe_fill = tipe_fill;

            this.defaultSelectedTipePattern = {
                code: tipe_pattern,
                label: namatipe_pattern,
                tag: tipe_pattern,
            };
            this.selectedtipe_pattern = tipe_pattern;

            this.fill = fill;
            this.stroke = stroke;
            this.fill_width = fill_width;
            this.stroke_width = stroke_width;
            this.dash_start = dash_start;
            this.dash_end = dash_end;
            this.filenya_ikon = filenya_ikon;
            this.urut = urut;
            this.sudut = sudut;
            $('#costumModalpeta').modal('show');
        },
        LihatPeta(kode, nama, tabel, filenya_peta, tipe_peta, fill, stroke, fill_width, stroke_width, dash_start, dash_end, filenya_ikon, urut, tipe_fill, tipe_pattern, sudut, namatipe_fill, namatipe_pattern) {
            const message = this.linknya + '|' + kode + '|' + nama + '|' + tabel + '|' + filenya_peta + '|' + tipe_peta + '|' + fill + '|' + stroke + '|' + fill_width + '|' + stroke_width + '|' + dash_start + '|' + dash_start + '|' + dash_end + '|' + filenya_ikon + '|' + urut + '|' + tipe_fill + '|' + tipe_pattern + '|' + sudut + '|' + namatipe_fill + '|' + namatipe_pattern;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = './maps.html';
            iframe.contentWindow.postMessage(message, "*");
            $('#costumModalviewpeta').modal('show');

        },
        simpandatapeta() {
            if ((document.getElementById('filenya_peta').value == '') || (document.getElementById('filenya_peta').value === null) || (document.getElementById('filenya_peta').value === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Isikan File Geojson..',
                });
                return false;
            }
            if ((this.selectedtipepeta == 0) || (this.selectedtipepeta === null) || (this.selectedtipepeta === undefined)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Tipe Data Peta..',
                });
                return false;
            }
            if (this.selectedtipepeta == 1) {
                if ((this.selectedtipe_fill == 0) || (this.selectedtipe_pattern == 0) || (this.selectedtipe_fill === null) || (this.selectedtipe_pattern === null) || (this.selectedtipe_fill === undefined) || (this.selectedtipe_pattern === undefined)) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Pilih Tipe Fill Polygon Peta..',
                    });
                    return false;
                }
            }

            if (this.selectedtipepeta == 2) {
                if ((document.getElementById('filenya_ikon').value == '') || (document.getElementById('filenya_ikon').value === null) || (document.getElementById('filenya_ikon').value === undefined)) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Ikon Point..',
                    });
                    return false;
                }
            }
            var fd = new FormData();
            fd.append("id_tabel", this.kodeaset);
            fd.append("nama_tabel", this.tabelaset);
            fd.append("filenya_peta", document.getElementById('filenya_peta').value);
            fd.append("tipe_peta", this.selectedtipepeta);
            fd.append("fill", this.fill);
            fd.append("stroke", this.stroke);
            fd.append("fill_width", this.fill_width.toString().replaceAll(",", "."));
            fd.append("stroke_width", this.stroke_width.toString().replaceAll(",", "."));
            fd.append("dash_start", this.dash_start.toString().replaceAll(",", "."));
            fd.append("dash_end", this.dash_end.toString().replaceAll(",", "."));
            fd.append("filenya_ikon", document.getElementById('filenya_ikon').value);
            fd.append("urut", this.urut);
            fd.append("tipe_fill", this.selectedtipe_fill);
            fd.append("tipe_pattern", this.selectedtipe_pattern);
            fd.append("sudut", this.sudut);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("maps_oripro-AddPetaLokasi", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                        $('.swal2-container').css("z-index", '999999');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah disetting..', 'success').then(function () {
                            window.location.replace("/Aset");
                        });
                        $('.swal2-container').css("z-index", '999999');
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.selectedbidang = JSON.parse(localStorage.getItem("bdlsistem"));
        this.selectedsubbidang = JSON.parse(localStorage.getItem("sbdsistem"));
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>