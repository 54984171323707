<template>
  <div class="modal fade" id="costumModalprofil" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
    aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
    style="margin: auto;">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Profil {{ namauser }}</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-justify">
          <p>
          <div class="row">
            <div class="col-sm-12">
              <label>Nama</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-user"></i></span>
                </div>
                <input type="text" class="form-control form-control-sm" id="namauser" name="namauser"
                  placeholder="Nama User" v-model="namauser" readonly>
              </div>
            </div>
            <div class="col-sm-12">
              <label>Username</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-address-card"></i></span>
                </div>
                <input type="text" class="form-control form-control-sm" id="username" name="username"
                  placeholder="Username" v-model="username">
                <input type="hidden" class="form-control form-control-sm" id="username_tmp" name="username_tmp"
                  placeholder="Username" v-model="username_tmp">
              </div>
            </div>
            <div class="col-sm-12">
              <label>Password Lama</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input type="password" class="form-control form-control-sm" id="password" name="password"
                  placeholder="Password Lama" v-model="password">
              </div>
            </div>
            <div class="col-sm-12">
              <label>Password Baru</label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input type="password" class="form-control form-control-sm" id="passwordbaru" name="passwordbaru"
                  placeholder="Password Baru" v-model="passwordbaru">
              </div>
            </div>
            <!--SERTIFIKAT GAMBAR 1-->
            <div class="col-sm-12">
              <label>Foto<small class="text-info"> (*images files)</small>
              </label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="far fa-file-image"></i></span>
                </div>
                <input type="file" class="form-control form-control-sm" id="fileToUploadfilenya_user"
                  name="fileToUploadfilenya_user" placeholder="fileToUpload" style="font-size: smaller;"
                  accept="image/*">
                <input type="hidden" class="form-control form-control-sm" id="filenya_user" name="filenya_user"
                  placeholder="File Dokumentasi" v-model="filenya_user">
              </div>
            </div>
            <div class="col-sm-12">
              <label>Opsi</label>
              <div class="row">
                <div class="col-sm-8">
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                      style="width:0%" id="persennyafilenya_user">
                      <span id="tulisanpersenfilenya_user">0%</span>
                    </div>
                  </div>
                  <br>
                  <span class="text-success" id="suksesnyafilenya_user"></span>
                </div>
                <div class="col-sm-4 text-center">
                  <button type="button" class="btn btn-warning btn-sm lebar text-white"
                    @click="upload('filenya_user', 'image/*')">Upload
                    File</button>
                  <button type="button" class="btn btn-danger btn-sm lebar" @click="clearfile('filenya_user')">Hapus
                    File</button>
                </div>
              </div>
            </div>
            <!--SERTIFIKAT GAMBAR 1 END-->
          </div>
          </p>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-primary btn-sm lebar" @click="UpdateProfil()"><i
              class="fas fa-user-edit"></i>
            Ubah</button>
          <button type="button" class="btn btn-info btn-sm lebar" data-dismiss="modal"> <i
              class="far fa-times-circle"></i>
            Batal</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button" style="font-size: large;">
          <i class="fas fa-align-left"></i>
        </a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="#" class="nav-link animation__shake">Asset Management System</a>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <img :src="folder" alt="User Profil" class="img-size-50 mr-3 img-circle" style="height: 50px !important;">
          <b class="text-primary">{{ namauser }} <i class="fas fa-angle-down"></i></b>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a href="javascript:void(0)" class="dropdown-item" @click="UbahProfil()">
            <i class="fas fa-user mr-2"></i> Profil
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" v-on:click="logoutAct">
            <i class="fas fa-sign-out-alt mr-2"></i> Logout
          </a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import swal from 'sweetalert2';
export default {
  components: {
    swal
  },
  data() {
    return {
      tahun: new Date().getFullYear(),
      namauser: JSON.parse(localStorage.getItem("nmusistem")),
      username: '',
      username_tmp: '',
      password: '',
      passwordbaru: '',
      filenya_user: JSON.parse(localStorage.getItem("imgsistem")),
      folder: process.env.VUE_APP_URL_API + 'view_oripro-GambarUser?f=' + JSON.parse(localStorage.getItem("imgsistem")),
    };
  },
  methods: {
    getOtentifikasi() {
      const mainAPIUser = axios.create({
          baseURL: process.env.VUE_APP_URL_API,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
          },
        });

        mainAPIUser.get("oripro-GetDataUserName?kduser=" + localStorage.getItem("usistem")).then(
          Res => {
            if (Res.data.response == 'error') {
              //alert(Res.data.response);
              this.ClearlocalStorage();
              return false;
            }
            else {
              //alert(JSON.stringify(Res.data));
              this.username = Res.data.content.data[0].username;
              this.username_tmp = Res.data.content.data[0].username;
            }
          }
        ).catch(
          error => {
            swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            }).fire({
              icon: 'warning',
              title: error,
            });
          }
        )
    },
    clearfile(idnya) {
      if (document.getElementById(idnya).value == '') {
        swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        }).fire({
          icon: 'warning',
          title: 'File Tidak Tersedia',
        });
        return false;
      }

      var fd = new FormData();
      fd.append("folderToUpload", idnya);
      fd.append("filenya", document.getElementById(idnya).value);
      const mainAPI = axios.create({
        baseURL: process.env.VUE_APP_URL_API,
        headers: {
          "Content-Type": "form-data",
          Authorization: "Bearer " + localStorage.getItem("tokensistem"),
        },
      });
      mainAPI.post("oripro-FilesDeleted", fd).then(
        Response => {
          swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
          }).fire({
            icon: 'warning',
            title: Response.data.message,
          });
        }
      ).catch(function (error) {
        swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        }).fire({
          icon: 'warning',
          title: error
        });
      }
      )

      document.getElementById('fileToUpload' + idnya).value = '';
      document.getElementById(idnya).value = '';
      document.getElementById('persennya' + idnya).style.width = "0%";
      document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
      document.getElementById('suksesnya' + idnya).innerHTML = '';
      return false;
    },
    upload(idnya, tipenya) {
      var elmnt = document.getElementById("fileToUpload" + idnya);
      try {
        var namanya = elmnt.files[0].name;
        //alert(elmnt.files[0]['type'].toUpperCase());
        if (tipenya == 'image/*') {
          if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
            document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
            return;
          }
        }
        else {
          if (elmnt.files[0]['type'] != tipenya) {
            document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
            return;
          }
        }
      } catch (error) {
        document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
        return;
      }
      var fd = new FormData();
      fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
      fd.append("folderToUpload", idnya);
      const mainAPI = axios.create({
        baseURL: process.env.VUE_APP_URL_API,
        headers: {
          "Content-Type": "form-data",
          Authorization: "Bearer " + localStorage.getItem("tokensistem"),
        },
      });
      mainAPI.post("oripro-FilesUpload", fd, {
        onUploadProgress: function (uploadEvent) {
          document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
          document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
        }
      }).then(
        function (res) {
          document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
          if (res.data.filename != undefined) {
            document.getElementById(idnya).value = res.data.filename;
          }
        }
      ).catch(function (e) {
        document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
        document.getElementById(idnya).value = '';
        console.log(e);
      }
      )
    },
    UbahProfil() {
      $('#costumModalprofil').modal('show');
    },
    UpdateProfil() {
      if (this.username == '') {
        swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        }).fire({
          icon: 'warning',
          title: 'Isikan username..',
        });
        return false;
      }
      if (this.password == '') {
        swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        }).fire({
          icon: 'warning',
          title: 'Isikan password..',
        });
        return false;
      }
      if (this.passwordbaru == '') {
        swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000
        }).fire({
          icon: 'warning',
          title: 'Isikan password baru..',
        });
        return false;
      }
      swal.fire({
        title: "Konfirmasi",
        text: "Yakin Hendak Ubah Kata Sandi / Password?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonColor: "#33bff2",
        cancelButtonColor: "#6566aa",
        confirmButtonText: "Ubah"
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            kduser: JSON.parse(localStorage.getItem("usistem")),
            username: this.username,
            username_tmp: this.username_tmp,
            passwordlama: this.password,
            passwordbaru: this.passwordbaru,
            foto: document.getElementById('filenya_user').value,
          };
          const mainAPI = axios.create({
            baseURL: process.env.VUE_APP_URL_API,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("tokensistem"),
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          mainAPI.post("oripro-ChangeUser", data).then(
            Response => {
              if (Response.data.response == 'error') {
                swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000
                }).fire({
                  icon: 'warning',
                  title: Response.data.message,
                });
                return false;
              }
              else {
                swal.fire({
                  title: "Informasi",
                  text: "Profil Pengguna Telah Dirubah!",
                  icon: "success"
                }).then(function () {
                  localStorage.removeItem('tokensistem');
                  localStorage.removeItem('usistem');
                  localStorage.removeItem('lsistem');
                  localStorage.removeItem('nmlsistem');
                  localStorage.removeItem('emlsistem');
                  localStorage.removeItem('nmusistem');
                  localStorage.removeItem('orsistem');
                  localStorage.removeItem('nmorsistem');
                  localStorage.removeItem('bdlsistem');
                  localStorage.removeItem('nmbdlsistem');
                  localStorage.removeItem('sbdsistem');
                  localStorage.removeItem('nmsbdsistem');
                  localStorage.removeItem('jbtsistem');
                  localStorage.removeItem('loksistem');
                  localStorage.removeItem('nmloksistem');
                  localStorage.removeItem('imgsistem');
                  localStorage.removeItem('tsistem');
                  window.location.replace("/");
                });
                $('.swal2-container').css("z-index", '999999');
              }
            }
          ).catch(
            error => {
              swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
              }).fire({
                icon: 'warning',
                title: error,
              });
            }
          )
        }
      });
      $('.swal2-container').css("z-index", '999999');
      return false;
    },
    async logoutAct() {
      swal.fire({
        title: "Konfirmasi",
        text: "Yakin Hendak Keluar Aplikasi?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonColor: "#33bff2",
        cancelButtonColor: "#6566aa",
        confirmButtonText: "Keluar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.ClearlocalStorage();
        }
      });

      return false;
    }
  },
  mounted() {
    this.getOtentifikasi();
  },
}
</script>

<style></style>