<template>
  <div class="contentlogin" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'MLokasi'">
    <Lokasi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLokasi'">
    <ProsesLokasi/>
  </div>
  <div class="content" v-if="route.name == 'MGedung'">
    <AreaGedung/>
  </div>
  <div class="content" v-if="route.name == 'ProsesGedung'">
    <ProsesGedung/>
  </div>
  <div class="content" v-if="route.name == 'MLantai'">
    <Lantai/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLantai'">
    <ProsesLantai/>
  </div>
  <div class="content" v-if="route.name == 'MRuangan'">
    <Ruangan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesRuangan'">
    <ProsesRuangan/>
  </div>
  <div class="content" v-if="route.name == 'MSifatBarang'">
    <SifatBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSifatBarang'">
    <ProsesSifatBarang/>
  </div>
  <div class="content" v-if="route.name == 'MKondisiBarang'">
    <KondisiBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKondisiBarang'">
    <ProsesKondisiBarang/>
  </div>
  <div class="content" v-if="route.name == 'MFungsiBarang'">
    <FungsiBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesFungsiBarang'">
    <ProsesFungsiBarang/>
  </div>
  <div class="content" v-if="route.name == 'MKlasifikasiBarang'">
    <KlasifikasiBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKlasifikasiBarang'">
    <ProsesKlasifikasiBarang/>
  </div>
  <div class="content" v-if="route.name == 'MKategoriBarang'">
    <KategoriBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriBarang'">
    <ProsesKategoriBarang/>
  </div>
  <div class="content" v-if="route.name == 'MJenisBarang'">
    <JenisBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesJenisBarang'">
    <ProsesJenisBarang/>
  </div>
  <div class="content" v-if="route.name == 'MKlasifikasiPenilaian'">
    <KlasifikasiPenilaian/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKlasifikasiPenilaian'">
    <ProsesKlasifikasiPenilaian/>
  </div>
  <div class="content" v-if="route.name == 'MMerkBarang'">
    <MerkBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesMerkBarang'">
    <ProsesMerkBarang/>
  </div>
  <div class="content" v-if="route.name == 'MTipeBarang'">
    <TipeBarang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesTipeBarang'">
    <ProsesTipeBarang/>
  </div>
  <div class="content" v-if="route.name == 'MPoinPengurang'">
    <PoinPengurang/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPoinPengurang'">
    <ProsesPoinPengurang/>
  </div>
  <div class="content" v-if="route.name == 'MJenisPerawatan'">
    <JenisPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesJenisPerawatan'">
    <ProsesJenisPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'MDurasiPerawatan'">
    <DurasiPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesDurasiPerawatan'">
    <ProsesDurasiPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'MSelesaiPerawatan'">
    <SelesaiPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSelesaiPerawatan'">
    <ProsesSelesaiPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'MMetodePerawatan'">
    <MetodePerawatan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesMetodePerawatan'">
    <ProsesMetodePerawatan/>
  </div>
  <div class="content" v-if="route.name == 'MAlatPerawatan'">
    <AlatPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesAlatPerawatan'">
    <ProsesAlatPerawatan/>
  </div>
  <div class="content" v-if="route.name == 'MOrganisasiInstitusi'">
    <OrganisasiInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesOrganisasiInstitusi'">
    <ProsesOrganisasiInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'MBidangInstitusi'">
    <BidangInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesBidangInstitusi'">
    <ProsesBidangInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'MSubBidangInstitusi'">
    <SubBidangInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSubBidangInstitusi'">
    <ProsesSubBidangInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'MJabatanInstitusi'">
    <JabatanInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'ProsesJabatanInstitusi'">
    <ProsesJabatanInstitusi/>
  </div>
  <div class="content" v-if="route.name == 'MJenisTenant'">
    <JenisTenant/>
  </div>
  <div class="content" v-if="route.name == 'ProsesJenisTenant'">
    <ProsesJenisTenant/>
  </div>
  <div class="content" v-if="route.name == 'MDurasiTenant'">
    <DurasiTenant/>
  </div>
  <div class="content" v-if="route.name == 'ProsesDurasiTenant'">
    <ProsesDurasiTenant/>
  </div>
  <div class="content" v-if="route.name == 'MPegawai'">
    <Pegawai/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPegawai'">
    <ProsesPegawai/>
  </div>
  <div class="content" v-if="route.name == 'Aset'">
    <Aset/>
  </div>
  <div class="content" v-if="route.name == 'MBentukBangunan'">
    <BentukBangunan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesBentukBangunan'">
    <ProsesBentukBangunan/>
  </div>
  <div class="content" v-if="route.name == 'MStatusPenggunaan'">
    <StatusPenggunaan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesStatusPenggunaan'">
    <ProsesStatusPenggunaan/>
  </div>
  <div class="content" v-if="route.name == 'MJenisPenggunaan'">
    <JenisPenggunaan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesJenisPenggunaan'">
    <ProsesJenisPenggunaan/>
  </div>
  <div class="content" v-if="route.name == 'MStrukturBangunan'">
    <StrukturBangunan/>
  </div>
  <div class="content" v-if="route.name == 'ProsesStrukturBangunan'">
    <ProsesStrukturBangunan/>
  </div>
  <div class="content" v-if="route.name == 'MAirBersih'">
    <AirBersih/>
  </div>
  <div class="content" v-if="route.name == 'ProsesAirBersih'">
    <ProsesAirBersih/>
  </div>
  <div class="content" v-if="route.name == 'MLimbah'">
    <Limbah/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLimbah'">
    <ProsesLimbah/>
  </div>
  <div class="content" v-if="route.name == 'DataGedung'">
    <DataGedung/>
  </div>
  <div class="content" v-if="route.name == 'DataLantai'">
    <DataLantai/>
  </div>
  <div class="content" v-if="route.name == 'DataRuangan'">
    <DataRuangan/>
  </div>
  <div class="content" v-if="route.name == 'Tenant'">
    <Tenant/>
  </div>
  <div class="content" v-if="route.name == 'ProsesTenant'">
    <ProsesTenant/>
  </div>
  <div class="content" v-if="route.name == 'MJadwalPegawai'">
    <Jadwal/>
  </div>
  <div class="content" v-if="route.name == 'Tugas'">
    <Tugas/>
  </div>
  <div class="content" v-if="route.name == 'ProsesTugas'">
    <ProsesTugas/>
  </div>
  <div class="content" v-if="route.name == 'KinerjaAset'">
    <KinerjaAset/>
  </div>
  <div class="content" v-if="route.name == 'DetailKinerjaAsetGedung'">
    <DetailKinerjaAsetGedung/>
  </div>
  <div class="content" v-if="route.name == 'DetailKinerjaAsetGedungFisik'">
    <DetailKinerjaAsetGedungFisik/>
  </div>
  <div class="content" v-if="route.name == 'DetailKinerjaAsetGedungFungsi'">
    <DetailKinerjaAsetGedungFungsi/>
  </div>
  <div class="content" v-if="route.name == 'KinerjaPersonil'">
    <KinerjaPersonil/>
  </div>
  <div class="content" v-if="route.name == 'DetailKinerjaPersonil'">
    <DetailKinerjaPersonil/>
  </div>
  <div class="content" v-if="route.name == 'Peta'">
    <Peta/>
  </div>
  <div class="content" v-if="route.name == 'QRCode'">
    <QRCode/>
  </div>
</template>

<script>
import Login from './components/views/Publik/Login.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Lokasi from './components/views/Reff/Lokasi/Lokasi.vue';
import ProsesLokasi from './components/views/Reff/Lokasi/ProsesLokasi.vue';
import AreaGedung from './components/views/Reff/AreaGedung/AreaGedung.vue';
import ProsesGedung from './components/views/Reff/AreaGedung/ProsesGedung.vue';
import Lantai from './components/views/Reff/Lantai/Lantai.vue';
import ProsesLantai from './components/views/Reff/Lantai/ProsesLantai.vue';
import Ruangan from './components/views/Reff/Ruangan/Ruangan.vue';
import ProsesRuangan from './components/views/Reff/Ruangan/ProsesRuangan.vue';

import SifatBarang from './components/views/Reff/SifatBarang/SifatBarang.vue';
import ProsesSifatBarang from './components/views/Reff/SifatBarang/ProsesSifatBarang.vue';
import KondisiBarang from './components/views/Reff/KondisiBarang/KondisiBarang.vue';
import ProsesKondisiBarang from './components/views/Reff/KondisiBarang/ProsesKondisiBarang.vue';
import FungsiBarang from './components/views/Reff/FungsiBarang/FungsiBarang.vue';
import ProsesFungsiBarang from './components/views/Reff/FungsiBarang/ProsesFungsiBarang.vue';
import KlasifikasiBarang from './components/views/Reff/KlasifikasiBarang/KlasifikasiBarang.vue';
import ProsesKlasifikasiBarang from './components/views/Reff/KlasifikasiBarang/ProsesKlasifikasiBarang.vue';
import KategoriBarang from './components/views/Reff/KategoriBarang/KategoriBarang.vue';
import ProsesKategoriBarang from './components/views/Reff/KategoriBarang/ProsesKategoriBarang.vue';
import JenisBarang from './components/views/Reff/JenisBarang/JenisBarang.vue';
import ProsesJenisBarang from './components/views/Reff/JenisBarang/ProsesJenisBarang.vue';
import KlasifikasiPenilaian from './components/views/Reff/KlasifikasiPenilaian/KlasifikasiPenilaian.vue';
import ProsesKlasifikasiPenilaian from './components/views/Reff/KlasifikasiPenilaian/ProsesKlasifikasiPenilaian.vue';
import MerkBarang from './components/views/Reff/MerkBarang/MerkBarang.vue';
import ProsesMerkBarang from './components/views/Reff/MerkBarang/ProsesMerkBarang.vue';
import TipeBarang from './components/views/Reff/TipeBarang/TipeBarang.vue';
import ProsesTipeBarang from './components/views/Reff/TipeBarang/ProsesTipeBarang.vue';
import PoinPengurang from './components/views/Reff/PoinPengurang/PoinPengurang.vue';
import ProsesPoinPengurang from './components/views/Reff/PoinPengurang/ProsesPoinPengurang.vue';

import JenisPerawatan from './components/views/Reff/JenisPerawatan/JenisPerawatan.vue';
import ProsesJenisPerawatan from './components/views/Reff/JenisPerawatan/ProsesJenisPerawatan.vue';
import DurasiPerawatan from './components/views/Reff/DurasiPerawatan/DurasiPerawatan.vue';
import ProsesDurasiPerawatan from './components/views/Reff/DurasiPerawatan/ProsesDurasiPerawatan.vue';
import SelesaiPerawatan from './components/views/Reff/SelesaiPerawatan/SelesaiPerawatan.vue';
import ProsesSelesaiPerawatan from './components/views/Reff/SelesaiPerawatan/ProsesSelesaiPerawatan.vue';
import MetodePerawatan from './components/views/Reff/MetodePerawatan/MetodePerawatan.vue';
import ProsesMetodePerawatan from './components/views/Reff/MetodePerawatan/ProsesMetodePerawatan.vue';
import AlatPerawatan from './components/views/Reff/AlatPerawatan/AlatPerawatan.vue';
import ProsesAlatPerawatan from './components/views/Reff/AlatPerawatan/ProsesAlatPerawatan.vue';

import OrganisasiInstitusi from './components/views/Reff/OrganisasiInstitusi/OrganisasiInstitusi.vue';
import ProsesOrganisasiInstitusi from './components/views/Reff/OrganisasiInstitusi/ProsesOrganisasiInstitusi.vue';
import BidangInstitusi from './components/views/Reff/BidangInstitusi/BidangInstitusi.vue';
import ProsesBidangInstitusi from './components/views/Reff/BidangInstitusi/ProsesBidangInstitusi.vue';
import SubBidangInstitusi from './components/views/Reff/SubBidang/SubBidangInstitusi.vue';
import ProsesSubBidangInstitusi from './components/views/Reff/SubBidang/ProsesSubBidangInstitusi.vue';
import JabatanInstitusi from './components/views/Reff/JabatanInstitusi/JabatanInstitusi.vue';
import ProsesJabatanInstitusi from './components/views/Reff/JabatanInstitusi/ProsesJabatanInstitusi.vue';

import JenisTenant from './components/views/Reff/JenisTenant/JenisTenant.vue';
import ProsesJenisTenant from './components/views/Reff/JenisTenant/ProsesJenisTenant.vue';
import DurasiTenant from './components/views/Reff/DurasiTenant/DurasiTenant.vue';
import ProsesDurasiTenant from './components/views/Reff/DurasiTenant/ProsesDurasiTenant.vue';

import BentukBangunan from './components/views/Reff/BentukBangunan/BentukBangunan.vue';
import ProsesBentukBangunan from './components/views/Reff/BentukBangunan/ProsesBentukBangunan.vue';
import StatusPenggunaan from './components/views/Reff/StatusPenggunaan/StatusPenggunaan.vue';
import ProsesStatusPenggunaan from './components/views/Reff/StatusPenggunaan/ProsesStatusPenggunaan.vue';
import JenisPenggunaan from './components/views/Reff/JenisPenggunaan/JenisPenggunaan.vue';
import ProsesJenisPenggunaan from './components/views/Reff/JenisPenggunaan/ProsesJenisPenggunaan.vue';
import StrukturBangunan from './components/views/Reff/StrukturBangunan/StrukturBangunan.vue';
import ProsesStrukturBangunan from './components/views/Reff/StrukturBangunan/ProsesStrukturBangunan.vue';
import AirBersih from './components/views/Reff/AirBersih/AirBersih.vue';
import ProsesAirBersih from './components/views/Reff/AirBersih/ProsesAirBersih.vue';
import Limbah from './components/views/Reff/Limbah/Limbah.vue';
import ProsesLimbah from './components/views/Reff/Limbah/ProsesLimbah.vue';

import Pegawai from './components/views/Pegawai/Pegawai.vue';
import ProsesPegawai from './components/views/Pegawai/ProsesPegawai.vue';

import Aset from './components/views/Aset/Aset.vue';
import DataGedung from './components/views/DataGedung/DataGedung.vue';
import DataLantai from './components/views/DataLantai/DataLantai.vue';
import DataRuangan from './components/views/DataRuangan/DataRuangan.vue';
import Tenant from './components/views/DataTenant/Tenant.vue';
import ProsesTenant from './components/views/DataTenant/ProsesTenant.vue';
import Jadwal from './components/views/Jadwal/Jadwal.vue';
import Tugas from './components/views/Tugas/Tugas.vue';
import ProsesTugas from './components/views/Tugas/ProsesTugas.vue';
import KinerjaAset from './components/views/KinerjaAset/KinerjaAset.vue';
import DetailKinerjaAsetGedung from './components/views/KinerjaAset/DetailKinerjaAsetGedung.vue';
import DetailKinerjaAsetGedungFisik from './components/views/KinerjaAset/DetailKinerjaAsetGedungFisik.vue';
import DetailKinerjaAsetGedungFungsi from './components/views/KinerjaAset/DetailKinerjaAsetGedungFungsi.vue';
import KinerjaPersonil from './components/views/KinerjaPersonil/KinerjaPersonil.vue';
import DetailKinerjaPersonil from './components/views/KinerjaPersonil/DetailKinerjaPersonil.vue';
import Peta from './components/views/Peta/Peta.vue';
import QRCode from './components/views/QRCode/QRCode.vue';

import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Login,Dashboard,Lokasi,ProsesLokasi,AreaGedung,ProsesGedung,Lantai,ProsesLantai
    ,Ruangan,ProsesRuangan,SifatBarang,ProsesSifatBarang,KondisiBarang,ProsesKondisiBarang,FungsiBarang,ProsesFungsiBarang
    ,KlasifikasiBarang,ProsesKlasifikasiBarang,KategoriBarang,ProsesKategoriBarang
    ,JenisBarang,ProsesJenisBarang,KlasifikasiPenilaian,ProsesKlasifikasiPenilaian,MerkBarang,ProsesMerkBarang,TipeBarang,ProsesTipeBarang
    ,JenisPerawatan,ProsesJenisPerawatan,DurasiPerawatan,ProsesDurasiPerawatan,SelesaiPerawatan,ProsesSelesaiPerawatan
    ,MetodePerawatan,ProsesMetodePerawatan,AlatPerawatan,ProsesAlatPerawatan,PoinPengurang,ProsesPoinPengurang
    ,OrganisasiInstitusi,ProsesOrganisasiInstitusi,BidangInstitusi,ProsesBidangInstitusi
    ,SubBidangInstitusi,ProsesSubBidangInstitusi,JabatanInstitusi,ProsesJabatanInstitusi
    ,JenisTenant,ProsesJenisTenant,BentukBangunan,ProsesBentukBangunan
    ,StatusPenggunaan,ProsesStatusPenggunaan,JenisPenggunaan,ProsesJenisPenggunaan
    ,StrukturBangunan,ProsesStrukturBangunan,AirBersih,ProsesAirBersih,Limbah,ProsesLimbah
    ,DurasiTenant,ProsesDurasiTenant,Pegawai,ProsesPegawai,Tenant,ProsesTenant
    ,Aset,DataGedung,DataLantai,DataRuangan,Jadwal,Tugas,ProsesTugas,KinerjaAset,DetailKinerjaAsetGedung
    ,DetailKinerjaAsetGedungFisik,DetailKinerjaAsetGedungFungsi,KinerjaPersonil,DetailKinerjaPersonil
    ,Peta,QRCode
  },
  mounted() {
    document.title = "ORIPRO";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
