<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h5 class="text-primary">Detail Penugasan {{ tugassekarang }}</h5>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Tugas">Data Penugasan </a></li>
                                <li class="breadcrumb-item active">Proses Tugas</li>

                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row p-3">
                        <div class="col-md-12">
                            <div class="card cardhijau card-outline">
                                <div class="card-header bg-primary" style="border-radius: 0px;">
                                    <h3 class="card-title">Check list</h3>
                                </div>
                                <div class="card-body p-2">
                                    <input type="hidden" class="form-control form-control-sm" id="id_jadwal"
                                        name="id_jadwal" placeholder="id_jadwal" v-model="id_jadwal">
                                    <input type="hidden" class="form-control form-control-sm" id="aset_barang"
                                        name="aset_barang" placeholder="aset_barang" v-model="aset_barang">
                                    <input type="hidden" class="form-control form-control-sm" id="pj_aset"
                                        name="pj_aset" placeholder="pj_aset" v-model="pj_aset">
                                    <input type="hidden" class="form-control form-control-sm" id="status_pengganti"
                                        name="status_pengganti" placeholder="status_pengganti"
                                        v-model="status_pengganti">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Status Check<small class="text-info"> (*Pilih Data)</small></label>
                                            <v-select :options="selesai" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedselesai"
                                                @update:modelValue="onselesaiChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Kondisi Aset<small class="text-info"> (*Pilih Data)</small></label>
                                            <v-select :options="kondisi" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedkondisi"
                                                @update:modelValue="onkondisiChange"></v-select>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-6">
                                            <label>Fungsi Aset<small class="text-info"> (*Pilih Data)</small></label>
                                            <v-select :options="fungsi" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedfungsi"
                                                @update:modelValue="onfungsiChange"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Keterangan</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-clipboard"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="catatan"
                                                    name="catatan" placeholder="Keterangan" v-model="catatan">
                                            </div>
                                        </div>
                                        <!--GAMBAR AWAL-->
                                        <div class="col-sm-6">
                                            <label>Kondisi Awal<small class="text-info"> (*images files)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_awal" name="fileToUploadfilenya_awal"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_awal" name="filenya_awal"
                                                    placeholder="File Dokumentasi">
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <label>Opsi</label>
                                                    <div class="row">
                                                        <div class="col-sm-8">
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar"
                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                    aria-valuemax="100" style="width:0%"
                                                                    id="persennyafilenya_awal">
                                                                    <span id="tulisanpersenfilenya_awal">0%</span>
                                                                </div>
                                                            </div>
                                                            <br>
                                                            <span class="text-success"
                                                                id="suksesnyafilenya_awal"></span>
                                                        </div>
                                                        <div class="col-sm-4 text-center">
                                                            <button type="button"
                                                                class="btn btn-warning btn-sm lebar text-white"
                                                                @click="upload('filenya_awal', 'image/*')">Upload
                                                                File</button>
                                                            <button type="button" class="btn btn-danger btn-sm lebar"
                                                                @click="clearfile('filenya_awal')">Hapus File</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--GAMBAR AWAL END-->

                                        <!--GAMBAR AKHIR-->
                                        <div class="col-sm-6">
                                            <label>Kondisi Akhir<small class="text-info"> (*images files)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_akhir" name="fileToUploadfilenya_akhir"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="image/*">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_akhir" name="filenya_akhir"
                                                    placeholder="File Dokumentasi">
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <label>Opsi</label>
                                                    <div class="row">
                                                        <div class="col-sm-8">
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar"
                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                    aria-valuemax="100" style="width:0%"
                                                                    id="persennyafilenya_akhir">
                                                                    <span id="tulisanpersenfilenya_akhir">0%</span>
                                                                </div>
                                                            </div>
                                                            <br>
                                                            <span class="text-success"
                                                                id="suksesnyafilenya_akhir"></span>
                                                        </div>
                                                        <div class="col-sm-4 text-center">
                                                            <button type="button"
                                                                class="btn btn-warning btn-sm lebar text-white"
                                                                @click="upload('filenya_akhir', 'image/*')">Upload
                                                                File</button>
                                                            <button type="button" class="btn btn-danger btn-sm lebar"
                                                                @click="clearfile('filenya_akhir')">Hapus File</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--GAMBAR AKHIR END-->

                                    </div>
                                </div>
                                <div class="card-footer p-2">
                                    <button type="button" class="btn btn-sm btn-success float-left text-white lebar"
                                        @click="simpan()"><i class="fas fa-plus-square"></i> Simpan</button>
                                    <button type="button" class="btn btn-sm btn-secondary float-left text-white lebar"
                                        @click="batalproses()"><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import vSelect from 'vue-select';
import swal from 'sweetalert2';

import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            validasi: false,
            tugassekarang: '',
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
            datapesan: [],
            datajadwal: [],
            carikata: '',
            pilihan: false,
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),

            selesai: [],
            dataselesai: [],
            defaultSelectedselesai: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedselesai: 0,

            kondisi: [],
            datakondisi: [],
            defaultSelectedkondisi: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkondisi: 0,

            fungsi: [],
            datafungsi: [],
            defaultSelectedfungsi: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedfungsi: 0,

            id_jadwal: '',
            aset_barang: '',
            pj_aset: '',
            status_pengganti: '',

            catatan: '',
            latitude: '',
            longitude: '',
        }
    },
    methods: {
        validasidata() {
            if (this.selectedselesai === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Status Penugasan...'
                });
                return false;
            }
            if (this.selectedselesai == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Status Penugasan...'
                });
                return false;
            }

            if (this.selectedkondisi === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Kondisi Aset...'
                });
                return false;
            }
            if (this.selectedkondisi == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Kondisi Aset...'
                });
                return false;
            }

            if (this.selectedfungsi === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Fungsi Aset...'
                });
                return false;
            }
            if (this.selectedfungsi == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Fungsi Aset...'
                });
                return false;
            }

            this.validasi = true;
            return;
        },
        onselesaiChange(a) {
            try {
                this.selectedselesai = this.selesai[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkondisiChange(a) {
            try {
                this.selectedkondisi = this.kondisi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onfungsiChange(a) {
            try {
                this.selectedfungsi = this.fungsi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        clearfile(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        upload(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        batalproses() {
            window.location.replace("/Tugas");
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            else {
                if (navigator.geolocation) {
                    const successCallback = (position) => {
                        this.latitude = position.coords.latitude;
                        this.longitude = position.coords.longitude;

                        var d = new Date();
                        var month = '' + (d.getMonth() + 1);
                        var day = '' + d.getDate();
                        var year = d.getFullYear();
                        var hour = d.getHours();
                        var minutes = d.getMinutes();
                        var second = d.getSeconds();

                        if (month.length < 2)
                            month = '0' + month;
                        if (day.length < 2)
                            day = '0' + day;

                        var tanggal = [year, month, day].join('-');
                        var jam = [hour, minutes, second].join(':');

                        var fd = new FormData();
                        fd.append("id_jadwal", this.id_jadwal);
                        fd.append("aset_barang", this.aset_barang);
                        fd.append("tanggal", tanggal);
                        fd.append("pj_aset", this.pj_aset);
                        fd.append("status_pengganti", this.status_pengganti);
                        fd.append("jam_selesai", jam);
                        fd.append("status_selesai", this.selectedselesai);
                        fd.append("kondisi", this.selectedkondisi);
                        fd.append("fungsi", this.selectedfungsi);
                        fd.append("latitude", this.latitude);
                        fd.append("longitude", this.longitude);
                        fd.append("filenya_awal", document.getElementById('filenya_awal').value);
                        fd.append("filenya_akhir", document.getElementById('filenya_akhir').value);
                        fd.append("catatan", this.catatan);
                        fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

                        const mainAPI = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                "Content-Type": "form-data",
                                Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            },
                        });

                        mainAPI.post("datatodolist_oripro-AddDataPerawatan", fd).then(
                            Response => {
                                if (Response.data.response == 'error') {
                                    swal.fire('Peringatan', Response.data.message, 'error');
                                }
                                else {
                                    swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                        window.location.replace("/Tugas");
                                    });
                                }
                                $('.swal2-container').css("z-index", '999999');
                            }
                        ).catch(function (error) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: error
                            });
                            $('.swal2-container').css("z-index", '999999');
                        }
                        )

                    };

                    const errorCallback = (error) => {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error,
                        });
                        return false;
                    };

                    navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

                } else {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Aktifkan GPS / Lokasi Gadget..',
                    });
                    return false;
                }
            }
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("mprwt_oripro-GetSelesaiPerawatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataselesai = [];
                        this.selesai = [];
                        this.dataselesai = Response.data.content.data;
                        this.dataselesai.forEach((item) => {
                            this.selesai.push({
                                label: item.nama,
                                code: this.dataselesai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });

                        this.selesai.unshift({
                            label: 'Pilih Salah Satu',
                            code: '0',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI1 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI1.get("mb_oripro-GetKondisiBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakondisi = [];
                        this.kondisi = [];
                        this.datakondisi = Response.data.content.data;
                        this.datakondisi.forEach((item) => {
                            this.kondisi.push({
                                label: item.nama,
                                code: this.datakondisi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });

                        this.kondisi.unshift({
                            label: 'Pilih Salah Satu',
                            code: '0',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI3 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI3.get("mb_oripro-GetFungsiBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datafungsi = [];
                        this.fungsi = [];
                        this.datafungsi = Response.data.content.data;
                        this.datafungsi.forEach((item) => {
                            this.fungsi.push({
                                label: item.nama,
                                code: this.datafungsi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });

                        this.fungsi.unshift({
                            label: 'Pilih Salah Satu',
                            code: '0',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("datajadwal_oripro-GetDataJadwal?kd=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajadwal = [];
                        this.datajadwal = Response.data.content.data;
                        this.tugassekarang = '';
                        this.tugassekarang = Response.data.content.data[0].nmmetode_perawatan
                            + ' ' + Response.data.content.data[0].nmjenis_perawatan
                            + ' ' + Response.data.content.data[0].nmdurasi_perawatan
                            + ' ' + Response.data.content.data[0].nmjenis_aset
                            + ' ' + Response.data.content.data[0].nmruangan
                            + ' ' + Response.data.content.data[0].nmlantai
                            + ' ' + Response.data.content.data[0].nmgedung
                            + ' ' + Response.data.content.data[0].nmlokasi;

                        this.id_jadwal = Response.data.content.data[0].id;
                        this.aset_barang = Response.data.content.data[0].aset_barang;
                        this.pj_aset = Response.data.content.data[0].pj_aset;
                        this.status_pengganti = Response.data.content.data[0].status_pengganti;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        refresh() {
            window.location.replace("/Tugas");
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>