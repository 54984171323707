<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Manajemen Bentuk Bangunan</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                <li class="breadcrumb-item active">Data Bentuk Bangunan</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-2 float-left pb-2">
                                        <button type="button" class="btn btn-sm btn-primary float-left text-white"
                                            @click="prosesdata('Tambah')">
                                            <i class="fas fa-plus"></i> Tambah Data
                                        </button>

                                    </div>
                                    <div class="col-sm-8 float-left pb-2">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="col-sm-1 float-left pb-2">
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-secondary btn-sm lebar"
                                                style="height: 30px;">Export</button>
                                            <button type="button"
                                                class="btn btn-secondary dropdown-toggle dropdown-icon"
                                                data-toggle="dropdown" style="height: 30px;">
                                                <span class="sr-only">Toggle Dropdown</span>
                                            </button>
                                            <div class="dropdown-menu" role="menu">
                                                <a class="dropdown-item" href="#"
                                                    @click="G_tableCSV('_Kondisi_Aset_')">Excel</a>
                                                <a class="dropdown-item" href="#" @click="printContent()">Print</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 80px;">No</th>
                                                    <th style="width: 150px;">Kode</th>
                                                    <th>Nama</th>
                                                    <th style="width: 80px;">Skoring<br>Prioritas</th>
                                                    <th style="width: 130px;">Opsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td>{{ datalist.kode }}</td>
                                                        <td>{{ datalist.nama }}</td>
                                                        <td>{{ G_numKoma(datalist.skor_prio) }}</td>
                                                        <td class="text-center">
                                                            <button type="button"
                                                                class="btn btn-primary btn-sm lebar text-white"
                                                                @click="prosesdata(datalist.id)"><i
                                                                    class="fas fa-edit"></i> Edit Data</button>
                                                            <button type="button" class="btn btn-info btn-sm lebar"
                                                                @click="hapus(datalist.id)"><i
                                                                    class="fas fa-trash-alt"></i> Hapus Data</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div class="col-md-12 text-center" v-if="(datapesan == 'data kosong')">Data
                                            Masih Kosong</div>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { inject } from 'vue';
import swal from 'sweetalert2';
import xlsx from 'xlsx/dist/xlsx.full.min';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, swal
    },
    data() {
        return {
            halamanloading: true,
            carikata: '',
            datamaster: [],
            datapesan: [],
            ekportexcel: [],
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        async G_tableCSV(namefile) {
            this.ekportexcel = [];
            this.halamanloading = true;

            var mainAPIMaster = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIMaster.get("minfsipil_oripro-GetBentukBangunan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    this.datamaster = [];
                    this.ekportexcel.push({
                        "No": '1',
                        "Data": 'Data Tidak Diketemukan',
                    });
                    if (this.datapesan == 'data diketemukan') {
                        this.ekportexcel = [];

                        this.datamaster = Response.data.content.data;
                        for (let i = 0; i < this.datamaster.length; i++) {
                            this.ekportexcel.push({
                                "No": i + 1,
                                "Kode": this.datamaster[i].kode,
                                "Nama": this.datamaster[i].nama,
                                "Skoring": parseFloat(this.datamaster[i].skor_prio),
                            });
                        }
                    }

                    function formatColumn(worksheet, col, fmt) {
                        const range = XLSX.utils.decode_range(worksheet['!ref'])
                        // note: range.s.r + 1 skips the header row
                        for (let row = range.s.r + 1; row <= range.e.r; ++row) {
                            const ref = XLSX.utils.encode_cell({ r: row, c: col })
                            if (worksheet[ref] && worksheet[ref].t === 'n') {
                                worksheet[ref].z = fmt
                            }
                        }
                    }
                    const currency = '0.00';
                    const XLSX = xlsx;
                    const workbook = XLSX.utils.book_new();
                    const worksheet = XLSX.utils.json_to_sheet(this.ekportexcel);

                    // ARRAY NUMBER SILAHKAN MASUKKAN NOMOR KOLOM
                    for (let col of [3]) {
                        formatColumn(worksheet, col, currency)
                    }
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Bentuk Bangunan");
                    XLSX.writeFile(workbook, this.G_formatDate(Date(), 'd-M-Y') + namefile + ".xlsx");
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.halamanloading = false;
            })

        },
        printContent() {
            var divToPrint = document.getElementById('tabeldata');
            var htmlToPrint = '' +
                '<style type="text/css">' +
                'table th, table td {' +
                'border:1px solid #000;' +
                '}' +
                '</style>';
            htmlToPrint += divToPrint.outerHTML;
            const printWindow = window.open('', '', 'height=1000,width=1000')
            printWindow.document.write(htmlToPrint)
            printWindow.print()

        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#33bff2",
                cancelButtonColor: "#6566aa",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("minfsipil_oripro-DeleteBentukBangunan", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/MBentukBangunan");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async AmbilData() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("minfsipil_oripro-GetBentukBangunan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesBentukBangunan/" + bytes);
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>