<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Infografis Manajemen Aset dan Pegawai</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Infografis</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardbiruatas2">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-12 float-left pb-2">
                                        <v-select :options="lokasi" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedLokasi" @update:modelValue="onlokasiChange"
                                            style="background-color: #ffffff;z-index: 1;"></v-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <!-- Add the bg color to the header using any of the bg-* classes -->
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Kinerja Infrastruktur Aset</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <BarChartPengajuan height="350" :options="chartOptionsBarChartPengajuan"
                                        :series="seriesBarChartPengajuan">
                                    </BarChartPengajuan>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <!-- Add the bg color to the header using any of the bg-* classes -->
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Kinerja Kondisi Aset</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <BarChartPengajuanKondisi height="350"
                                        :options="chartOptionsBarChartPengajuanKondisi"
                                        :series="seriesBarChartPengajuanKondisi">
                                    </BarChartPengajuanKondisi>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <!-- Add the bg color to the header using any of the bg-* classes -->
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Kinerja Fungsi Aset</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <BarChartPengajuanFungsi height="350" :options="chartOptionsBarChartPengajuanFungsi"
                                        :series="seriesBarChartPengajuanFungsi">
                                    </BarChartPengajuanFungsi>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <!-- Add the bg color to the header using any of the bg-* classes -->
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Kinerja Pegawai</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <BarChartPengajuan2 height="350" :options="chartOptionsBarChartPengajuan2"
                                        :series="seriesBarChartPengajuan2">
                                    </BarChartPengajuan2>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">&nbsp;</div>
                        <div class="col-sm-12">
                            <!-- TABLE: LATEST ORDERS -->
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Skoring Aset</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="table-responsive">
                                        <table class="table m-0">
                                            <thead>
                                                <tr>
                                                    <th>Kode</th>
                                                    <th>Nama Lokasi / Gedung</th>
                                                    <th>Status Infrastruktur<br>Skor</th>
                                                    <th>Status Kondisi<br>Skor</th>
                                                    <th>Status Fungsi<br>Skor</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in datatabelresume" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td><a href="javascript:void(0)">{{ datalist.kode }}</a></td>
                                                        <td>
                                                            {{ datalist.namalokasi }}<br>
                                                            {{ datalist.namagedung }}
                                                        </td>
                                                        <td class="text-center">
                                                            <span :class="[(datalist.nama_skor_infrastruktur == 'belum ada data'
                                                                ? 'badge badge-danger' : 'badge badge-default')]"
                                                                :style="[(datalist.nama_skor_infrastruktur == 'belum ada data'
                                                                    ? 'color: ' + datalist.warna_skor_infrastruktur : 'color: '
                                                                    + datalist.warna_skor_infrastruktur)]">
                                                                {{ datalist.nama_skor_infrastruktur }}
                                                            </span><br>
                                                            {{ G_numFormatKoma(datalist.skor_infrastruktur) }}
                                                        </td>
                                                        <td class="text-center">
                                                            <span :class="[(datalist.nama_skor_kondisi == 'belum ada data'
                                                                ? 'badge badge-danger' : 'badge badge-default')]"
                                                                :style="[(datalist.nama_skor_kondisi == 'belum ada data'
                                                                    ? 'color: ' + datalist.warna_skor_kondisi : 'color: '
                                                                    + datalist.warna_skor_kondisi)]">
                                                                {{ datalist.nama_skor_kondisi }}
                                                            </span><br>
                                                            {{ G_numFormatKoma(datalist.skor_kondisi) }}
                                                        </td>
                                                        <td class="text-center">
                                                            <span :class="[(datalist.nama_skor_fungsi == 'belum ada data'
                                                                ? 'badge badge-danger' : 'badge badge-default')]"
                                                                :style="[(datalist.nama_skor_fungsi == 'belum ada data'
                                                                    ? 'color: ' + datalist.warna_skor_fungsi : 'color: '
                                                                    + datalist.warna_skor_fungsi)]">
                                                                {{ datalist.nama_skor_fungsi }}
                                                            </span><br>
                                                            {{ G_numFormatKoma(datalist.skor_fungsi) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer text-center">
                                    <a href="/KinerjaAset">Lihat Semua Kinerja Aset</a>
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header">
                                    <h3 class="card-title">Urutan Skoring Pegawai</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-0">
                                    <ul class="users-list clearfix">
                                        <template v-for="(datalist, urutlist) in datatabelpegawai" :key="urutlist">
                                            <li>
                                                <img :src="folder_foto + datalist.foto"
                                                    class="img-size-75 img-circle animation__wobble" alt="User Image"
                                                    style="height: 100px;">
                                                <a class="users-list-name " href="#">
                                                    {{ datalist.nama }} <br>
                                                    {{ datalist.nip }}
                                                </a>
                                                <span class="users-list-date"
                                                    :style="'color:' + datalist.vervalwarnanilai">
                                                    {{ datalist.vervalnamaskorakhir }}
                                                </span>
                                            </li>
                                        </template>
                                    </ul>
                                    <!-- /.users-list -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer text-center">
                                    <a href="/KinerjaPersonil">Lihat Detail Data Kinerja Pegawai</a>
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!--/.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, BarChartPengajuan: VueApexCharts
        , BarChartPengajuanKondisi: VueApexCharts, BarChartPengajuanFungsi: VueApexCharts
        , BarChartPengajuan2: VueApexCharts, vSelect
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            lokasiluser: JSON.parse(localStorage.getItem("loksistem")),
            namalokasiuser: JSON.parse(localStorage.getItem("nmloksistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
            folder_foto: process.env.VUE_APP_URL_API + 'view_oripro-GambarUser?f=',

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,

            chartOptionsBarChartPengajuan: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                colors: ['#00E396'],
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Skor', 'Target Ideal'],
                    markers: {
                        fillColors: ['#00E396', '#775DD0']
                    }
                }
            },
            seriesBarChartPengajuan: [],

            chartOptionsBarChartPengajuanKondisi: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                colors: ['#00E396'],
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Skor', 'Target Ideal'],
                    markers: {
                        fillColors: ['#00E396', '#775DD0']
                    }
                }
            },
            seriesBarChartPengajuanKondisi: [],

            chartOptionsBarChartPengajuanFungsi: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                colors: ['#00E396'],
                legend: {
                    show: true,
                    showForSingleSeries: true,
                    customLegendItems: ['Skor', 'Target Ideal'],
                    markers: {
                        fillColors: ['#00E396', '#775DD0']
                    }
                }
            },
            seriesBarChartPengajuanFungsi: [],

            chartOptionsBarChartPengajuan2: {
                chart: {
                    type: 'line',
                    height: 350,
                },
                stroke: {
                    width: [0, 4]
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1]
                },
                labels: [],
                xaxis: {
                    type: 'text',
                    labels: {
                        rotate: -90,
                        rotateAlways: true,
                        style: {
                            fontSize: '8px',
                        }
                    }
                },
                yaxis: [{
                    title: {
                        text: 'Realisasi Kinerja',
                    },

                }, {
                    opposite: true,
                    title: {
                        text: 'Target Kinerja'
                    }
                }]
            },
            seriesBarChartPengajuan2: [],

            datatabelpegawai: [],
            datatabelresume: [],
        }
    },
    methods: {
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
                this.ambildataterpilih();
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambildata() {
            this.halamanloading = true;

            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser > 4) {
                kodelokasi = JSON.parse(localStorage.getItem("loksistem"));
            }
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            await mainAPI4.get("m_oripro-GetLokasi?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        if (this.leveluser < 4) {
                            this.lokasi.unshift({
                                label: 'Semua Data',
                                code: 0,
                                tag: 0,
                            });
                        }
                        if (this.leveluser > 4) {
                            this.defaultSelectedLokasi = {
                                code: kodelokasi,
                                label: JSON.parse(localStorage.getItem("nmloksistem")),
                                tag: kodelokasi,
                            };
                            this.selectedlokasi = kodelokasi;
                        }

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            this.halamanloading = true;
            await mainAPI.get("dash_oripro-GetBarDashboradInfrastruktur?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.seriesBarChartPengajuan = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.seriesBarChartPengajuan = [
                            {
                                name: 'Skor',
                                data: Response.data.content.data,
                            }
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI.get("dash_oripro-GetBarDashboardKondisiInfrastruktur?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.seriesBarChartPengajuanKondisi = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.seriesBarChartPengajuanKondisi = [
                            {
                                name: 'Skor',
                                data: Response.data.content.data,
                            }
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI.get("dash_oripro-GetBarDashboardFungsiInfrastruktur?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.seriesBarChartPengajuanFungsi = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.seriesBarChartPengajuanFungsi = [
                            {
                                name: 'Skor',
                                data: Response.data.content.data,
                            }
                        ];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI.get("dash_oripro-GetBarDashboardPegawai?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.chartOptionsBarChartPengajuan2.labels = [];
                        this.seriesBarChartPengajuan2 = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.chartOptionsBarChartPengajuan2 = {
                            labels: Response.data.content.label,
                        }
                        this.seriesBarChartPengajuan2 = [
                            {
                                name: 'Realisasi Kinerja',
                                type: 'column',
                                data: Response.data.content.skor,
                            }, {
                                name: 'Target Kinerja',
                                type: 'line',
                                data: Response.data.content.target,
                            }

                        ]

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI.get("dash_oripro-GetSkorInfrastukturAll?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    this.datatabelresume = [];
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datatabelresume = [];
                        this.datatabelresume = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })

            this.halamanloading = true;
            await mainAPI.get("dash_oripro-GetSkorPegawai?kdlokasi=" + kodelokasi + "&kdlevellebihrendah=6").then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    this.datatabelpegawai = [];
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datatabelpegawai = [];
                        this.datatabelpegawai = Response.data.content.data;
                        this.datatabelpegawai.sort((a, b) => {
                            return a.urutskor - b.urutskor;
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
        },
        async ambildataterpilih() {
            this.halamanloading = true;

            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser > 4) {
                kodelokasi = JSON.parse(localStorage.getItem("loksistem"));
            }

            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            this.halamanloading = true;
            await mainAPI2.get("dash_oripro-GetBarDashboradInfrastruktur?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.seriesBarChartPengajuan = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.seriesBarChartPengajuan = [
                            {
                                name: 'Skor',
                                data: Response.data.content.data,
                            }
                        ];
                    }
                    else {
                        this.seriesBarChartPengajuan = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI2.get("dash_oripro-GetBarDashboardKondisiInfrastruktur?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.seriesBarChartPengajuanKondisi = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.seriesBarChartPengajuanKondisi = [
                            {
                                name: 'Skor',
                                data: Response.data.content.data,
                            }
                        ];
                    }
                    else {
                        this.seriesBarChartPengajuanKondisi = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI2.get("dash_oripro-GetBarDashboardFungsiInfrastruktur?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.seriesBarChartPengajuanFungsi = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.seriesBarChartPengajuanFungsi = [
                            {
                                name: 'Skor',
                                data: Response.data.content.data,
                            }
                        ];
                    }
                    else {
                        this.seriesBarChartPengajuanFungsi = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI2.get("dash_oripro-GetBarDashboardPegawai?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.chartOptionsBarChartPengajuan2.labels = [];
                        this.seriesBarChartPengajuan2 = [];
                        //this.namalokasi = Response.data.content.data[0].nama;
                        this.chartOptionsBarChartPengajuan2 = {
                            labels: Response.data.content.label,
                        }
                        this.seriesBarChartPengajuan2 = [
                            {
                                name: 'Realisasi Kinerja',
                                type: 'column',
                                data: Response.data.content.skor,
                            }, {
                                name: 'Target Kinerja',
                                type: 'line',
                                data: Response.data.content.target,
                            }

                        ]

                    }
                    else {
                        this.chartOptionsBarChartPengajuan2.labels = [];
                        this.seriesBarChartPengajuan2 = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            this.halamanloading = true;
            await mainAPI2.get("dash_oripro-GetSkorInfrastukturAll?kdlokasi=" + kodelokasi).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    this.datatabelpegawai = [];
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datatabelpegawai = [];
                        this.datatabelpegawai = Response.data.content.data;
                        this.datatabelpegawai.sort((a, b) => {
                            return b.vervalskorakhir - a.vervalskorakhir;
                        });
                    }
                    else {
                        this.datatabelpegawai = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })

            this.halamanloading = true;
            await mainAPI2.get("dash_oripro-GetSkorPegawai?kdlokasi=" + kodelokasi + "&kdlevellebihrendah=6").then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    this.datatabelpegawai = [];
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datatabelpegawai = [];
                        this.datatabelpegawai = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>