import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MLokasi',
    name: 'MLokasi',
    component: () => import('../../components/views/Reff/Lokasi/Lokasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLokasi/:id',
    name: 'ProsesLokasi',
    props: true,
    component: () => import('../../components/views/Reff/Lokasi/ProsesLokasi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MGedung',
    name: 'MGedung',
    component: () => import('../../components/views/Reff/AreaGedung/AreaGedung.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesGedung/:id',
    name: 'ProsesGedung',
    props: true,
    component: () => import('../../components/views/Reff/AreaGedung/ProsesGedung.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MLantai',
    name: 'MLantai',
    component: () => import('../../components/views/Reff/Lantai/Lantai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLantai/:id',
    name: 'ProsesLantai',
    props: true,
    component: () => import('../../components/views/Reff/Lantai/ProsesLantai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MRuangan',
    name: 'MRuangan',
    component: () => import('../../components/views/Reff/Ruangan/Ruangan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesRuangan/:id',
    name: 'ProsesRuangan',
    props: true,
    component: () => import('../../components/views/Reff/Ruangan/ProsesRuangan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MSifatBarang',
    name: 'MSifatBarang',
    component: () => import('../../components/views/Reff/SifatBarang/SifatBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSifatBarang/:id',
    name: 'ProsesSifatBarang',
    props: true,
    component: () => import('../../components/views/Reff/SifatBarang/ProsesSifatBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MKondisiBarang',
    name: 'MKondisiBarang',
    component: () => import('../../components/views/Reff/KondisiBarang/KondisiBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKondisiBarang/:id',
    name: 'ProsesKondisiBarang',
    props: true,
    component: () => import('../../components/views/Reff/KondisiBarang/ProsesKondisiBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MFungsiBarang',
    name: 'MFungsiBarang',
    component: () => import('../../components/views/Reff/FungsiBarang/FungsiBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesFungsiBarang/:id',
    name: 'ProsesFungsiBarang',
    props: true,
    component: () => import('../../components/views/Reff/FungsiBarang/ProsesFungsiBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MKlasifikasiBarang',
    name: 'MKlasifikasiBarang',
    component: () => import('../../components/views/Reff/KlasifikasiBarang/KlasifikasiBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKlasifikasiBarang/:id',
    name: 'ProsesKlasifikasiBarang',
    props: true,
    component: () => import('../../components/views/Reff/KlasifikasiBarang/ProsesKlasifikasiBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MKategoriBarang',
    name: 'MKategoriBarang',
    component: () => import('../../components/views/Reff/KategoriBarang/KategoriBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKategoriBarang/:id',
    name: 'ProsesKategoriBarang',
    props: true,
    component: () => import('../../components/views/Reff/KategoriBarang/ProsesKategoriBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MJenisBarang',
    name: 'MJenisBarang',
    component: () => import('../../components/views/Reff/JenisBarang/JenisBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesJenisBarang/:id',
    name: 'ProsesJenisBarang',
    props: true,
    component: () => import('../../components/views/Reff/JenisBarang/ProsesJenisBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MKlasifikasiPenilaian',
    name: 'MKlasifikasiPenilaian',
    component: () => import('../../components/views/Reff/KlasifikasiPenilaian/KlasifikasiPenilaian.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesKlasifikasiPenilaian/:id',
    name: 'ProsesKlasifikasiPenilaian',
    props: true,
    component: () => import('../../components/views/Reff/KlasifikasiPenilaian/ProsesKlasifikasiPenilaian.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MMerkBarang',
    name: 'MMerkBarang',
    component: () => import('../../components/views/Reff/MerkBarang/MerkBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesMerkBarang/:id',
    name: 'ProsesMerkBarang',
    props: true,
    component: () => import('../../components/views/Reff/MerkBarang/ProsesMerkBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MTipeBarang',
    name: 'MTipeBarang',
    component: () => import('../../components/views/Reff/TipeBarang/TipeBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesTipeBarang/:id',
    name: 'ProsesTipeBarang',
    props: true,
    component: () => import('../../components/views/Reff/TipeBarang/ProsesTipeBarang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MJenisPerawatan',
    name: 'MJenisPerawatan',
    component: () => import('../../components/views/Reff/JenisPerawatan/JenisPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesJenisPerawatan/:id',
    name: 'ProsesJenisPerawatan',
    props: true,
    component: () => import('../../components/views/Reff/JenisPerawatan/ProsesJenisPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MDurasiPerawatan',
    name: 'MDurasiPerawatan',
    component: () => import('../../components/views/Reff/DurasiPerawatan/DurasiPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesDurasiPerawatan/:id',
    name: 'ProsesDurasiPerawatan',
    props: true,
    component: () => import('../../components/views/Reff/DurasiPerawatan/ProsesDurasiPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MSelesaiPerawatan',
    name: 'MSelesaiPerawatan',
    component: () => import('../../components/views/Reff/SelesaiPerawatan/SelesaiPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSelesaiPerawatan/:id',
    name: 'ProsesSelesaiPerawatan',
    props: true,
    component: () => import('../../components/views/Reff/SelesaiPerawatan/ProsesSelesaiPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MMetodePerawatan',
    name: 'MMetodePerawatan',
    component: () => import('../../components/views/Reff/MetodePerawatan/MetodePerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesMetodePerawatan/:id',
    name: 'ProsesMetodePerawatan',
    props: true,
    component: () => import('../../components/views/Reff/MetodePerawatan/ProsesMetodePerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MAlatPerawatan',
    name: 'MAlatPerawatan',
    component: () => import('../../components/views/Reff/AlatPerawatan/AlatPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesAlatPerawatan/:id',
    name: 'ProsesAlatPerawatan',
    props: true,
    component: () => import('../../components/views/Reff/AlatPerawatan/ProsesAlatPerawatan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MOrganisasiInstitusi',
    name: 'MOrganisasiInstitusi',
    component: () => import('../../components/views/Reff/OrganisasiInstitusi/OrganisasiInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesOrganisasiInstitusi/:id',
    name: 'ProsesOrganisasiInstitusi',
    props: true,
    component: () => import('../../components/views/Reff/OrganisasiInstitusi/ProsesOrganisasiInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MBidangInstitusi',
    name: 'MBidangInstitusi',
    component: () => import('../../components/views/Reff/BidangInstitusi/BidangInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesBidangInstitusi/:id',
    name: 'ProsesBidangInstitusi',
    props: true,
    component: () => import('../../components/views/Reff/BidangInstitusi/ProsesBidangInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MSubBidangInstitusi',
    name: 'MSubBidangInstitusi',
    component: () => import('../../components/views/Reff/SubBidang/SubBidangInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSubBidangInstitusi/:id',
    name: 'ProsesSubBidangInstitusi',
    props: true,
    component: () => import('../../components/views/Reff/SubBidang/ProsesSubBidangInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MJabatanInstitusi',
    name: 'MJabatanInstitusi',
    component: () => import('../../components/views/Reff/JabatanInstitusi/JabatanInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesJabatanInstitusi/:id',
    name: 'ProsesJabatanInstitusi',
    props: true,
    component: () => import('../../components/views/Reff/JabatanInstitusi/ProsesJabatanInstitusi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MJenisTenant',
    name: 'MJenisTenant',
    component: () => import('../../components/views/Reff/JenisTenant/JenisTenant.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesJenisTenant/:id',
    name: 'ProsesJenisTenant',
    props: true,
    component: () => import('../../components/views/Reff/JenisTenant/ProsesJenisTenant.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MDurasiTenant',
    name: 'MDurasiTenant',
    component: () => import('../../components/views/Reff/DurasiTenant/DurasiTenant.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesDurasiTenant/:id',
    name: 'ProsesDurasiTenant',
    props: true,
    component: () => import('../../components/views/Reff/DurasiTenant/ProsesDurasiTenant.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MPegawai',
    name: 'MPegawai',
    component: () => import('../../components/views/Pegawai/Pegawai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPegawai/:id',
    name: 'ProsesPegawai',
    props: true,
    component: () => import('../../components/views/Pegawai/ProsesPegawai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Aset',
    name: 'Aset',
    component: () => import('../../components/views/Aset/Aset.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MBentukBangunan',
    name: 'MBentukBangunan',
    component: () => import('../../components/views/Reff/BentukBangunan/BentukBangunan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MPoinPengurang',
    name: 'MPoinPengurang',
    component: () => import('../../components/views/Reff/PoinPengurang/PoinPengurang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesPoinPengurang/:id',
    name: 'ProsesPoinPengurang',
    props: true,
    component: () => import('../../components/views/Reff/PoinPengurang/ProsesPoinPengurang.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesBentukBangunan/:id',
    name: 'ProsesBentukBangunan',
    props: true,
    component: () => import('../../components/views/Reff/BentukBangunan/ProsesBentukBangunan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MStatusPenggunaan',
    name: 'MStatusPenggunaan',
    component: () => import('../../components/views/Reff/StatusPenggunaan/StatusPenggunaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesStatusPenggunaan/:id',
    name: 'ProsesStatusPenggunaan',
    props: true,
    component: () => import('../../components/views/Reff/StatusPenggunaan/ProsesStatusPenggunaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MJenisPenggunaan',
    name: 'MJenisPenggunaan',
    component: () => import('../../components/views/Reff/JenisPenggunaan/JenisPenggunaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesJenisPenggunaan/:id',
    name: 'ProsesJenisPenggunaan',
    props: true,
    component: () => import('../../components/views/Reff/JenisPenggunaan/ProsesJenisPenggunaan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MStrukturBangunan',
    name: 'MStrukturBangunan',
    component: () => import('../../components/views/Reff/StrukturBangunan/StrukturBangunan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesStrukturBangunan/:id',
    name: 'ProsesStrukturBangunan',
    props: true,
    component: () => import('../../components/views/Reff/StrukturBangunan/ProsesStrukturBangunan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MAirBersih',
    name: 'MAirBersih',
    component: () => import('../../components/views/Reff/AirBersih/AirBersih.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesAirBersih/:id',
    name: 'ProsesAirBersih',
    props: true,
    component: () => import('../../components/views/Reff/AirBersih/ProsesAirBersih.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MLimbah',
    name: 'MLimbah',
    component: () => import('../../components/views/Reff/Limbah/Limbah.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesLimbah/:id',
    name: 'ProsesLimbah',
    props: true,
    component: () => import('../../components/views/Reff/Limbah/ProsesLimbah.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DataGedung',
    name: 'DataGedung',
    component: () => import('../../components/views/DataGedung/DataGedung.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Tenant',
    name: 'Tenant',
    component: () => import('../../components/views/DataTenant/Tenant.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesTenant/:id',
    name: 'ProsesTenant',
    props: true,
    component: () => import('../../components/views/DataTenant/ProsesTenant.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DataLantai',
    name: 'DataLantai',
    component: () => import('../../components/views/DataLantai/DataLantai.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DataRuangan',
    name: 'DataRuangan',
    component: () => import('../../components/views/DataRuangan/DataRuangan.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/MJadwalPegawai',
    name: 'MJadwalPegawai',
    component: () => import('../../components/views/Jadwal/Jadwal.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Tugas',
    name: 'Tugas',
    component: () => import('../../components/views/Tugas/Tugas.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesTugas/:id',
    name: 'ProsesTugas',
    props: true,
    component: () => import('../../components/views/Tugas/ProsesTugas.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KinerjaAset',
    name: 'KinerjaAset',
    component: () => import('../../components/views/KinerjaAset/KinerjaAset.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DetailKinerjaAsetGedung/:id',
    name: 'DetailKinerjaAsetGedung',
    props: true,
    component: () => import('../../components/views/KinerjaAset/DetailKinerjaAsetGedung.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DetailKinerjaAsetGedungFisik/:id',
    name: 'DetailKinerjaAsetGedungFisik',
    props: true,
    component: () => import('../../components/views/KinerjaAset/DetailKinerjaAsetGedungFisik.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DetailKinerjaAsetGedungFungsi/:id',
    name: 'DetailKinerjaAsetGedungFungsi',
    props: true,
    component: () => import('../../components/views/KinerjaAset/DetailKinerjaAsetGedungFungsi.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KinerjaPersonil',
    name: 'KinerjaPersonil',
    component: () => import('../../components/views/KinerjaPersonil/KinerjaPersonil.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/DetailKinerjaPersonil/:id',
    name: 'DetailKinerjaPersonil',
    props: true,
    component: () => import('../../components/views/KinerjaPersonil/DetailKinerjaPersonil.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Peta',
    name: 'Peta',
    component: () => import('../../components/views/Peta/Peta.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/QRCode',
    name: 'QRCode',
    component: () => import('../../components/views/QRCode/QRCode.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
