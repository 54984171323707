<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Proses Data Klasifikasi Penilaian</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/MKlasifikasiPenilaian">Data Klasifikasi
                                        Penilaian</a></li>
                                <li class="breadcrumb-item active">Proses Data</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Formulir Input Klasifikasi Penilaian</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Nama Klasifikasi Kondisi <small class="text-info"> (*Nama Klasifikasi
                                                    Penilaian Kondisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama_aset"
                                                    name="nama_aset" placeholder="Nama Klasifikasi Aset"
                                                    v-model="nama_aset">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Klasifikasi Fungsi <small class="text-info"> (*Nama Klasifikasi
                                                    Penilaian Fungsi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="nama_fungsi"
                                                    name="nama_fungsi" placeholder="Nama Klasifikasi Aset"
                                                    v-model="nama_fungsi">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Klasifikasi Personil <small class="text-info"> (*Nama
                                                    Klasifikasi Penilaian Personil)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-book-reader"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm"
                                                    id="nama_personil" name="nama_personil"
                                                    placeholder="Nama Klasifikasi Personil" v-model="nama_personil">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nilai Ambang Bawah <small class="text-info"> (*Nilai Ambang
                                                    Bawah)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-chevron-down"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="skor_min"
                                                    name="skor_min" placeholder="Nilai Ambang Bawah"
                                                    data-inputmask='"mask": "99,99"' data-mask v-model="skor_min">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nilai Ambang Atas <small class="text-info"> (*Nilai Ambang
                                                    Atas)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fas fa-chevron-up"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="skor_max"
                                                    name="skor_max" placeholder="Nilai Ambang Atas"
                                                    data-inputmask='"mask": "99,99"' data-mask v-model="skor_max">
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Warna</label>
                                            <Vue3ColorPicker v-model="warna" mode="solid" :showColorList="false"
                                                :showEyeDrop="false" type="HEX" />
                                        </div>
                                    </div>
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    <button type="button" class="btn btn-sm btn-success float-left text-white lebar"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')"><i
                                            class="fas fa-plus-square"></i> Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success float-left text-white lebar"
                                        v-if="(countdata > 0)" @click="update()"><i class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-secondary float-left text-white lebar"
                                        @click="batalproses()"><i class="fas fa-window-close"></i> Batal</button>
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, swal, Vue3ColorPicker
    },
    data() {
        return {
            validasi: false,
            halamanloading: true,
            datamaster: [],
            datapesan: [],
            countdata: 0,
            nama_aset: '',
            nama_fungsi: '',
            nama_personil: '',
            skor_min: '00,00',
            skor_max: '00,00',
            warna: '#000000',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        }
    },
    methods: {
        initializeSelect2() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
        },
        AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPIDataSingel.get("mklnilai_oripro-GetbyIDKlasifikasiPenilaian?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.nama_aset = Response.data.content.data[0].nama_aset;
                    this.nama_fungsi = Response.data.content.data[0].nama_fungsi;
                    this.nama_personil = Response.data.content.data[0].nama_personil;
                    this.skor_min = this.G_numKomaTambahNol(Response.data.content.data[0].skor_min);
                    this.skor_max = this.G_numKomaTambahNol(Response.data.content.data[0].skor_max);
                    this.warna = Response.data.content.data[0].warna;
                    this.countdata = Response.data.countdata;
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.halamanloading = false;
            })
        },
        validasidata() {
            if (document.getElementById('nama_aset').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama_aset').focus();
                return false;
            }
            if (document.getElementById('nama_fungsi').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama_fungsi').focus();
                return false;
            }
            if (document.getElementById('nama_personil').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama_personil').focus();
                return false;
            }

            this.validasi = true;
            return;
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var fd = new FormData();
            fd.append("nama_aset", this.nama_aset);
            fd.append("nama_fungsi", this.nama_fungsi);
            fd.append("nama_personil", this.nama_personil);
            fd.append("skor_min", document.getElementById("skor_min").value.replaceAll(",", "."));
            fd.append("skor_max", document.getElementById("skor_max").value.replaceAll(",", "."));
            fd.append("warna", this.warna);
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);

            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("mklnilai_oripro-UpdateKlasifikasiPenilaian", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/MKlasifikasiPenilaian");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
        simpan() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var fd = new FormData();
            fd.append("nama_aset", this.nama_aset);
            fd.append("nama_fungsi", this.nama_fungsi);
            fd.append("nama_personil", this.nama_personil);
            fd.append("skor_min", document.getElementById("skor_min").value.replaceAll(",", "."));
            fd.append("skor_max", document.getElementById("skor_max").value.replaceAll(",", "."));
            fd.append("warna", this.warna);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("mklnilai_oripro-AddKlasifikasiPenilaian", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            window.location.replace("/MKlasifikasiPenilaian");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        batalproses() {
            window.location.replace("/MKlasifikasiPenilaian");
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
    },
    mounted() {
        this.initializeSelect2();
        this.getOtentifikasi();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>