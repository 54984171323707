<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar elevation-4 sidebar-dark-navy">
        <a href="#" class="brand-link" style="align-items: center;" data-widget="pushmenu" role="button">
            <center>
                <i class="fas fa-align-right text-muted"></i>&nbsp;
            </center>
        </a>
        <!-- Sidebar -->
        <div class="sidebar">
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="col-sm-12 text-center">
                    Hello, Welcome {{ namauser }}
                </div>
            </div>
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column nav-flat" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item" v-if="(leveluser > 5)">
                        <router-link to="/Tugas"
                            :class="[((route.name == 'Tugas' || route.name == 'ProsesTugas') ? 'nav-link active' : 'nav-link')]"
                            id="Tugas"><i class="nav-icon far fa-clipboard"></i>
                            <p>PENUGASAN PERSONIL</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="(leveluser > 5)">
                        <a href="#" class="nav-link" @click="keluar()">
                            <i class="nav-icon fas fa-sign-out-alt"></i>
                            <p>KELUAR / LOGOUT</p>
                        </a>
                    </li>
                    <li class="nav-header text-success" v-if="(leveluser <= 5)"><i
                            class="nav-icon fab fa-buromobelexperte"></i> INFOGRAFIS</li>
                    <li class="nav-item" v-if="(leveluser <= 5)">
                        <router-link to="/Dashboard"
                            :class="[(route.name == 'Dashboard' ? 'nav-link active' : 'nav-link')]" id="Dashboard"><i
                                class="nav-icon fas fa-chart-line"></i>
                            <p>DASHBOARD</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="(leveluser <= 5)">
                        <router-link to="/Peta" :class="[(route.name == 'Peta' ? 'nav-link active' : 'nav-link')]"
                            id="Peta"><i class="nav-icon fas fa-globe-asia"></i>
                            <p>PETA ASET</p>
                        </router-link>
                    </li>
                    <li class="nav-header text-success" v-if="(leveluser <= 5)"><i
                            class="nav-icon fab fa-buromobelexperte"></i> KINERJA</li>
                    <li class="nav-item" v-if="(leveluser <= 5)">
                        <router-link to="/KinerjaAset" :class="[((route.name == 'KinerjaAset' || route.name == 'DetailKinerjaAsetGedung'
                            || route.name == 'DetailKinerjaAsetGedungFisik' || route.name == 'DetailKinerjaAsetGedungFungsi'
                        ) ? 'nav-link active' : 'nav-link')]" id="KinerjaAset"><i
                                class="nav-icon fas fa-box-open"></i>
                            <p>KINERJA ASET</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="(leveluser <= 5)">
                        <router-link to="/KinerjaPersonil"
                            :class="[((route.name == 'KinerjaPersonil' || route.name == 'DetailKinerjaPersonil') ? 'nav-link active' : 'nav-link')]"
                            id="KinerjaPersonil"><i class="nav-icon fas fa-users-cog"></i>
                            <p>KINERJA PERSONIL</p>
                        </router-link>
                    </li>
                    <li class="nav-header text-success"
                        v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))"><i
                            class="nav-icon fab fa-buromobelexperte"></i> PENGATURAN PERSONIL</li>
                    <li class="nav-item" v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))">
                        <router-link to="/MPegawai"
                            :class="[((route.name == 'MPegawai' || route.name == 'ProsesPegawai') ? 'nav-link active' : 'nav-link')]"
                            id="MPegawai"><i class="nav-icon fas fa-user-friends"></i>
                            <p>DATA PERSONIL</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))">
                        <router-link to="/MJadwalPegawai"
                            :class="[((route.name == 'MJadwalPegawai' || route.name == 'ProsesJadwalPegawai') ? 'nav-link active' : 'nav-link')]"
                            id="MJadwalPegawai"><i class="nav-icon fas far fa-calendar-alt"></i>
                            <p>JADWAL PERSONIL</p>
                        </router-link>
                    </li>
                    <li class="nav-header text-success"
                        v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))"><i
                            class="nav-icon fab fa-buromobelexperte"></i> PENGATURAN ASET</li>
                    <li class="nav-item" v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))">
                        <router-link to="/Aset"
                            :class="[((route.name == 'Aset' || route.name == 'ProsesAset') ? 'nav-link active' : 'nav-link')]"
                            id="Aset"><i class="nav-icon fas fa-map-marked"></i>
                            <p>DATA PETA LOKASI ASET</p>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))">
                        <router-link to="/DataGedung"
                            :class="[((route.name == 'DataGedung' || route.name == 'ProsesDataGedung') ? 'nav-link active' : 'nav-link')]"
                            id="DataGedung"><i class="nav-icon far fa-hospital"></i>
                            <p>DATA GEDUNG / AREA</p>
                        </router-link>
                        <router-link to="/DataLantai"
                            :class="[((route.name == 'DataLantai' || route.name == 'ProsesDataLantai') ? 'nav-link active' : 'nav-link')]"
                            id="DataLantai"><i class="nav-icon far fa-object-group"></i>
                            <p>DATA LANTAI</p>
                        </router-link>
                        <router-link to="/DataRuangan"
                            :class="[((route.name == 'DataRuangan' || route.name == 'ProsesDataRuangan') ? 'nav-link active' : 'nav-link')]"
                            id="DataRuangan"><i class="nav-icon fas fa-boxes"></i>
                            <p>DATA RUANGAN & BARANG</p>
                        </router-link>
                        <router-link to="/QRCode"
                            :class="[((route.name == 'QRCode' || route.name == 'ProsesQRCode') ? 'nav-link active' : 'nav-link')]"
                            id="QRCode"><i class="nav-icon fas fa-qrcode"></i>
                            <p>QR CODE ASET BARANG</p>
                        </router-link>
                    </li>
                    <li class="nav-header text-success"
                        v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))"><i
                            class="nav-icon fab fa-buromobelexperte"></i> PENGATURAN TENANT</li>
                    <li class="nav-item" v-if="((leveluser <= 5) && (leveluser >= 4) || (leveluser <= 2))">
                        <router-link to="/Tenant"
                            :class="[((route.name == 'Tenant' || route.name == 'ProsesTenant') ? 'nav-link active' : 'nav-link')]"
                            id="Tenant"><i class="nav-icon fas fa-house-user"></i>
                            <p>DATA TENANT</p>
                        </router-link>
                    </li>
                    <li class="nav-header text-success" v-if="(leveluser <= 2)"><i
                            class="nav-icon fab fa-buromobelexperte"></i> REFERENSI SISTEM</li>
                    <li :class="[((route.name == 'MOrganisasiInstitusi' || route.name == 'ProsesOrganisasiInstitusi'
                        || route.name == 'MBidangInstitusi' || route.name == 'ProsesBidangInstitusi'
                        || route.name == 'MSubBidangInstitusi' || route.name == 'ProsesSubBidangInstitusi'
                        || route.name == 'MJabatanInstitusi' || route.name == 'ProsesJabatanInstitusi'
                    ) ? 'nav-item menu-open' : 'nav-item')]" id="DivisiGrup" v-if="(leveluser <= 2)">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-city"></i>
                            <p>
                                REFERENSI INSTITUSIONAL
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/MOrganisasiInstitusi"
                                    :class="[((route.name == 'MOrganisasiInstitusi' || route.name == 'ProsesOrganisasiInstitusi') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Organisasi / OPD</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MBidangInstitusi"
                                    :class="[((route.name == 'MBidangInstitusi' || route.name == 'ProsesBidangInstitusi') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Divisi / Bidang</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MSubBidangInstitusi"
                                    :class="[((route.name == 'MSubBidangInstitusi' || route.name == 'ProsesSubBidangInstitusi') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Unit / SubBidang</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MJabatanInstitusi"
                                    :class="[((route.name == 'MJabatanInstitusi' || route.name == 'ProsesJabatanInstitusi') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Jabatan</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li :class="[((route.name == 'MJenisTenant' || route.name == 'ProsesJenisTenant'
                        || route.name == 'MDurasiTenant' || route.name == 'ProsesDurasiTenant'
                    ) ? 'nav-item menu-open' : 'nav-item')]" id="TenantGrup" v-if="(leveluser <= 2)">
                        <a href="#" class="nav-link">
                            <i class="nav-icon far fa-handshake"></i>
                            <p>
                                REFERENSI TENANT
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/MJenisTenant"
                                    :class="[((route.name == 'MJenisTenant' || route.name == 'ProsesJenisTenant') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Jenis Tenant</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MDurasiTenant"
                                    :class="[((route.name == 'MDurasiTenant' || route.name == 'ProsesDurasiTenant') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Durasi Tenant</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li :class="[((route.name == 'MLokasi' || route.name == 'ProsesLokasi'
                        || route.name == 'MGedung' || route.name == 'ProsesGedung'
                        || route.name == 'MLantai' || route.name == 'ProsesLantai'
                        || route.name == 'MRuangan' || route.name == 'ProsesRuangan') ? 'nav-item menu-open' : 'nav-item')]"
                        id="LokasiGrup" v-if="(leveluser <= 2)">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-clipboard-check"></i>
                            <p>
                                REFERENSI LOKASI
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/MLokasi"
                                    :class="[((route.name == 'MLokasi' || route.name == 'ProsesLokasi') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Lokasi Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MGedung"
                                    :class="[((route.name == 'MGedung' || route.name == 'ProsesGedung') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Gedung / Area Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/Mlantai"
                                    :class="[((route.name == 'MLantai' || route.name == 'ProsesLantai') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Lantai Gedung</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MRuangan"
                                    :class="[((route.name == 'MRuangan' || route.name == 'ProsesRuangan') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Ruangan Gedung</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li :class="[((route.name == 'MSifatBarang' || route.name == 'ProsesSifatBarang'
                        || route.name == 'MKondisiBarang' || route.name == 'ProsesKondisiBarang'
                        || route.name == 'MFungsiBarang' || route.name == 'ProsesFungsiBarang'
                        || route.name == 'MKlasifikasiBarang' || route.name == 'ProsesKlasifikasiBarang'
                        || route.name == 'MKategoriBarang' || route.name == 'ProsesKategoriBarang'
                        || route.name == 'MJenisBarang' || route.name == 'ProsesJenisBarang'
                        || route.name == 'MMerkBarang' || route.name == 'ProsesMerkBarang'
                        || route.name == 'MTipeBarang' || route.name == 'ProsesTipeBarang'
                    ) ? 'nav-item menu-open' : 'nav-item')]" id="BarangGrup" v-if="(leveluser <= 2)">
                        <a href="#" class="nav-link">
                            <i class="nav-icon far fa-hdd"></i>
                            <p>
                                REFERENSI ASET
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/MSifatBarang"
                                    :class="[((route.name == 'MSifatBarang' || route.name == 'ProsesSifatBarang') ? 'nav-link active' : 'nav-link')]"><i
                                        class="nav-icon far fa-circle"></i>
                                    <p>Sifat Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MKondisiBarang"
                                    :class="[((route.name == 'MKondisiBarang' || route.name == 'ProsesKondisiBarang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Kondisi Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MFungsiBarang"
                                    :class="[((route.name == 'MFungsiBarang' || route.name == 'ProsesFungsiBarang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Fungsi Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MKlasifikasiBarang"
                                    :class="[((route.name == 'MKlasifikasiBarang' || route.name == 'ProsesKlasifikasiBarang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Klasifikasi Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MKategoriBarang"
                                    :class="[((route.name == 'MKategoriBarang' || route.name == 'ProsesKategoriBarang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Kategori Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MJenisBarang"
                                    :class="[((route.name == 'MJenisBarang' || route.name == 'ProsesJenisBarang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Jenis Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MMerkBarang"
                                    :class="[((route.name == 'MMerkBarang' || route.name == 'ProsesMerkBarang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Merk Aset</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MTipeBarang"
                                    :class="[((route.name == 'MTipeBarang' || route.name == 'ProsesTipeBarang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Tipe Aset</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li :class="[((route.name == 'MJenisPerawatan' || route.name == 'ProsesJenisPerawatan'
                        || route.name == 'MDurasiPerawatan' || route.name == 'ProsesDurasiPerawatan'
                        || route.name == 'MMetodePerawatan' || route.name == 'ProsesMetodePerawatan'
                        || route.name == 'MAlatPerawatan' || route.name == 'ProsesAlatPerawatan'
                        || route.name == 'MSelesaiPerawatan' || route.name == 'ProsesSelesaiPerawatan'
                    ) ? 'nav-item menu-open' : 'nav-item')]" id="PerawatanGrup" v-if="(leveluser <= 2)">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-mitten"></i>
                            <p>
                                REFERENSI PERAWATAN
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/MJenisPerawatan"
                                    :class="[((route.name == 'MJenisPerawatan' || route.name == 'ProsesJenisPerawatan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Jenis Perawatan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MDurasiPerawatan"
                                    :class="[((route.name == 'MDurasiPerawatan' || route.name == 'ProsesDurasiPerawatan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Durasi Perawatan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MMetodePerawatan"
                                    :class="[((route.name == 'MMetodePerawatan' || route.name == 'ProsesMetodePerawatan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Metode Perawatan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MAlatPerawatan"
                                    :class="[((route.name == 'MAlatPerawatan' || route.name == 'ProsesAlatPerawatan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Alat Perawatan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MSelesaiPerawatan"
                                    :class="[((route.name == 'MSelesaiPerawatan' || route.name == 'ProsesSelesaiPerawatan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Selesai Perawatan</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li :class="[((route.name == 'MBentukBangunan' || route.name == 'ProsesBentukBangunan'
                        || route.name == 'MStatusPenggunaan' || route.name == 'ProsesStatusPenggunaan'
                        || route.name == 'MJenisPenggunaan' || route.name == 'ProsesJenisPenggunaan'
                        || route.name == 'MStrukturBangunan' || route.name == 'ProsesStrukturBangunan'
                        || route.name == 'MAirBersih' || route.name == 'ProsesAirBersih'
                        || route.name == 'MLimbah' || route.name == 'ProsesLimbah'
                    ) ? 'nav-item menu-open' : 'nav-item')]" id="SipilGrup" v-if="(leveluser <= 2)">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-hammer"></i>
                            <p>
                                REFERENSI INFRAST. SIPIL
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/MBentukBangunan"
                                    :class="[((route.name == 'MBentukBangunan' || route.name == 'ProsesBentukBangunan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Bentuk Bangunan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MStatusPenggunaan"
                                    :class="[((route.name == 'MStatusPenggunaan' || route.name == 'ProsesStatusPenggunaan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Status Penggunaan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MJenisPenggunaan"
                                    :class="[((route.name == 'MJenisPenggunaan' || route.name == 'ProsesJenisPenggunaan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Jenis Status Penggunaan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MStrukturBangunan"
                                    :class="[((route.name == 'MStrukturBangunan' || route.name == 'ProsesStrukturBangunan') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Struktur Bangunan</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MAirBersih"
                                    :class="[((route.name == 'MAirBersih' || route.name == 'ProsesAirBersih') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Sumber Air Bersih</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MLimbah"
                                    :class="[((route.name == 'MLimbah' || route.name == 'ProsesLimbah') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Limbah</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li :class="[((route.name == 'MKlasifikasiPenilaian' || route.name == 'ProsesKlasifikasiPenilaian'
                        || route.name == 'MPoinPengurang' || route.name == 'ProsesPoinPengurang'
                    ) ? 'nav-item menu-open' : 'nav-item')]" id="SipilGrup" v-if="(leveluser <= 2)">
                        <a href="#" class="nav-link">
                            <i class="nav-icon fas fa-balance-scale"></i>
                            <p>
                                REFERENSI PENILAIAN
                                <i class="right fas fa-angle-left"></i>
                            </p>
                        </a>
                        <ul class="nav nav-treeview">
                            <li class="nav-item">
                                <router-link to="/MKlasifikasiPenilaian"
                                    :class="[((route.name == 'MKlasifikasiPenilaian' || route.name == 'ProsesKlasifikasiPenilaian') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Klasifikasi Penilaian</p>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/MPoinPengurang"
                                    :class="[((route.name == 'MPoinPengurang' || route.name == 'ProsesPoinPengurang') ? 'nav-link active' : 'nav-link')]"
                                    class="nav-link"><i class="nav-icon far fa-circle"></i>
                                    <p>Poin Pengurang</p>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                    <li>&nbsp;</li>
                    <li>
                        <div class="col-sm-12 text-center text-primary">
                            <i class="far fa-copyright"></i>{{ tahun }}&nbsp;&nbsp;<img
                                src="../../../assets/img/oriprorev.png" alt="Admin Logo" class="animation__shake"
                                style="opacity: .8;height: 14px;">
                        </div>
                    </li>
                    <li>&nbsp;</li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<script>
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
export default {
    components: {
        swal
    },
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    data() {
        return {
            tahun: new Date().getFullYear(),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
        };
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                localStorage.removeItem('tokensistem');
                localStorage.removeItem('usistem');
                localStorage.removeItem('lsistem');
                localStorage.removeItem('nmlsistem');
                localStorage.removeItem('emlsistem');
                localStorage.removeItem('nmusistem');
                localStorage.removeItem('tsistem');
                localStorage.removeItem('orsistem');
                localStorage.removeItem('bdlsistem');
                localStorage.removeItem('sbdsistem');
                localStorage.removeItem('jbtsistem');
            }
        },
        keluar() {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Keluar Aplikasi?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#33bff2",
                cancelButtonColor: "#6566aa",
                confirmButtonText: "Keluar"
            }).then((result) => {
                if (result.isConfirmed) {
                    this.ClearlocalStorage();
                }
            });

            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        if ((localStorage.getItem("tokensistem") == null)) {
            window.location.replace("/");
        }
    }
}
</script>

<style></style>