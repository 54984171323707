<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="modal fade" id="costumModaldetailaset" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: 0;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Delegasikan Tugas</h3>
                        <input type="hidden" class="form-control form-control-sm" id="kdjadwal" name="kdjadwal"
                            placeholder="Kode Lokasi" readonly>
                    </div>
                    <div class="modal-body">
                        <p>
                        <div class="col-sm-12">
                            <label>Pengganti<small class="text-info"> (*Pilih Data)</small></label>
                            <v-select :options="petugas" :reduce="(label) => label.code" label="label"
                                v-model="defaultSelectedpetugas" @update:modelValue="onpetugasChange"></v-select>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-primary btn-sm lebar" @click="delegasikan()"><i
                                class="fas fa-edit"></i>
                            Delegasikan</button>
                        <button type="button" class="btn btn-info btn-sm lebar" data-dismiss="modal"> <i
                                class="far fa-times-circle"></i>
                            Batal</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h5 class="text-primary">Penugasan Tanggal {{ G_formatDateNonWIB(tanggalsekarang) }}</h5>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Tugas">Data Penugasan </a></li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row p-3">
                        <div class="col-md-12">
                            <div class="card cardhijau card-outline" v-if="(datapesan != 'data kosong')">
                                <div class="card-header bg-primary" style="border-radius: 0px;">
                                    <h3 class="card-title">Todo list</h3><br>&nbsp;

                                    <div class="card-tools">
                                        <div class="input-group input-group-sm">
                                            <input type="text" class="form-control" placeholder="Pencarian Tugas"
                                                v-model="carikata">
                                            <div class="input-group-append">
                                                <div class="btn btn-primary">
                                                    <i class="fas fa-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /.card-tools -->
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-2">
                                    <div class="mailbox-controls">
                                        <!-- Check all button -->
                                        <button type="button" class="btn btn-success btn-sm checkbox-toggle"
                                            @click="pilih()"><i class="far fa-square"></i>
                                        </button>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger btn-sm" @click="foward()">
                                                <i class="fas fa-share"></i>
                                            </button>
                                        </div>
                                        <!-- /.btn-group -->
                                        <button type="button" class="btn btn-primary btn-sm" @click="refresh()">
                                            <i class="fas fa-sync-alt"></i>
                                        </button>
                                    </div>
                                    <div class="table-responsive mailbox-messages">
                                        <table class="table table-hover">
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td style="width: 30px;" class="text-center">
                                                            <div class="icheck-info">
                                                                <input type="checkbox" :value="datalist.id"
                                                                    :id="'check' + (datalist.id)">
                                                                <label :for="'check' + (datalist.id)"></label>
                                                            </div>
                                                        </td>
                                                        <td class="mailbox-name" style="width: 70vw;">
                                                            <a href="#" @click="prosesdata(datalist.id)">
                                                                <span class="badge badge-warning">
                                                                    {{ datalist.jam_mulai }} s/d {{
                                                                        datalist.jam_selesai }}
                                                                </span>
                                                                {{ datalist.nmmetode_perawatan }}
                                                                {{ datalist.nmjenis_perawatan }}
                                                                {{ datalist.nmdurasi_perawatan }}
                                                                {{ datalist.nmjenis_aset }}
                                                                {{ datalist.nmruangan }}
                                                                {{ datalist.nmlantai }}
                                                                {{ datalist.nmgedung }}
                                                                {{ datalist.nmlokasi }}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <!-- /.table -->
                                    </div>
                                    <!-- /.mail-box-messages -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer p-2">
                                    <div class="mailbox-controls">
                                        <!-- Check all button -->
                                        <button type="button" class="btn btn-success btn-sm checkbox-toggle"
                                            @click="pilih()">
                                            <i class="far fa-square"></i>
                                        </button>
                                        <div class="btn-group">
                                            <button type="button" class="btn btn-danger btn-sm">
                                                <i class="fas fa-share"></i>
                                            </button>
                                        </div>
                                        <!-- /.btn-group -->
                                        <button type="button" class="btn btn-primary btn-sm" @click="refresh()">
                                            <i class="fas fa-sync-alt"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- /.card -->
                            <div class="col-md-12 text-center text-primary" v-if="(datapesan == 'data kosong')">
                                <img src="../../../assets/img/1048966.png" style="height: 200px;"><br>
                                Tidak Ada Jadwal Penugasan Saat Ini
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            tanggalsekarang: new Date(),
            iduser: JSON.parse(localStorage.getItem("usistem")),
            lokasiluser: JSON.parse(localStorage.getItem("loksistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
            datapesan: [],
            datajadwal: [],
            carikata: '',
            pilihan: false,

            petugas: [],
            datapetugas: [],
            defaultSelectedpetugas: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedpetugas: 0,
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datajadwal
                : this.datajadwal.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        delegasikan() {
            if (this.selectedpetugas === undefined) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Personil Pengganti...'
                });
                $('.swal2-container').css("z-index", '999999');
                return false;
            }
            if (this.selectedpetugas == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Silahkan Pilih Personil Pengganti...'
                });
                $('.swal2-container').css("z-index", '999999');
                return false;
            }

            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Ubah Penugasan?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#33bff2",
                cancelButtonColor: "#6566aa",
                confirmButtonText: "Ubah"
            }).then((result) => {
                if (result.isConfirmed) {

                    var fd = new FormData();
                    fd.append("id", document.getElementById('kdjadwal').value);
                    fd.append("pj_aset", this.selectedpetugas);
                    fd.append("status_pengganti", "1");
                    fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));

                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            "Content-Type": "form-data",
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    mainAPI.post("datajadwal_oripro-UbahDataJadwalPJPetugas", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                    window.location.replace("/Tugas");
                                });
                            }
                            $('.swal2-container').css("z-index", '999999');
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                        $('.swal2-container').css("z-index", '999999');
                    }
                    )
                }
            });
            $('.swal2-container').css("z-index", '999999');
        },
        onpetugasChange(a) {
            try {
                this.selectedpetugas = this.petugas[a].tag;
            }
            catch (err) {
                return;
            }
        },
        pilih() {
            if (this.pilihan == false) {
                $('.mailbox-messages input[type=\'checkbox\']').prop('checked', true);
                $('.checkbox-toggle .far.fa-square').removeClass('fa-square').addClass('fa-check-square');
                this.pilihan = true;
            }
            else {
                $('.mailbox-messages input[type=\'checkbox\']').prop('checked', false);
                $('.checkbox-toggle .far.fa-check-square').removeClass('fa-check-square').addClass('fa-square');
                this.pilihan = false;
            }
        },
        foward() {
            var datafoward = [];
            $('.mailbox-messages input[type=\'checkbox\']').each(function () {
                if (this.checked) {
                    var idnya = this.id.toString().replaceAll("check", "");
                    datafoward.push(idnya);
                }
            });
            if (datafoward.length == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Tidak ada data terpilih'
                });
                $('.swal2-container').css("z-index", '999999');
                return false;
            }
            document.getElementById('kdjadwal').value = datafoward;
            const mainAPI11 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI11.get("m_peg_oripro-GetPegawaiNonSuperAdmin?kdorganisasi=" + this.kdeorganisasi
                + "&kdbidang=" + this.kdebidang + "&kdsubbidang=" + this.kdesubbidang + "&kdlevellebihrendah=" + this.leveluser
                + "&kdlokasi=" + this.lokasiluser + "&md5id=" + this.iduser + "&random=" + random).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        if (this.datapesan == 'data diketemukan') {
                            this.datapetugas = [];
                            this.petugas = [];
                            this.datapetugas = Response.data.content.data;
                            this.datapetugas.forEach((item) => {
                                this.petugas.push({
                                    label: item.nama,
                                    code: this.datapetugas.indexOf(item) + 1,
                                    tag: item.id,
                                });
                            });
                            this.petugas.unshift({
                                label: 'Pilih Data',
                                code: 0,
                                tag: 0,
                            });
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.ClearlocalStorage();
                    this.halamanloading = false;
                });

            $('#costumModaldetailaset').modal('show');
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/ProsesTugas/" + bytes);
        },
        async ambildata() {
            this.halamanloading = true;
            var d = this.tanggalsekarang;
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            var tanggal = [year, month, day].join('-');
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("datajadwal_oripro-MD5GetDataJadwal?kdpegawai=" + this.iduser + "&tanggal=" + tanggal + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajadwal = [];
                        this.datajadwal = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        refresh() {
            window.location.replace("/Tugas");
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>