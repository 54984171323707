<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Kinerja Pegawai</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Infografis Pegawai</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12" :style="[(leveluser >= 4 ? 'display:none' : 'display:block')]">
                            <div class="card cardinfo collapsed-card">
                                <div class="cardinfo card-header border-transparent">
                                    Filter Data
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i
                                                class="fas fa-plus text-white"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <label>Divisi / Bidang</label>
                                            <v-select :options="bidang" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedBidang"
                                                @update:modelValue="onbidangChange"></v-select>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Unit / SubBidang</label>
                                            <v-select :options="subbidang" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedSubBidang"
                                                @update:modelValue="onsubbidangChange"></v-select>
                                        </div>
                                        <div class="col-sm-4">
                                            <label>Lokasi</label>
                                            <v-select :options="lokasi" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedLokasi"
                                                @update:modelValue="onlokasiChange"></v-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card cardhijau">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-11 float-left pb-2">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 80px;">Foto</th>
                                                    <th style="width: 250px;">NIK /<br>Nama<br>Jabatan</th>
                                                    <th style="width: 200px;">Divisi /<br>Unit</th>
                                                    <th style="width: 130px;">Tanggal<br>Mulai Gabung /<br>Mulai
                                                        Kontrak /<br>Selesai Kontrak</th>
                                                    <th style="width: 150px;">Level /<br>Akses Lokasi</th>
                                                    <th style="width: 150px;">Skoring</th>
                                                    <th style="width: 130px;">Opsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td class="text-center">
                                                            <img :src="folder_foto + datalist.foto"
                                                                class="img-size-75 img-circle animation__wobble"
                                                                alt="User Image" style="height: 100px;">
                                                        </td>
                                                        <td>
                                                            {{ datalist.nip }}<br>
                                                            <span class="text-primary">{{ datalist.nama }}</span><br>
                                                            <span class="text-info">{{ datalist.namajabatan }}</span>
                                                        </td>
                                                        <td>
                                                            <span class="text-primary">{{ datalist.namabidang
                                                                }}</span><br>
                                                            {{ datalist.namasubbidang }}
                                                        </td>
                                                        <td class="text-center">
                                                            <span class="badge badge-mulai">{{
                                                                G_formatDate_Tanpa_WIB(datalist.tgl_gabung)
                                                            }}</span><br>
                                                            <span class="badge badge-info">{{
                                                                G_formatDate_Tanpa_WIB(datalist.tgl_kontrak_mulai)
                                                            }}</span><br>
                                                            <span
                                                                :class="[(Date.parse(datalist.tgl_kontrak_selesai) >= tanggalsetahundarisekarang ? 'badge badge-success'
                                                                    : Date.parse(datalist.tgl_kontrak_selesai) >= tanggaltigabulandarisekarang ? 'badge badge-warning' : 'badge badge-danger')]">
                                                                {{ G_formatDate_Tanpa_WIB(datalist.tgl_kontrak_selesai)
                                                                }}
                                                            </span>

                                                        </td>
                                                        <td>
                                                            <span class="text-info">{{ datalist.namalevel
                                                                }}</span><br>
                                                            {{ datalist.namalokasi }}
                                                        </td>
                                                        <td class="text-center">
                                                            <span :style="'color:' + datalist.vervalwarnanilai">{{
                                                                G_numKoma(datalist.vervalskorakhir) }}</span><br>
                                                            <span :style="'color:' + datalist.vervalwarnanilai">{{
                                                                datalist.vervalnamaskorakhir }}</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <button type="button"
                                                                class="btn btn-info btn-sm lebar text-white"
                                                                @click="prosesdata(datalist.id)"><i
                                                                    class="fas fa-edit"></i> Lihat Detail</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div class="col-md-12 text-center" v-if="(datapesanmaster == 'data kosong')">
                                            Data
                                            Masih Kosong</div>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    Jumlah Data: {{ G_numFormat(JumlahfilteredKataData) }}
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import CryptoJS from 'crypto-js';
import { inject } from 'vue';
import swal from 'sweetalert2';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            carikata: '',
            datamaster: [],
            datapesan: [],
            datapesanmaster: [],
            folder_foto: process.env.VUE_APP_URL_API + 'view_oripro-GambarUser?f=',
            tanggalsetahundarisekarang: Date.parse(new Date()) + (365 * 24 * 60 * 60 * 1000),
            tanggaltigabulandarisekarang: Date.parse(new Date()) + (90 * 24 * 60 * 60 * 1000),

            bidang: [],
            databidang: [],
            defaultSelectedBidang: {
                code: 0,
                label: 'Semua Data',
            },
            selectedbidang: 0,
            subbidang: [],
            datasubbidang: [],
            defaultSelectedSubBidang: {
                code: 0,
                label: 'Semua Data',
            },
            selectedsubbidang: 0,
            kdorganisasi: JSON.parse(localStorage.getItem("orsistem")),

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        async AmbilDataBidangTerpilih() {
            var kdorganisasi = this.kdorganisasi;
            if (kdorganisasi == 0) {
                kdorganisasi = '';
            }
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("morg_oripro-GetBidangInstitusi?kdorganisasi=" + kdorganisasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databidang = [];
                        this.bidang = [];
                        this.databidang = Response.data.content.data;
                        this.databidang.forEach((item) => {
                            this.bidang.push({
                                label: item.nama,
                                code: this.databidang.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.bidang.unshift({
                            label: 'Semua Data',
                            code: '0',
                        });
                    }
                    this.AmbilDataSubBidangTerpilih('');
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
            this.halamanloading = true;
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLokasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lokasi.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        onbidangChange(a) {
            try {
                this.selectedbidang = this.bidang[a].tag;
                this.AmbilDataSubBidangTerpilih(this.selectedbidang);
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        async AmbilDataSubBidangTerpilih(kdbidang) {

            var kdorganisasi = this.kdorganisasi;
            if (kdorganisasi == 0) {
                kdorganisasi = '';
            }

            if (kdbidang === undefined) {
                var kodebidang = '';
            }
            else if (kdbidang == 0) {
                var kodebidang = '';
            }
            else {
                var kodebidang = kdbidang;
            }
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("morg_oripro-GetSubBidangInstitusi?kdorganisasi=" + kdorganisasi + "&kdbidang=" + kodebidang + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasubbidang = [];
                        this.subbidang = [];
                        this.datasubbidang = Response.data.content.data;
                        this.datasubbidang.forEach((item) => {
                            this.subbidang.push({
                                label: item.nama,
                                code: this.datasubbidang.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.subbidang.unshift({
                            label: 'Semua Data',
                            code: '0',
                        });

                        this.selectedsubbidang = 0;
                        this.defaultSelectedSubBidang = {
                            code: 0,
                            label: 'Semua Data',
                        };
                        this.AmbilData();
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        onsubbidangChange(a) {
            try {
                this.selectedsubbidang = this.subbidang[a].tag;
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        KosongkanSubBidang() {
            this.datasubbidang = [];
            this.subbidang = [];
            this.defaultSelectedSubBidang = {
                code: 0,
                label: 'Semua Data',
            };
            this.selectedsubbidang = 0;
        },
        async AmbilData() {
            this.halamanloading = true;
            this.datamaster = [];
            var kodebidang = '';
            var kodesubbidang = '';
            if ((this.selectedbidang != 0) && (this.selectedbidang != undefined)) {
                kodebidang = this.selectedbidang;
            }
            if ((this.selectedsubbidang != 0) && (this.selectedsubbidang != undefined)) {
                kodesubbidang = this.selectedsubbidang;
            }
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("kinpeg_oripro-GetKinerjaPegawai?kdorganisasi=" + this.kdorganisasi
                + "&kdbidang=" + kodebidang + "&kdsubbidang=" + kodesubbidang
                + "&kdlokasi=" + kodelokasi + "&kdlevellebihrendah=" + this.leveluser + "&random=" + random).then(
                    Response => {
                        this.datapesanmaster = Response.data.message;
                        if (this.datapesanmaster == 'data diketemukan') {
                            this.datamaster = [];
                            this.datamaster = Response.data.content.data;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.ClearlocalStorage();
                    this.halamanloading = false;
                    //window.location.replace("/");
                })
        },
        prosesdata(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/DetailKinerjaPersonil/" + bytes);
        },
    },
    mounted() {
        this.selectedbidang = JSON.parse(localStorage.getItem("bdlsistem"));
        this.selectedsubbidang = JSON.parse(localStorage.getItem("sbdsistem"));
        this.getOtentifikasi();
        this.AmbilDataBidangTerpilih();
        this.AmbilData();
    }
}
</script>
<style></style>