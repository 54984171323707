<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Manajemen QR Code Aset Barang</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">QR Code</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardbiruatas2">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Lokasi</label>
                                        <v-select :options="lokasi" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedlokasi" @update:modelValue="onlokasiChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Gedung</label>
                                        <v-select :options="gedung" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedgedung" @update:modelValue="ongedungChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Lantai</label>
                                        <v-select :options="lantai" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedlantai" @update:modelValue="onlantaiChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2">
                                        <label>Ruangan</label>
                                        <v-select :options="ruangan" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedruangan" @update:modelValue="onruanganChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="col-sm-2 float-left pb-2">
                                        <button type="button" class="btn btn-sm btn-primary float-left text-white"
                                            @click="prosesdata()">
                                            <i class="fas fa-qrcode"></i> Tampilkan QR Code
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-10 float-left pb-2">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="col-sm-1 float-left pb-2">
                                        <button type="button"
                                            class="btn btn-sm btn-secondary float-left text-white lebar"
                                            @click="printContent()">
                                            <i class="fas fa-print"></i> Cetak
                                        </button>
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 250px;">Lokasi / Gedung / Lantai / Ruangan</th>
                                                    <th style="width: 250px;">Aset Barang</th>
                                                    <th style="width: 200px;">QR</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td>
                                                            {{ datalist.nmlokasi }}<br>
                                                            <span class="text-primary">{{ datalist.nmgedung
                                                                }}</span><br>
                                                            <span class="text-info">{{ datalist.nmlantai }}</span><br>
                                                            <span class="text-success">{{ datalist.nmruangan }}</span>
                                                        </td>
                                                        <td>
                                                            <span class="text-primary">
                                                                {{ datalist.nmsifat_aset }}
                                                            </span><br>
                                                            <span class="text-info">
                                                                {{ datalist.nmklasifikasi_aset }}
                                                            </span><br>
                                                            <span class="text-success">
                                                                {{ datalist.nmkategori_aset }}
                                                            </span><br>
                                                            <span class="text-secondary">
                                                                {{ datalist.nmjenis_aset }}
                                                            </span><br>
                                                        </td>
                                                        <td class="text-center">
                                                            <qrcode-vue render-as="svg"
                                                                :value="datalist.kode"></qrcode-vue>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div class="col-md-12 text-center" v-if="(datapesanmaster == 'data kosong')">
                                            Data
                                            Masih Kosong</div>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    Jumlah Data: {{ G_numFormat(JumlahfilteredKataData) }}
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import { ref } from 'vue';
import QrcodeVue, { Level, RenderAs } from 'qrcode.vue';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const value = ref('qrcode');
        const level = ref < Level > ('M');
        const renderAs = ref < RenderAs > ('svg');
    },
    components: {
        Footer, NavBar, Menu, vSelect, QrcodeVue
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            lokasiluser: JSON.parse(localStorage.getItem("loksistem")),
            namalokasiuser: JSON.parse(localStorage.getItem("nmloksistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),

            lokasi: [],
            datalokasi: [],
            defaultSelectedlokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,

            gedung: [],
            datagedung: [],
            defaultSelectedgedung: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedgedung: 0,

            lantai: [],
            datalantai: [],
            defaultSelectedlantai: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlantai: 0,

            ruangan: [],
            dataruangan: [],
            defaultSelectedruangan: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedruangan: 0,
            carikata: '',
            datamaster: [],
            datapesan: [],
            datapesanmaster: [],
            ekportexcel: [],
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        printContent() {
            var divToPrint = document.getElementById('tabeldata');
            var htmlToPrint = '' +
                '<style type="text/css">' +
                'table th, table td {' +
                'border:1px solid #000;' +
                '}' +
                '</style>';
            htmlToPrint += divToPrint.outerHTML;
            const printWindow = window.open('', '', 'height=1000,width=1000')
            printWindow.document.write(htmlToPrint)
            printWindow.print()

        },
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
                this.ambildataterpilih();
                this.selectedgedung = 0;
                this.ambildatalantaiterpilih();
                this.selectedlantai = 0;
                this.ambildataruanganterpilih();
            }
            catch (err) {
                return;
            }
        },
        ongedungChange(a) {
            try {
                this.selectedgedung = this.gedung[a].tag;
                this.ambildatalantaiterpilih();
                this.selectedlantai = 0;
                this.ambildataruanganterpilih();
            }
            catch (err) {
                return;
            }
        },
        onlantaiChange(a) {
            try {
                this.selectedlantai = this.lantai[a].tag;
                this.ambildataruanganterpilih();
            }
            catch (err) {
                return;
            }
        },
        onruanganChange(a) {
            try {
                this.selectedruangan = this.ruangan[a].tag;
                //this.ambildataterpilih();
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambildata() {
            this.halamanloading = true;

            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser > 4) {
                kodelokasi = JSON.parse(localStorage.getItem("loksistem"));
            }
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLokasi?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        if (this.leveluser < 4) {
                            this.lokasi.unshift({
                                label: 'Semua Data',
                                code: 0,
                                tag: 0,
                            });
                        }
                        if (this.leveluser > 4) {
                            this.defaultSelectedlokasi = {
                                code: kodelokasi,
                                label: JSON.parse(localStorage.getItem("nmloksistem")),
                                tag: kodelokasi,
                            };
                            this.selectedlokasi = kodelokasi;
                        }

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetGedung?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datagedung = [];
                        this.gedung = [];
                        this.datagedung = Response.data.content.data;
                        this.datagedung.forEach((item) => {
                            this.gedung.push({
                                label: item.nama,
                                code: this.datagedung.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.gedung.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });
                        this.selectedgedung = 0;

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            this.selectedgedung = '';
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLantai?kdlokasi=" + kodelokasi + "&kdgedung=" + this.selectedgedung + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalantai = [];
                        this.lantai = [];
                        this.datalantai = Response.data.content.data;
                        this.datalantai.forEach((item) => {
                            this.lantai.push({
                                label: item.nama,
                                code: this.datalantai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lantai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });

                        this.selectedlantai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.selectedlantai = '';
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetRuangan?kdlokasi=" + kodelokasi + "&kdgedung=" + this.selectedgedung + "&kdlantai=" + this.selectedlantai + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataruangan = [];
                        this.ruangan = [];
                        this.dataruangan = Response.data.content.data;
                        this.dataruangan.forEach((item) => {
                            this.ruangan.push({
                                label: item.nama,
                                code: this.dataruangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.ruangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });

                        this.selectedruangan = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async ambildataterpilih() {
            this.halamanloading = true;
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser > 4) {
                kodelokasi = JSON.parse(localStorage.getItem("loksistem"));
            }

            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetGedung?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datagedung = [];
                        this.gedung = [];
                        this.datagedung = Response.data.content.data;
                        this.datagedung.forEach((item) => {
                            this.gedung.push({
                                label: item.nama,
                                code: this.datagedung.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.gedung.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelectedgedung = {
                            code: 0,
                            label: 'Semua Data',
                            tag: 0,
                        };
                        this.selectedgedung = 0;
                    }
                    else {
                        this.datagedung = [];
                        this.gedung = [];
                        this.defaultSelectedgedung = '';
                        this.selectedgedung = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async ambildatalantaiterpilih() {
            this.halamanloading = true;
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser > 4) {
                kodelokasi = JSON.parse(localStorage.getItem("loksistem"));
            }

            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });

            var kodegedung = '';
            if ((this.selectedgedung != 0) && (this.selectedgedung != undefined)) {
                kodegedung = this.selectedgedung;
            }
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLantai?kdlokasi=" + kodelokasi + "&kdgedung=" + kodegedung + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalantai = [];
                        this.lantai = [];
                        this.datalantai = Response.data.content.data;
                        this.datalantai.forEach((item) => {
                            this.lantai.push({
                                label: item.nama,
                                code: this.datalantai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lantai.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelectedlantai = {
                            code: 0,
                            label: 'Semua Data',
                            tag: 0,
                        };
                        this.selectedlantai = 0;
                    }
                    else {
                        this.datalantai = [];
                        this.lantai = [];
                        this.defaultSelectedlantai = '';
                        this.selectedlantai = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async ambildataruanganterpilih() {
            this.halamanloading = true;
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser > 4) {
                kodelokasi = JSON.parse(localStorage.getItem("loksistem"));
            }

            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });

            var kodegedung = '';
            if ((this.selectedgedung != 0) && (this.selectedgedung != undefined)) {
                kodegedung = this.selectedgedung;
            }

            var kodelantai = '';
            if ((this.selectedlantai != 0) && (this.selectedlantai != undefined)) {
                kodelantai = this.selectedlantai;
            }
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetRuangan?kdlokasi=" + kodelokasi + "&kdgedung=" + kodegedung + "&kdlantai=" + kodelantai + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataruangan = [];
                        this.ruangan = [];
                        this.dataruangan = Response.data.content.data;
                        this.dataruangan.forEach((item) => {
                            this.ruangan.push({
                                label: item.nama,
                                code: this.dataruangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.ruangan.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });
                        this.defaultSelectedruangan = {
                            code: 0,
                            label: 'Semua Data',
                            tag: 0,
                        };
                        this.selectedruangan = 0;
                    }
                    else {
                        this.dataruangan = [];
                        this.ruangan = [];
                        this.defaultSelectedruangan = '';
                        this.selectedruangan = '';
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        async prosesdata() {
            this.halamanloading = true;
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });

            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            var koderuangan = '';
            if ((this.selectedruangan != 0) && (this.selectedruangan != undefined)) {
                koderuangan = this.selectedruangan;
            }
            var random = Math.random();
            await mainAPI4.get("dataaset_oripro-GetDataAset?kdlokasi=" + kodelokasi + "&kdgedung=" + this.selectedgedung + "&kdlantai=" + this.selectedlantai + "&kdruangan=" + koderuangan + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>