<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="modal fade" id="costumModaldetailaset" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: 0;">
            <div class="modal-dialog" style="position: absolute;top: 0;">
                <div class="modal-content" style="height: auto;min-width: 100vw;">
                    <div class="modal-header">
                        <h4 class="modal-title">Data Aset Barang {{ namadetailaset }}</h4>
                        <input type="hidden" class="form-control form-control-sm" id="kdlokasibarang"
                            name="kdlokasibarang" placeholder="Kode Lokasi" v-model="kdlokasibarang" readonly>
                        <input type="hidden" class="form-control form-control-sm" id="kdgedungbarang"
                            name="kdgedungbarang" placeholder="Kode Gedung" v-model="kdgedungbarang" readonly>
                        <input type="hidden" class="form-control form-control-sm" id="kdlantaibarang"
                            name="kdlantaibarang" placeholder="Kode Ruangan" v-model="kdlantaibarang" readonly>
                        <input type="hidden" class="form-control form-control-sm" id="kdruanganbarang"
                            name="kdruanganbarang" placeholder="Kode Ruangan" v-model="kdruanganbarang" readonly>
                    </div>
                    <div class="modal-body">
                        <p>
                        <div class="table-responsive table-wrapper table-striped">
                            <table class="table m-0" id="tabeldataaset" style="max-width: 100%;">
                                <thead>
                                    <tr>
                                        <th rowspan="2" style="width: 80px;">
                                            No<br>
                                            <button type="button" class="btn btn-warning btn-sm"
                                                @click="addKoordinat"><i class="far fa-plus-square"></i></button>
                                        </th>
                                        <th style="width: 400px;">Identitas Barang</th>
                                        <th style="width: 400px;">Perawatan Barang</th>
                                        <th style="width: 250px;">Lokasi</th>
                                        <th style="width: 250px;" rowspan="2">PJ </th>
                                    </tr>
                                    <tr>
                                        <th>Sifat / Klasifikasi / Kategori / Jenis / Merk / Tipe</th>
                                        <th>Jenis / Durasi / Metode / Alat / Jam Mulai / Jam Selesai</th>
                                        <th>Latitude / Longitude</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(koodinatsetuju, counter) in koodinatsetujus" v-bind:key="counter">
                                        <td class="text-center">
                                            {{ counter + 1 }}<br>
                                            <button type="button" class="btn btn-warning btn-sm"
                                                @click="deleteKoordinat(counter)" v-if="(counter >= 0)"><i
                                                    class="far fa-minus-square"></i></button>
                                            <input type="hidden" placeholder="ID" class="form-control form-control-sm"
                                                :id="'id' + (counter + 1)" style="font-size: smaller;"
                                                :value="koodinatsetuju.id">
                                        </td>
                                        <td>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>Sifat</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'sifat_aset' + (counter + 1)">
                                                        <option v-for="(sifataset, i) in sifat_aset"
                                                            :value="sifataset['code']" :key="i"
                                                            :selected="sifataset['code'] == koodinatsetuju.sifat_aset">
                                                            {{ sifataset['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Klasifikasi</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'klasifikasi_aset' + (counter + 1)">
                                                        <option v-for="(klasifikasiaset, i) in klasifikasi_aset"
                                                            :value="klasifikasiaset['code']" :key="i"
                                                            :selected="klasifikasiaset['code'] == koodinatsetuju.klasifikasi_aset">
                                                            {{ klasifikasiaset['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Kategori</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'kategori_aset' + (counter + 1)">
                                                        <option v-for="(kategoriaset, i) in kategori_aset"
                                                            :value="kategoriaset['code']" :key="i"
                                                            :selected="kategoriaset['code'] == koodinatsetuju.kategori_aset">
                                                            {{ kategoriaset['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jenis</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'jenis_aset' + (counter + 1)">
                                                        <option v-for="(jenisaset, i) in jenis_aset"
                                                            :value="jenisaset['code']" :key="i"
                                                            :selected="jenisaset['code'] == koodinatsetuju.jenis_aset">
                                                            {{ jenisaset['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Merk</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'merk_aset' + (counter + 1)">
                                                        <option v-for="(merkaset, i) in merk_aset"
                                                            :value="merkaset['code']" :key="i"
                                                            :selected="merkaset['code'] == koodinatsetuju.merk_aset">
                                                            {{ merkaset['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Tipe</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'tipe_aset' + (counter + 1)">
                                                        <option v-for="(tipeaset, i) in tipe_aset"
                                                            :value="tipeaset['code']" :key="i"
                                                            :selected="tipeaset['code'] == koodinatsetuju.tipe_aset">
                                                            {{ tipeaset['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>Jenis Perawatan</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'jenis_perawatan' + (counter + 1)">
                                                        <option v-for="(jenisperawatan, i) in jenis_perawatan"
                                                            :value="jenisperawatan['code']" :key="i"
                                                            :selected="jenisperawatan['code'] == koodinatsetuju.jenis_perawatan">
                                                            {{ jenisperawatan['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Durasi Perawatan</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'durasi_perawatan' + (counter + 1)">
                                                        <option v-for="(durasiperawatan, i) in durasi_perawatan"
                                                            :value="durasiperawatan['code']" :key="i"
                                                            :selected="durasiperawatan['code'] == koodinatsetuju.durasi_perawatan">
                                                            {{ durasiperawatan['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Metode Perawatan</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'metode_perawatan' + (counter + 1)">
                                                        <option v-for="(metodeperawatan, i) in metode_perawatan"
                                                            :value="metodeperawatan['code']" :key="i"
                                                            :selected="metodeperawatan['code'] == koodinatsetuju.metode_perawatan">
                                                            {{ metodeperawatan['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Alat Perawatan</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'alat_perawatan' + (counter + 1)">
                                                        <option v-for="(alatperawatan, i) in alat_perawatan"
                                                            :value="alatperawatan['code']" :key="i"
                                                            :selected="alatperawatan['code'] == koodinatsetuju.alat_perawatan">
                                                            {{ alatperawatan['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jam Mulai Perawatan</label>
                                                    <input type="time" class="form-control form-control-sm"
                                                        :id="'jam_mulai' + (counter + 1)" style="font-size: smaller;"
                                                        :value="koodinatsetuju.jam_mulai">
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jam Selesai Perawatan</label>
                                                    <input type="time" class="form-control form-control-sm"
                                                        :id="'jam_selesai' + (counter + 1)" style="font-size: smaller;"
                                                        :value="koodinatsetuju.jam_selesai">
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <label>Latitude</label>
                                                    <input type="number" placeholder="Latitude"
                                                        class="form-control form-control-sm"
                                                        :id="'latitude' + (counter + 1)" style="font-size: smaller;"
                                                        :value="koodinatsetuju.latitude">
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Longitude</label>
                                                    <input type="number" placeholder="Longitude"
                                                        class="form-control form-control-sm"
                                                        :id="'longitude' + (counter + 1)" style="font-size: smaller;"
                                                        :value="koodinatsetuju.longitude">
                                                </div>
                                                <!--SERTIFIKAT GAMBAR 1-->
                                                <div class="col-sm-12">
                                                    <label>Dokumentasi<small class="text-info"> (*images files)</small>
                                                    </label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="far fa-file-image"></i></span>
                                                        </div>
                                                        <input type="file" class="form-control form-control-sm"
                                                            :id="'fileToUploadfilenya_aset_' + (counter + 1)"
                                                            placeholder="fileToUpload" style="font-size: smaller;"
                                                            accept="image/*">
                                                        <input type="hidden" class="form-control form-control-sm"
                                                            :id="'filenya_aset_' + (counter + 1)"
                                                            placeholder="File Dokumentasi"
                                                            :value="koodinatsetuju.filenya_aset">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <label>Opsi</label>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar"
                                                                    aria-valuenow="0" aria-valuemin="0"
                                                                    aria-valuemax="100" style="width:0%"
                                                                    :id="'persennyafilenya_aset_' + (counter + 1)">
                                                                    <span
                                                                        :id="'tulisanpersenfilenya_aset_' + (counter + 1)">0%</span>
                                                                </div>
                                                            </div>
                                                            <br>
                                                            <span class="text-success"
                                                                :id="'suksesnyafilenya_aset_' + (counter + 1)"></span>
                                                        </div>
                                                        <div class="col-sm-12 text-center">
                                                            <button type="button"
                                                                class="btn btn-warning btn-sm lebar text-white"
                                                                @click="upload('filenya_aset_' + (counter + 1), 'image/*')">Upload
                                                                File</button>
                                                            <button type="button" class="btn btn-danger btn-sm lebar"
                                                                @click="clearfile('filenya_aset_' + (counter + 1))">Hapus
                                                                File</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--SERTIFIKAT GAMBAR 1 END-->
                                            </div>
                                        </td>
                                        <td>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <label>PJ</label>
                                                    <select class="form-control form-control-sm"
                                                        style="width: 100%;font-size: smaller;"
                                                        :id="'pj_aset' + (counter + 1)">
                                                        <option v-for="(pjaset, i) in pj_aset_barang"
                                                            :value="pjaset['code']" :key="i"
                                                            :selected="pjaset['code'] == koodinatsetuju.pj_aset">
                                                            {{ pjaset['label'] }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div v-if="koodinatsetuju.filenya_aset" class="col-sm-12 text-center">
                                                    &nbsp;</div>
                                                <div v-if="koodinatsetuju.filenya_aset" class="col-sm-12 text-center">
                                                    <img :src="folder_foto + koodinatsetuju.filenya_aset"
                                                        class="img-size-75 animation__wobble" alt="User Image"
                                                        style="height: 150px;" v-if="koodinatsetuju.filenya_aset">
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-primary btn-sm lebar" @click="simpandataaset()"><i
                                class="fas fa-edit"></i>
                            Simpan</button>
                        <button type="button" class="btn btn-info btn-sm lebar" data-dismiss="modal"> <i
                                class="far fa-times-circle"></i>
                            Batal</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
        <div class="modal fade" id="costumModaldetail" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Data Informasi Ruangan</h4>
                    </div>
                    <div class="modal-body text-justify">
                        <p>
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Nama Ruangan</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-building"></i></span>
                                    </div>
                                    <input type="hidden" class="form-control form-control-sm" id="kdlokasi"
                                        name="kdlokasi" placeholder="Kode Lokasi" v-model="kdlokasi" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="kdgedung"
                                        name="kdgedung" placeholder="Kode Gedung" v-model="kdgedung" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="kdlantai"
                                        name="kdlantai" placeholder="Kode Ruangan" v-model="kdlantai" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="kdruangan"
                                        name="kdruangan" placeholder="Kode Ruangan" v-model="kdruangan" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="id_tabel_data_ruangan"
                                        name="id_tabel_data_ruangan" placeholder="Kode Tabel Ruangan"
                                        v-model="id_tabel_data_ruangan" readonly>
                                    <input type="text" class="form-control form-control-sm" id="nama" name="nama"
                                        placeholder="Nama Aset" v-model="nama" readonly>

                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Panjang Ruangan (m)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-arrows-alt-v"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="panjang"
                                        name="panjang" placeholder="Panjang Ruangan" v-model="panjang">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Lebar Ruangan (m)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-arrows-alt-h"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="lebar" name="lebar"
                                        placeholder="Lebar Ruangan" v-model="lebar">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Tinggi Ruangan (m)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-ellipsis-v"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="tinggi" name="tinggi"
                                        placeholder="Tinggi Ruangan" v-model="tinggi">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Luas Ruangan (m<sup>2</sup>)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-expand"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="luas_m2"
                                        name="luas_m2" placeholder="Luas Ruangan" v-model="luas_m2">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Bentuk Ruangan</label>
                                <v-select :options="bentuk_bangunan" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedbentuk_bangunan"
                                    @update:modelValue="onbentuk_bangunanChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Penggunaan Ruangan</label>
                                <v-select :options="status_penggunaan" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstatus_penggunaan"
                                    @update:modelValue="onstatus_penggunaanChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Atap</label>
                                <v-select :options="struktur_atap" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_atap" @update:modelValue="onstruktur_atapChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Atap</label>
                                <v-select :options="atap" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedatap" @update:modelValue="onatapChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Dinding</label>
                                <v-select :options="struktur_dinding" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_dinding"
                                    @update:modelValue="onstruktur_dindingChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Dinding</label>
                                <v-select :options="dinding" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelecteddinding" @update:modelValue="ondindingChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Lantai</label>
                                <v-select :options="struktur_lantai" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_lantai"
                                    @update:modelValue="onstruktur_lantaiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Lantai</label>
                                <v-select :options="lantai" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedlantai" @update:modelValue="onlantaiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Pondasi</label>
                                <v-select :options="struktur_pondasi" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_pondasi"
                                    @update:modelValue="onstruktur_pondasiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Pondasi</label>
                                <v-select :options="pondasi" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedpondasi" @update:modelValue="onpondasiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Kondisi</label>
                                <v-select :options="kondisi" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedkondisi" @update:modelValue="onkondisiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Jenis Penggunaan</label>
                                <v-select :options="jenis_penggunaan" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedjenis_penggunaan"
                                    @update:modelValue="onjenis_penggunaanChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Tenant</label>
                                <v-select :options="tenant" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedtenant" @update:modelValue="ontenantChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Penanggungjawab Aset</label>
                                <v-select :options="pj_aset" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedpj_aset" @update:modelValue="onpj_asetChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Tanggal Data</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                                    </div>
                                    <input type="date" class="form-control form-control-sm" id="tanggal_data"
                                        name="tanggal_data" placeholder="Tanggal Data" v-model="tanggal_data">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Catatan / Keterangan</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-chalkboard"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="catatan" name="catatan"
                                        placeholder="Catatan" v-model="catatan">
                                </div>
                            </div>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-primary btn-sm lebar" @click="simpandata()"><i
                                class="fas fa-edit"></i>
                            Simpan</button>
                        <button type="button" class="btn btn-info btn-sm lebar" data-dismiss="modal"> <i
                                class="far fa-times-circle"></i>
                            Batal</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Manajemen Data Informasi Ruangan & Barang</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                <li class="breadcrumb-item active">Data Informasi Ruangan & Barang</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-8 float-left pb-2">
                                        <label>Pencarian</label>
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="col-sm-3 float-left pb-2">
                                        <label>Lokasi</label>
                                        <v-select :options="lokasi" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedLokasi" @update:modelValue="onlokasiChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 80px;">No</th>
                                                    <th>Lokasi</th>
                                                    <th style="width: 150px;">Informasi Ruangan</th>
                                                    <th style="width: 100px;">Status Ruangan<br>& Nama Tenant</th>
                                                    <th style="width: 80px;">Luas (m<sup>2</sup>)</th>
                                                    <th style="width: 80px;">Skoring</th>
                                                    <th style="width: 100px;">PJ</th>
                                                    <th style="width: 100px;">Jumlah Aset Barang</th>
                                                    <th style="width: 130px;">Opsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td :class="datalist.class">{{ datalist.No }}</td>
                                                        <td :class="datalist.class">{{ datalist.nama }}</td>
                                                        <td :class="datalist.class + ' text-center'">
                                                            <span
                                                                :class="[(datalist.id_ruangan != 'Informasi detail belum tersedia' ? 'badge badge-success' : 'badge badge-danger')]">
                                                                {{ datalist.id_ruangan }}
                                                            </span>
                                                        </td>
                                                        <td :class="datalist.class">
                                                            {{ datalist.nmjenis_penggunaan }}<br>
                                                            <span class="text-primary">{{ datalist.nmtenant }}</span>
                                                        </td>
                                                        <td :class="datalist.class + ' text-right'">
                                                            {{ G_numFormatKoma(datalist.luas_m2) }}
                                                        </td>
                                                        <td :class="datalist.class + ' text-right'">
                                                            {{ G_numKoma(datalist.skor) }}
                                                        </td>
                                                        <td :class="datalist.class">
                                                            {{ datalist.nmpj_aset }}
                                                        </td>
                                                        <td :class="datalist.class + ' text-right'">
                                                            <a href="#"
                                                                @click="prosesaset(datalist.nama, datalist.kdlokasi, datalist.kdgedung, datalist.kdlantai, datalist.kdruangan)"
                                                                v-if="datalist.class == ''">{{
                                                                    G_numFormat(datalist.jumlahaset) }}</a>
                                                            <span v-if="datalist.class != ''">{{
                                                                G_numFormat(datalist.jumlahaset) }}</span>
                                                        </td>
                                                        <td :class="datalist.class + ' text-center'">
                                                            <button type="button" @click="prosesdata(datalist.kdlokasi, datalist.kdgedung, datalist.kdlantai, datalist.kdruangan, datalist.id_tabel_data_ruangan, datalist.nama
                                                                , datalist.panjang, datalist.lebar, datalist.tinggi, datalist.luas_m2
                                                                , datalist.bentuk_bangunan, datalist.nmbentuk_bangunan
                                                                , datalist.status_penggunaan, datalist.nmstatus_penggunaan
                                                                , datalist.struktur_atap, datalist.nmstruktur_atap
                                                                , datalist.atap, datalist.nmatap
                                                                , datalist.struktur_dinding, datalist.nmstruktur_dinding
                                                                , datalist.dinding, datalist.nmdinding
                                                                , datalist.struktur_lantai, datalist.nmstruktur_lantai
                                                                , datalist.lantai, datalist.nmlantai
                                                                , datalist.struktur_pondasi, datalist.nmstruktur_pondasi
                                                                , datalist.pondasi, datalist.nmpondasi
                                                                , datalist.kondisi, datalist.nmkondisi
                                                                , datalist.pj_aset, datalist.nmpj_aset
                                                                , datalist.jenis_penggunaan, datalist.nmjenis_penggunaan
                                                                , datalist.tenant, datalist.nmtenant
                                                                , datalist.tanggal_data, datalist.catatan
                                                            )" class="btn btn-info btn-sm lebar text-white"
                                                                v-if="datalist.class == ''">
                                                                <i class="fas fa-edit"></i> Setting
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div class="col-md-12 text-center" v-if="(datapesanmaster == 'data kosong')">
                                            Data
                                            Masih Kosong</div>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    Jumlah Lokasi: {{ G_numFormat(JumlahfilteredKataData) }}
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { inject } from 'vue';
import swal from 'sweetalert2';
import xlsx from 'xlsx/dist/xlsx.full.min';
import vSelect from 'vue-select';
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, swal, vSelect, Vue3ColorPicker
    },
    data() {
        return {
            halamanloading: true,
            validasi: false,
            carikata: '',
            tmpdatamaster: [],
            datamaster: [],
            datapesan: [],
            datapesanmaster: [],
            ekportexcel: [],
            kdorganisasi: JSON.parse(localStorage.getItem("orsistem")),

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            lokasiuser: JSON.parse(localStorage.getItem("loksistem")),
            nmlokasiuser: JSON.parse(localStorage.getItem("nmloksistem")),
            kodeaset: 0,
            namaaset: 0,
            kdlokasi: 0,
            kdgedung: 0,
            kdlantai: 0,
            kdruangan: 0,
            id_tabel_data_ruangan: '',
            nama: '',
            panjang: 0,
            lebar: 0,
            tinggi: 0,
            luas_m2: 0,

            bentuk_bangunan: [],
            databentuk_bangunan: [],
            defaultSelectedbentuk_bangunan: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedbentuk_bangunan: 0,

            status_penggunaan: [],
            datastatus_penggunaan: [],
            defaultSelectedstatus_penggunaan: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstatus_penggunaan: 0,

            struktur_atap: [],
            datastruktur_atap: [],
            defaultSelectedstruktur_atap: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_atap: 0,

            atap: [],
            dataatap: [],
            defaultSelectedatap: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedatap: 0,

            struktur_dinding: [],
            datastruktur_dinding: [],
            defaultSelectedstruktur_dinding: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_dinding: 0,

            dinding: [],
            datadinding: [],
            defaultSelecteddinding: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selecteddinding: 0,

            struktur_lantai: [],
            datastruktur_lantai: [],
            defaultSelectedstruktur_lantai: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_lantai: 0,

            lantai: [],
            datalantai: [],
            defaultSelectedlantai: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedlantai: 0,

            struktur_pondasi: [],
            datastruktur_pondasi: [],
            defaultSelectedstruktur_pondasi: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_pondasi: 0,

            pondasi: [],
            datapondasi: [],
            defaultSelectedpondasi: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpondasi: 0,
            kondisi: [],
            datakondisi: [],
            defaultSelectedkondisi: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedkondisi: 0,

            pj_aset: [],
            datapj_aset: [],
            defaultSelectedpj_aset: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpj_aset: 0,

            jenis_penggunaan: [],
            datajenis_penggunaan: [],
            defaultSelectedjenis_penggunaan: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedjenis_penggunaan: 0,

            tenant: [],
            datatenant: [],
            defaultSelectedtenant: {
                code: 0,
                label: 'Tidak Ada',
                tag: 0,
            },
            selectedtenant: 0,

            tanggal_data: '',
            catatan: '',
            kdlokasibarang: 0,
            kdgedungbarang: 0,
            kdlantaibarang: 0,
            kdruanganbarang: 0,

            counter: 0,
            koodinatsetujus: [],
            tempdataaset: [],

            sifat_aset: [],
            datasifat_aset: [],
            defaultSelectedsifat_aset: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedsifat_aset: 0,

            klasifikasi_aset: [],
            dataklasifikasi_aset: [],
            defaultSelectedklasifikasi_aset: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedklasifikasi_aset: 0,

            kategori_aset: [],
            datakategori_aset: [],
            defaultSelectedkategori_aset: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedkategori_aset: 0,

            jenis_aset: [],
            datajenis_aset: [],
            defaultSelectedjenis_aset: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedjenis_aset: 0,

            merk_aset: [],
            datamerk_aset: [],
            defaultSelectedmerk_aset: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedmerk_aset: 0,

            tipe_aset: [],
            datatipe_aset: [],
            defaultSelectedtipe_aset: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedtipe_aset: 0,

            jenis_perawatan: [],
            datajenis_perawatan: [],
            defaultSelectedjenis_perawatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedjenis_perawatan: 0,

            durasi_perawatan: [],
            datadurasi_perawatan: [],
            defaultSelecteddurasi_perawatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selecteddurasi_perawatan: 0,

            metode_perawatan: [],
            datametode_perawatan: [],
            defaultSelectedmetode_perawatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },
            selectedmetode_perawatan: 0,

            alat_perawatan: [],
            dataalat_perawatan: [],
            defaultSelectedalat_perawatan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: 0,
            },

            pj_aset_barang: [],
            datapj_aset_barang: [],
            defaultSelectedpj_aset_barang: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpj_aset_barang: 0,
            selectedalat_perawatan: 0,
            folder_foto: process.env.VUE_APP_URL_API + 'dataaset_oripro-GambarAset?f=',
            namadetailaset: '',

        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        simpandataaset() {
            if (this.koodinatsetujus.length == 0) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Data masih Kosong',
                });
                $('.swal2-container').css("z-index", '999999');
                return false;
            }

            for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                if (document.getElementById('sifat_aset' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Sifat Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('klasifikasi_aset' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Klasifikasi Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('kategori_aset' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Kategori Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('jenis_aset' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Jenis Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('merk_aset' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Merk Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('tipe_aset' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Tipe Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('jenis_perawatan' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Jenis Perawatan Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }

                if (document.getElementById('durasi_perawatan' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Durasi Perawatan Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }

                if (document.getElementById('metode_perawatan' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Metode Perawatan Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }

                if (document.getElementById('alat_perawatan' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Alat Perawatan Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }

                if (document.getElementById('jam_mulai' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Jam Mulai Perawatan Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('jam_selesai' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Jam Selesai Perawatan Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }
                if (document.getElementById('latitude' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Koordinat Latitude Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }

                if (document.getElementById('longitude' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Koordinat Longitude Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }

                if (document.getElementById('pj_aset' + i).value == '0') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Isikan Penanggungjawab Aset ke ' + i,
                    });
                    $('.swal2-container').css("z-index", '999999');
                    return false;
                }

                var d = new Date();
                var month = '' + (d.getMonth() + 1);
                var day = '' + d.getDate();
                var year = d.getFullYear();
                var hour = d.getHours();
                var minutes = d.getMinutes();
                var second = d.getSeconds();
                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;
                var kodeawal = [year, month, day, hour, minutes, second].join('');
                var id = document.getElementById('id' + i).value;
                var fd = new FormData();
                fd.append("lokasi", document.getElementById('kdlokasibarang').value);
                fd.append("gedung", document.getElementById('kdgedungbarang').value);
                fd.append("kd_lantai", document.getElementById('kdlantaibarang').value);
                fd.append("ruangan", document.getElementById('kdruanganbarang').value);
                fd.append("id", document.getElementById('id' + i).value);
                fd.append("sifat_aset", document.getElementById('sifat_aset' + i).value);
                fd.append("klasifikasi_aset", document.getElementById('klasifikasi_aset' + i).value);
                fd.append("kategori_aset", document.getElementById('kategori_aset' + i).value);
                fd.append("jenis_aset", document.getElementById('jenis_aset' + i).value);
                fd.append("merk_aset", document.getElementById('merk_aset' + i).value);
                fd.append("tipe_aset", document.getElementById('tipe_aset' + i).value);
                fd.append("kode", kodeawal + document.getElementById('kdlokasibarang').value + '_'
                    + document.getElementById('kdgedungbarang').value + '_'
                    + document.getElementById('kdlantaibarang').value + '_'
                    + document.getElementById('kdruanganbarang').value + '_'
                    + document.getElementById('sifat_aset' + i).value + '_'
                    + document.getElementById('klasifikasi_aset' + i).value + '_'
                    + document.getElementById('kategori_aset' + i).value + '_'
                    + document.getElementById('jenis_aset' + i).value + '_'
                    + document.getElementById('merk_aset' + i).value + '_'
                    + document.getElementById('tipe_aset' + i).value);
                fd.append("jenis_perawatan", document.getElementById('jenis_perawatan' + i).value);
                fd.append("durasi_perawatan", document.getElementById('durasi_perawatan' + i).value);
                fd.append("metode_perawatan", document.getElementById('metode_perawatan' + i).value);
                fd.append("alat_perawatan", document.getElementById('alat_perawatan' + i).value);
                fd.append("jam_mulai", document.getElementById('jam_mulai' + i).value);
                fd.append("jam_selesai", document.getElementById('jam_selesai' + i).value);
                fd.append("latitude", document.getElementById('latitude' + i).value);
                fd.append("longitude", document.getElementById('longitude' + i).value);
                fd.append("filenya_aset", document.getElementById('filenya_aset_' + i).value);
                fd.append("pj_aset", document.getElementById("pj_aset" + i).value);
                fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });

                if (id == '') {
                    mainAPI.post("dataaset_oripro-AddDataAset", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                    window.location.replace("/DataRuangan");
                                });
                            }
                            $('.swal2-container').css("z-index", '999999');
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                        $('.swal2-container').css("z-index", '999999');
                    }
                    )
                }
                else {
                    mainAPI.post("dataaset_oripro-UpdateDataAset", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                                    window.location.replace("/DataRuangan");
                                });
                            }
                            $('.swal2-container').css("z-index", '999999');
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                        $('.swal2-container').css("z-index", '999999');
                    }
                    )
                }

            }
        },
        clearfile(idnya) {
            var tempparameter = idnya.toString().split('_');
            var parameter = tempparameter[0] + '_' + tempparameter[1];
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                $('.swal2-container').css("z-index", '999999');
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", parameter);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    $('.swal2-container').css("z-index", '999999');
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
                $('.swal2-container').css("z-index", '999999');
            }
            )
            $('.swal2-container').css("z-index", '999999');
            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        upload(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            var tempparameter = idnya.toString().split('_');
            var parameter = tempparameter[0] + '_' + tempparameter[1];
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", parameter);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("oripro-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        initializeSelect2() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
        },
        addKoordinat() {
            this.koodinatsetujus.push({
                previous: '',
                expiration: ''
            })
        },
        deleteKoordinat(counter) {
            this.koodinatsetujus.splice(counter, 1);
        },
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        onbentuk_bangunanChange(a) {
            try {
                this.selectedbentuk_bangunan = this.bentuk_bangunan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatus_penggunaanChange(a) {
            try {
                this.selectedstatus_penggunaan = this.status_penggunaan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_atapChange(a) {
            try {
                this.selectedstruktur_atap = this.struktur_atap[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onatapChange(a) {
            try {
                this.selectedatap = this.atap[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_dindingChange(a) {
            try {
                this.selectedstruktur_dinding = this.struktur_dinding[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ondindingChange(a) {
            try {
                this.selecteddinding = this.dinding[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_lantaiChange(a) {
            try {
                this.selectedstruktur_lantai = this.struktur_lantai[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onlantaiChange(a) {
            try {
                this.selectedlantai = this.lantai[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_pondasiChange(a) {
            try {
                this.selectedstruktur_pondasi = this.struktur_pondasi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpondasiChange(a) {
            try {
                this.selectedpondasi = this.pondasi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onair_bersihChange(a) {
            try {
                this.selectedair_bersih = this.air_bersih[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpengelolaan_limbahChange(a) {
            try {
                this.selectedpengelolaan_limbah = this.pengelolaan_limbah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onlimbahChange(a) {
            try {
                this.selectedlimbah = this.limbah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpengelolaan_sampahChange(a) {
            try {
                this.selectedpengelolaan_sampah = this.pengelolaan_sampah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onparkirChange(a) {
            try {
                this.selectedparkir = this.parkir[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkondisiChange(a) {
            try {
                this.selectedkondisi = this.kondisi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpj_asetChange(a) {
            try {
                this.selectedpj_aset = this.pj_aset[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onjenis_penggunaanChange(a) {
            try {
                this.selectedjenis_penggunaan = this.jenis_penggunaan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontenantChange(a) {
            try {
                this.selectedtenant = this.tenant[a].tag;
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async AmbilData() {
            this.halamanloading = true;
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser >= 4) {
                kodelokasi = this.lokasiuser;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("dataruangan_oripro-GetDataRuangan?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.tmpdatamaster = [];
                        this.tmpdatamaster = Response.data.content.data;
                    }

                    this.datamaster = [];
                    for (let i = 0; i < this.tmpdatamaster.length; i++) {
                        this.datamaster.push({
                            "No": i + 1,
                            "id": this.tmpdatamaster[i].id,
                            "kdlokasi": this.tmpdatamaster[i].kdlokasi,
                            "nama": this.tmpdatamaster[i].nama,
                            "tabel": this.tmpdatamaster[i].tabel,
                            "class": this.tmpdatamaster[i].class,
                            "id_ruangan": '',
                            "id_tabel_data_ruangan": '',
                            "kdgedung": '',
                            "panjang": '',
                            "lebar": '',
                            "tinggi": '',
                            "luas_m2": this.tmpdatamaster[i].luas_m2,
                            "bentuk_bangunan": '',
                            "nmbentuk_bangunan": '',
                            "status_penggunaan": '',
                            "nmstatus_penggunaan": '',
                            "struktur_atap": '',
                            "nmstruktur_atap": '',
                            "atap": '',
                            "nmatap": '',
                            "struktur_dinding": '',
                            "nmstruktur_dinding": '',
                            "dinding": '',
                            "nmdinding": '',
                            "struktur_lantai": '',
                            "nmstruktur_lantai": '',
                            "lantai": '',
                            "nmlantai": '',
                            "struktur_pondasi": '',
                            "nmstruktur_pondasi": '',
                            "pondasi": '',
                            "nmpondasi": '',
                            "kondisi": '',
                            "nmkondisi": '',
                            "pj_aset": '',
                            "nmpj_aset": '',
                            "jenis_penggunaan": '',
                            "nmjenis_penggunaan": '',
                            "tenant": '',
                            "nmtenant": '',
                            "tanggal_data": '',
                            "catatan": '',
                            "jumlahaset": this.tmpdatamaster[i].jumlahaset,
                            "skor": this.tmpdatamaster[i].skor,
                        })
                        for (let z = 0; z < this.tmpdatamaster[i].gedung.length; z++) {
                            this.datamaster.push({
                                "No": (i + 1) + "." + (z + 1),
                                "id": this.tmpdatamaster[i].gedung[z].id,
                                "kdlokasi": this.tmpdatamaster[i].gedung[z].kdlokasi,
                                "nama": this.tmpdatamaster[i].gedung[z].nama,
                                "tabel": this.tmpdatamaster[i].gedung[z].tabel,
                                "class": this.tmpdatamaster[i].gedung[z].class,
                                "id_ruangan": '',
                                "id_tabel_data_ruangan": '',
                                "kdgedung": '',
                                "panjang": '',
                                "lebar": '',
                                "tinggi": '',
                                "luas_m2": this.tmpdatamaster[i].gedung[z].luas_m2,
                                "bentuk_bangunan": '',
                                "nmbentuk_bangunan": '',
                                "status_penggunaan": '',
                                "nmstatus_penggunaan": '',
                                "struktur_atap": '',
                                "nmstruktur_atap": '',
                                "atap": '',
                                "nmatap": '',
                                "struktur_dinding": '',
                                "nmstruktur_dinding": '',
                                "dinding": '',
                                "nmdinding": '',
                                "struktur_lantai": '',
                                "nmstruktur_lantai": '',
                                "lantai": '',
                                "nmlantai": '',
                                "struktur_pondasi": '',
                                "nmstruktur_pondasi": '',
                                "pondasi": '',
                                "nmpondasi": '',
                                "kondisi": '',
                                "nmkondisi": '',
                                "pj_aset": this.tmpdatamaster[i].gedung[z].pj_aset,
                                "nmpj_aset": this.tmpdatamaster[i].gedung[z].nmpj_aset,
                                "jenis_penggunaan": '',
                                "nmjenis_penggunaan": '',
                                "tenant": '',
                                "nmtenant": '',
                                "tanggal_data": '',
                                "jumlahaset": this.tmpdatamaster[i].gedung[z].jumlahaset,
                                "catatan": '',
                                "skor": this.tmpdatamaster[i].gedung[z].skor,
                            })
                            for (let m = 0; m < this.tmpdatamaster[i].gedung[z].lantai.length; m++) {
                                this.datamaster.push({
                                    "No": (i + 1) + "." + (z + 1) + "." + (m + 1),
                                    "id": this.tmpdatamaster[i].gedung[z].lantai[m].id,
                                    "kdlokasi": this.tmpdatamaster[i].gedung[z].lantai[m].kdlokasi,
                                    "kdgedung": this.tmpdatamaster[i].gedung[z].lantai[m].kdgedung,
                                    "kdlantai": this.tmpdatamaster[i].gedung[z].lantai[m].kdlantai,
                                    "nama": this.tmpdatamaster[i].gedung[z].lantai[m].nama,
                                    "tabel": this.tmpdatamaster[i].gedung[z].lantai[m].tabel,
                                    "class": this.tmpdatamaster[i].gedung[z].lantai[m].class,
                                    "id_ruangan": '',
                                    "id_tabel_data_ruangan": '',
                                    "kdgedung": '',
                                    "panjang": '',
                                    "lebar": '',
                                    "tinggi": '',
                                    "luas_m2": this.tmpdatamaster[i].gedung[z].lantai[m].luas_m2,
                                    "bentuk_bangunan": '',
                                    "nmbentuk_bangunan": '',
                                    "status_penggunaan": '',
                                    "nmstatus_penggunaan": '',
                                    "struktur_atap": '',
                                    "nmstruktur_atap": '',
                                    "atap": '',
                                    "nmatap": '',
                                    "struktur_dinding": '',
                                    "nmstruktur_dinding": '',
                                    "dinding": '',
                                    "nmdinding": '',
                                    "struktur_lantai": '',
                                    "nmstruktur_lantai": '',
                                    "lantai": '',
                                    "nmlantai": '',
                                    "struktur_pondasi": '',
                                    "nmstruktur_pondasi": '',
                                    "pondasi": '',
                                    "nmpondasi": '',
                                    "kondisi": '',
                                    "nmkondisi": '',
                                    "pj_aset": this.tmpdatamaster[i].gedung[z].lantai[m].pj_aset,
                                    "nmpj_aset": this.tmpdatamaster[i].gedung[z].lantai[m].nmpj_aset,
                                    "jenis_penggunaan": '',
                                    "nmjenis_penggunaan": '',
                                    "tenant": '',
                                    "nmtenant": '',
                                    "tanggal_data": '',
                                    "jumlahaset": this.tmpdatamaster[i].gedung[z].lantai[m].jumlahaset,
                                    "catatan": '',
                                    "skor": this.tmpdatamaster[i].gedung[z].lantai[m].skor,
                                })
                                for (let p = 0; p < this.tmpdatamaster[i].gedung[z].lantai[m].ruangan.length; p++) {
                                    this.datamaster.push({
                                        "No": (i + 1) + "." + (z + 1) + "." + (m + 1) + "." + (p + 1),
                                        "id": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].id,
                                        "kdlokasi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].kdlokasi,
                                        "kdgedung": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].kdgedung,
                                        "kdlantai": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].kdlantai,
                                        "kdruangan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].kdruangan,
                                        "nama": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nama,
                                        "tabel": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].tabel,
                                        "class": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].class,
                                        "id_ruangan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].id_ruangan,
                                        "id_tabel_data_ruangan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].id_tabel_data_ruangan,
                                        "kdgedung": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].kdgedung,
                                        "panjang": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].panjang,
                                        "lebar": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].lebar,
                                        "tinggi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].tinggi,
                                        "luas_m2": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].luas_m2,
                                        "bentuk_bangunan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].bentuk_bangunan,
                                        "nmbentuk_bangunan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmbentuk_bangunan,
                                        "status_penggunaan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].status_penggunaan,
                                        "nmstatus_penggunaan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmstatus_penggunaan,
                                        "struktur_atap": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].struktur_atap,
                                        "nmstruktur_atap": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmstruktur_atap,
                                        "atap": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].atap,
                                        "nmatap": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmatap,
                                        "struktur_dinding": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].struktur_dinding,
                                        "nmstruktur_dinding": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmstruktur_dinding,
                                        "dinding": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].dinding,
                                        "nmdinding": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmdinding,
                                        "struktur_lantai": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].struktur_lantai,
                                        "nmstruktur_lantai": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmstruktur_lantai,
                                        "lantai": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].lantai,
                                        "nmlantai": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmlantai,
                                        "struktur_pondasi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].struktur_pondasi,
                                        "nmstruktur_pondasi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmstruktur_pondasi,
                                        "pondasi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].pondasi,
                                        "nmpondasi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmpondasi,
                                        "kondisi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].kondisi,
                                        "nmkondisi": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmkondisi,
                                        "pj_aset": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].pj_aset,
                                        "nmpj_aset": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmpj_aset,
                                        "jenis_penggunaan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].jenis_penggunaan,
                                        "nmjenis_penggunaan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmjenis_penggunaan,
                                        "tenant": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].tenant,
                                        "nmtenant": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].nmtenant,
                                        "tanggal_data": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].tanggal_data,
                                        "jumlahaset": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].jumlahaset,
                                        "catatan": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].catatan,
                                        "skor": this.tmpdatamaster[i].gedung[z].lantai[m].ruangan[p].skor,
                                    })
                                }
                            }
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            this.halamanloading = true;
            if (this.leveluser < 4) {
                kodelokasi = '';
            }
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLokasi?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        if (this.leveluser < 4) {
                            this.lokasi.unshift({
                                label: 'Semua Data',
                                code: 0,
                                tag: 0,
                            });
                        }
                        if (this.leveluser >= 4) {
                            this.defaultSelectedLokasi = {
                                code: this.lokasiuser,
                                label: this.nmlokasiuser,
                                tag: this.lokasiuser,
                            };
                            this.selectedlokas = this.lokasiuser;
                        }

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            const mainAPI5 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI5.get("minfsipil_oripro-GetBentukBangunan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databentuk_bangunan = [];
                        this.bentuk_bangunan = [];
                        this.databentuk_bangunan = Response.data.content.data;
                        this.databentuk_bangunan.forEach((item) => {
                            this.bentuk_bangunan.push({
                                label: item.nama,
                                code: this.databentuk_bangunan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.bentuk_bangunan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI6 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI6.get("minfsipil_oripro-GetStatusPenggunaan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatus_penggunaan = [];
                        this.status_penggunaan = [];
                        this.datastatus_penggunaan = Response.data.content.data;
                        this.datastatus_penggunaan.forEach((item) => {
                            this.status_penggunaan.push({
                                label: item.nama,
                                code: this.datastatus_penggunaan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.status_penggunaan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI7 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI7.get("minfsipil_oripro-GetStrukturBangunan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastruktur_atap = [];
                        this.struktur_atap = [];
                        this.datastruktur_atap = Response.data.content.data;
                        this.datastruktur_atap.forEach((item) => {
                            this.struktur_atap.push({
                                label: item.nama,
                                code: this.datastruktur_atap.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_atap.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.dataatap = [];
                        this.atap = [];
                        this.dataatap = Response.data.content.data;
                        this.dataatap.forEach((item) => {
                            this.atap.push({
                                label: item.nama,
                                code: this.dataatap.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.atap.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datastruktur_dinding = [];
                        this.struktur_dinding = [];
                        this.datastruktur_dinding = Response.data.content.data;
                        this.datastruktur_dinding.forEach((item) => {
                            this.struktur_dinding.push({
                                label: item.nama,
                                code: this.datastruktur_dinding.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_dinding.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datadinding = [];
                        this.dinding = [];
                        this.datadinding = Response.data.content.data;
                        this.datadinding.forEach((item) => {
                            this.dinding.push({
                                label: item.nama,
                                code: this.datadinding.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.dinding.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datastruktur_lantai = [];
                        this.struktur_lantai = [];
                        this.datastruktur_lantai = Response.data.content.data;
                        this.datastruktur_lantai.forEach((item) => {
                            this.struktur_lantai.push({
                                label: item.nama,
                                code: this.datastruktur_lantai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_lantai.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datalantai = [];
                        this.lantai = [];
                        this.datalantai = Response.data.content.data;
                        this.datalantai.forEach((item) => {
                            this.lantai.push({
                                label: item.nama,
                                code: this.datalantai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lantai.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datastruktur_pondasi = [];
                        this.struktur_pondasi = [];
                        this.datastruktur_pondasi = Response.data.content.data;
                        this.datastruktur_pondasi.forEach((item) => {
                            this.struktur_pondasi.push({
                                label: item.nama,
                                code: this.datastruktur_pondasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_pondasi.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datapondasi = [];
                        this.pondasi = [];
                        this.datapondasi = Response.data.content.data;
                        this.datapondasi.forEach((item) => {
                            this.pondasi.push({
                                label: item.nama,
                                code: this.datapondasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.pondasi.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI8 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI8.get("minfsipil_oripro-GetJenisPenggunaan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajenis_penggunaan = [];
                        this.jenis_penggunaan = [];
                        this.datajenis_penggunaan = Response.data.content.data;
                        this.datajenis_penggunaan.forEach((item) => {
                            this.jenis_penggunaan.push({
                                label: item.nama,
                                code: this.datajenis_penggunaan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenis_penggunaan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI9 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI9.get("datatenant_oripro-GetDataTenant?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatenant = [];
                        this.tenant = [];
                        this.datatenant = Response.data.content.data;
                        this.datatenant.forEach((item) => {
                            this.tenant.push({
                                label: item.nama,
                                code: this.datatenant.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tenant.unshift({
                            label: 'Tidak Ada',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI11 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI11.get("mb_oripro-GetKondisiBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakondisi = [];
                        this.kondisi = [];
                        this.datakondisi = Response.data.content.data;
                        this.datakondisi.forEach((item) => {
                            this.kondisi.push({
                                label: item.nama,
                                code: this.datakondisi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kondisi.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });


            const mainAPISifat = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPISifat.get("mb_oripro-GetSifatBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasifat_aset = [];
                        this.sifat_aset = [];
                        this.datasifat_aset = Response.data.content.data;
                        this.datasifat_aset.forEach((item) => {
                            this.sifat_aset.push({
                                label: item.nama,
                                code: this.datasifat_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.sifat_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIKlasifikasi = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIKlasifikasi.get("mb_oripro-GetKlasifikasiBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataklasifikasi_aset = [];
                        this.klasifikasi_aset = [];
                        this.dataklasifikasi_aset = Response.data.content.data;
                        this.dataklasifikasi_aset.forEach((item) => {
                            this.klasifikasi_aset.push({
                                label: item.nama,
                                code: this.dataklasifikasi_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.klasifikasi_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIKategori = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIKategori.get("mkb_oripro-GetKategoriBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakategori_aset = [];
                        this.kategori_aset = [];
                        this.datakategori_aset = Response.data.content.data;
                        this.datakategori_aset.forEach((item) => {
                            this.kategori_aset.push({
                                label: item.nama,
                                code: this.datakategori_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kategori_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIJenis = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIJenis.get("mjb_oripro-GetJenisBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajenis_aset = [];
                        this.jenis_aset = [];
                        this.datajenis_aset = Response.data.content.data;
                        this.datajenis_aset.forEach((item) => {
                            this.jenis_aset.push({
                                label: item.nama,
                                code: this.datajenis_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenis_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIMerk = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIMerk.get("mrk_oripro-GetMerkBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamerk_aset = [];
                        this.merk_aset = [];
                        this.datamerk_aset = Response.data.content.data;
                        this.datamerk_aset.forEach((item) => {
                            this.merk_aset.push({
                                label: item.nama,
                                code: this.datamerk_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.merk_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPITipe = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPITipe.get("mtpy_oripro-GetTipeBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatipe_aset = [];
                        this.tipe_aset = [];
                        this.datatipe_aset = Response.data.content.data;
                        this.datatipe_aset.forEach((item) => {
                            this.tipe_aset.push({
                                label: item.nama,
                                code: this.datatipe_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tipe_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIJenisPerawatan = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIJenisPerawatan.get("mprwt_oripro-GetJenisPerawatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datajenis_perawatan = [];
                        this.jenis_perawatan = [];
                        this.datajenis_perawatan = Response.data.content.data;
                        this.datajenis_perawatan.forEach((item) => {
                            this.jenis_perawatan.push({
                                label: item.nama,
                                code: this.datajenis_perawatan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.jenis_perawatan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIDurasiPerawatan = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDurasiPerawatan.get("mprwt_oripro-GetDurasiPerawatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datadurasi_perawatan = [];
                        this.durasi_perawatan = [];
                        this.datadurasi_perawatan = Response.data.content.data;
                        this.datadurasi_perawatan.forEach((item) => {
                            this.durasi_perawatan.push({
                                label: item.nama,
                                code: this.datadurasi_perawatan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.durasi_perawatan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIMetodePerawatan = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIMetodePerawatan.get("mprwt_oripro-GetMetodePerawatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datametode_perawatan = [];
                        this.metode_perawatan = [];
                        this.datametode_perawatan = Response.data.content.data;
                        this.datametode_perawatan.forEach((item) => {
                            this.metode_perawatan.push({
                                label: item.nama,
                                code: this.datametode_perawatan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.metode_perawatan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPIAlatPerawatan = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIAlatPerawatan.get("mprwt_oripro-GetAlatPerawatan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataalat_perawatan = [];
                        this.alat_perawatan = [];
                        this.dataalat_perawatan = Response.data.content.data;
                        this.dataalat_perawatan.forEach((item) => {
                            this.alat_perawatan.push({
                                label: item.nama,
                                code: this.dataalat_perawatan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.alat_perawatan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        prosesaset(nama, kdlokasi, kdgedung, kdlantai, kdruangan) {
            this.namadetailaset = nama;
            this.kdlokasibarang = kdlokasi;
            this.kdgedungbarang = kdgedung;
            this.kdlantaibarang = kdlantai;
            this.kdruanganbarang = kdruangan;

            const mainAPIAset = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPIAset.get("dataaset_oripro-GetDataAset?kdlokasi=" + kdlokasi + "&kdgedung=" + kdgedung + "&kdlantai=" + kdlantai + "&kdruangan=" + kdruangan + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    this.koodinatsetujus = [];
                    this.tempdataaset = [];
                    if (this.datapesan == 'data diketemukan') {
                        this.tempdataaset = Response.data.content.data;
                        this.tempdataaset.forEach((item) => {
                            this.koodinatsetujus.push({
                                previous: '',
                                expiration: '',
                                id: item.id,
                                lokasi: item.lokasi,
                                gedung: item.gedung,
                                kd_lantai: item.kd_lantai,
                                ruangan: item.ruangan,
                                sifat_aset: item.sifat_aset,
                                klasifikasi_aset: item.klasifikasi_aset,
                                kategori_aset: item.kategori_aset,
                                jenis_aset: item.jenis_aset,
                                merk_aset: item.merk_aset,
                                tipe_aset: item.tipe_aset,
                                jenis_perawatan: item.jenis_perawatan,
                                durasi_perawatan: item.durasi_perawatan,
                                metode_perawatan: item.metode_perawatan,
                                alat_perawatan: item.alat_perawatan,
                                jam_mulai: item.jam_mulai,
                                jam_selesai: item.jam_selesai,
                                latitude: item.latitude,
                                longitude: item.longitude,
                                filenya_aset: item.filenya_aset,
                                pj_aset: item.pj_aset,
                            })
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI11 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI11.get("m_peg_oripro-GetPegawaiNonSuperAdmin?kdlokasi=" + kdlokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapj_aset = [];
                        this.pj_aset = [];
                        this.datapj_aset = Response.data.content.data;
                        this.datapj_aset.forEach((item) => {
                            this.pj_aset.push({
                                label: item.nama,
                                code: this.datapj_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.pj_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datapj_aset_barang = [];
                        this.pj_aset_barang = [];
                        this.datapj_aset_barang = Response.data.content.data;
                        this.datapj_aset_barang.forEach((item) => {
                            this.pj_aset_barang.push({
                                label: item.nama,
                                code: item.id,
                                tag: item.id,
                            });
                        });
                        this.pj_aset_barang.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            $('#costumModaldetailaset').modal('show');
        },
        prosesdata(kdlokasi, kdgedung, kdlantai, kdruangan, id_tabel_data_ruangan, nama, panjang, lebar, tinggi, luas_m2, bentuk_bangunan, nmbentuk_bangunan
            , status_penggunaan, nmstatus_penggunaan, struktur_atap, nmstruktur_atap, atap, nmatap
            , struktur_dinding, nmstruktur_dinding, dinding, nmdinding
            , struktur_lantai, nmstruktur_lantai, lantai, nmlantai
            , struktur_pondasi, nmstruktur_pondasi, pondasi, nmpondasi
            , kondisi, nmkondisi, pj_aset, nmpj_aset, jenis_penggunaan, nmjenis_penggunaan, tenant, nmtenant
            , tanggal_data, catatan) {
            this.kdlokasi = kdlokasi;
            this.kdlantai = kdlantai;
            this.kdruangan = kdruangan;
            this.kdgedung = kdgedung;
            this.id_tabel_data_ruangan = id_tabel_data_ruangan;
            this.nama = nama;
            this.panjang = panjang;
            this.lebar = lebar;
            this.tinggi = tinggi;
            this.luas_m2 = luas_m2;
            this.defaultSelectedbentuk_bangunan = {
                code: bentuk_bangunan,
                label: nmbentuk_bangunan,
                tag: bentuk_bangunan,
            };
            this.selectedbentuk_bangunan = bentuk_bangunan;

            this.defaultSelectedstatus_penggunaan = {
                code: status_penggunaan,
                label: nmstatus_penggunaan,
                tag: status_penggunaan,
            };
            this.selectedstatus_penggunaan = status_penggunaan;

            this.defaultSelectedstruktur_atap = {
                code: struktur_atap,
                label: nmstruktur_atap,
                tag: struktur_atap,
            };
            this.selectedstruktur_atap = struktur_atap;

            this.defaultSelectedatap = {
                code: atap,
                label: nmatap,
                tag: atap,
            };
            this.selectedatap = atap;

            this.defaultSelectedstruktur_dinding = {
                code: struktur_dinding,
                label: nmstruktur_dinding,
                tag: struktur_dinding,
            };
            this.selectedstruktur_dinding = struktur_dinding;

            this.defaultSelecteddinding = {
                code: dinding,
                label: nmdinding,
                tag: dinding,
            };
            this.selecteddinding = dinding;

            this.defaultSelectedstruktur_lantai = {
                code: struktur_lantai,
                label: nmstruktur_lantai,
                tag: struktur_lantai,
            };
            this.selectedstruktur_lantai = struktur_lantai;

            this.defaultSelectedlantai = {
                code: lantai,
                label: nmlantai,
                tag: lantai,
            };
            this.selectedlantai = lantai;

            this.defaultSelectedstruktur_pondasi = {
                code: struktur_pondasi,
                label: nmstruktur_pondasi,
                tag: struktur_pondasi,
            };
            this.selectedstruktur_pondasi = struktur_pondasi;

            this.defaultSelectedpondasi = {
                code: pondasi,
                label: nmpondasi,
                tag: pondasi,
            };
            this.selectedpondasi = pondasi;

            this.defaultSelectedkondisi = {
                code: kondisi,
                label: nmkondisi,
                tag: kondisi,
            };
            this.selectedkondisi = kondisi;

            this.defaultSelectedjenis_penggunaan = {
                code: jenis_penggunaan,
                label: nmjenis_penggunaan,
                tag: jenis_penggunaan,
            };
            this.selectedjenis_penggunaan = jenis_penggunaan;

            this.defaultSelectedtenant = {
                code: tenant,
                label: nmtenant,
                tag: tenant,
            };
            this.selectedtenant = tenant;

            const mainAPI11 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI11.get("m_peg_oripro-GetPegawaiNonSuperAdmin?kdlokasi=" + kdlokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapj_aset = [];
                        this.pj_aset = [];
                        this.datapj_aset = Response.data.content.data;
                        this.datapj_aset.forEach((item) => {
                            this.pj_aset.push({
                                label: item.nama,
                                code: this.datapj_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.pj_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.defaultSelectedpj_aset = {
                code: pj_aset,
                label: nmpj_aset,
                tag: pj_aset,
            };
            this.selectedpj_aset = pj_aset;

            this.tanggal_data = tanggal_data;
            this.catatan = catatan;

            $('#costumModaldetail').modal('show');
        },
        validasidata() {
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if ((document.getElementById('panjang').value == '') || (document.getElementById('panjang').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('panjang').focus();
                return false;
            }
            if ((document.getElementById('lebar').value == '') || (document.getElementById('lebar').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('lebar').focus();
                return false;
            }
            if ((document.getElementById('tinggi').value == '') || (document.getElementById('tinggi').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tinggi').focus();
                return false;
            }
            if ((document.getElementById('luas_m2').value == '') || (document.getElementById('luas_m2').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('luas_m2').focus();
                return false;
            }
            if ((this.selectedbentuk_bangunan === undefined) || (this.selectedbentuk_bangunan == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bentuk Ruangan',
                });
                return false;
            }
            if ((this.selectedstatus_penggunaan === undefined) || (this.selectedstatus_penggunaan == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Status Penggunaan Ruangan',
                });
                return false;
            }
            if ((this.selectedstruktur_atap === undefined) || (this.selectedstruktur_atap == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Atap',
                });
                return false;
            }
            if ((this.selectedatap === undefined) || (this.selectedatap == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Atap Ruangan',
                });
                return false;
            }
            if ((this.selectedstruktur_dinding === undefined) || (this.selectedstruktur_dinding == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Dinding',
                });
                return false;
            }
            if ((this.selecteddinding === undefined) || (this.selecteddinding == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Dinding Ruangan',
                });
                return false;
            }
            if ((this.selectedstruktur_lantai === undefined) || (this.selectedstruktur_lantai == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Ruangan',
                });
                return false;
            }
            if ((this.selectedlantai === undefined) || (this.selectedlantai == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Ruangan Ruangan',
                });
                return false;
            }
            if ((this.selectedstruktur_pondasi === undefined) || (this.selectedstruktur_pondasi == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Pondasi',
                });
                return false;
            }
            if ((this.selectedpondasi === undefined) || (this.selectedpondasi == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Pondasi Ruangan',
                });
                return false;
            }
            if ((this.selectedkondisi === undefined) || (this.selectedkondisi == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kondisi Ruangan',
                });
                return false;
            }
            if ((this.selectedjenis_penggunaan === undefined) || (this.selectedjenis_penggunaan == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Jenis Penggunaan Ruangan',
                });
                return false;
            }
            if ((this.selectedpj_aset === undefined) || (this.selectedpj_aset == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Penanggungjawab Ruangan',
                });
                return false;
            }

            if (document.getElementById('tanggal_data').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Tanggal Data',
                });
                document.getElementById('tanggal_data').focus();
                return false;
            }
            this.validasi = true;
            return;
        },
        simpandata() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }


            var fd = new FormData();
            fd.append("id", this.id_tabel_data_ruangan);
            fd.append("lokasi", this.kdlokasi);
            fd.append("gedung", this.kdgedung);
            fd.append("kd_lantai", this.kdlantai);
            fd.append("ruangan", this.kdruangan);
            fd.append("id_tabel_data_ruangan", this.id_tabel_data_ruangan);
            fd.append("nama", this.nama);
            fd.append("panjang", this.panjang.toString().replaceAll(",", "."));
            fd.append("lebar", this.lebar.toString().replaceAll(",", "."));
            fd.append("tinggi", this.tinggi.toString().replaceAll(",", "."));
            fd.append("luas_m2", this.luas_m2.toString().replaceAll(",", "."));
            fd.append("bentuk_bangunan", this.selectedbentuk_bangunan);
            fd.append("status_penggunaan", this.selectedstatus_penggunaan);
            fd.append("struktur_atap", this.selectedstruktur_atap);
            fd.append("atap", this.selectedatap);
            fd.append("struktur_dinding", this.selectedstruktur_dinding);
            fd.append("dinding", this.selecteddinding);
            fd.append("struktur_lantai", this.selectedstruktur_lantai);
            fd.append("lantai", this.selectedlantai);
            fd.append("struktur_pondasi", this.selectedstruktur_pondasi);
            fd.append("pondasi", this.selectedpondasi);
            fd.append("kondisi", this.selectedkondisi);
            fd.append("pj_aset", this.selectedpj_aset);
            fd.append("jenis_penggunaan", this.selectedjenis_penggunaan);
            fd.append("tenant", this.selectedtenant);
            fd.append("tanggal_data", this.tanggal_data);
            fd.append("catatan", this.catatan);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));

            if (this.id_tabel_data_ruangan != '') {
                swal.fire({
                    title: "Konfirmasi",
                    text: "Data sudah ada, simpan sebagai history data?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Update Data",
                    confirmButtonColor: "#33bff2",
                    cancelButtonColor: "#6566aa",
                    confirmButtonText: "Simpan History Data"
                }).then((result) => {
                    if (result.isConfirmed) {
                        const mainAPI = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                "Content-Type": "form-data",
                                Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            },
                        });
                        mainAPI.post("dataruangan_oripro-AddDataRuangan", fd).then(
                            Response => {
                                if (Response.data.response == 'error') {
                                    swal.fire('Peringatan', Response.data.message, 'error');
                                    $('.swal2-container').css("z-index", '999999');
                                }
                                else {
                                    swal.fire('Informasi', Response.data.message, 'success').then(function () {
                                        window.location.replace("/DataRuangan");
                                    });
                                    $('.swal2-container').css("z-index", '999999');
                                }
                            }
                        ).catch(function (error) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: error
                            });
                        }
                        )
                    }
                    else {
                        const mainAPI = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                "Content-Type": "form-data",
                                Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            },
                        });
                        mainAPI.post("dataruangan_oripro-UpdateDataRuangan", fd).then(
                            Response => {
                                if (Response.data.response == 'error') {
                                    swal.fire('Peringatan', Response.data.message, 'error');
                                    $('.swal2-container').css("z-index", '999999');
                                }
                                else {
                                    swal.fire('Informasi', Response.data.message, 'success').then(function () {
                                        window.location.replace("/DataRuangan");
                                    });
                                    $('.swal2-container').css("z-index", '999999');
                                }
                            }
                        ).catch(function (error) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: error
                            });
                        }
                        )
                    }
                });
                $('.swal2-container').css("z-index", '999999');
            } else {
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("dataruangan_oripro-AddDataRuangan", fd).then(
                    Response => {
                        if (Response.data.response == 'error') {
                            swal.fire('Peringatan', Response.data.message, 'error');
                            $('.swal2-container').css("z-index", '999999');
                        }
                        else {
                            swal.fire('Informasi', Response.data.message, 'success').then(function () {
                                window.location.replace("/DataRuangan");
                            });
                            $('.swal2-container').css("z-index", '999999');
                        }
                    }
                ).catch(function (error) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error
                    });
                }
                )
            }
            return false;
        },
    },
    mounted() {
        this.initializeSelect2();
        this.selectedbidang = JSON.parse(localStorage.getItem("bdlsistem"));
        this.selectedsubbidang = JSON.parse(localStorage.getItem("sbdsistem"));
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>