<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Detail Kinerja Perawatan Fungsi Aset {{ namalokasi }}</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/KinerjaAset">Infografis Aset</a></li>
                                <li class="breadcrumb-item active">Detail Kinerja Perawatan Fungsi</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardbiru">
                                <div class="card-header border-transparent">
                                    <div class="row">
                                        <div class="col-sm-11">
                                            <input type="text" class="form-control" placeholder="Pencarian Gedung"
                                                v-model="carikata">
                                        </div>
                                        <div class="col-sm-1 text-center">
                                            <div class="input-group-append text-center">
                                                <div class="btn btn-primary">
                                                    <i class="fas fa-search"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body p-3">

                                    </div>
                                    <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                        <div class="card collapsed-card">
                                            <div class="cardgray card-header border-transparent">
                                                {{ urutlist + 1 }}. {{ datalist.kode }} - {{ datalist.nama }} <span
                                                    :style="'color:' + datalist.warnakondisiskor_fungsi">
                                                    (SKOR:
                                                    {{ G_numKoma(datalist.skor_fungsi) }}
                                                    <span
                                                        :class="[(datalist.nama_kondisiskor_fungsi == 'belum ada data' ? 'badge badge-danger' : 'badge badge-secondary')]"
                                                        :style="[(datalist.nama_kondisiskor_fungsi == 'belum ada data' ? 'color: ' + datalist.warnakondisiskor_fungsi : 'color: ' + datalist.warnakondisiskor_fungsi)]">{{
                                                            datalist.nama_kondisiskor_fungsi }}
                                                    </span>
                                                    )
                                                </span>
                                                <div class="card-tools">
                                                    <button type="button" class="btn btn-tool"
                                                        data-card-widget="collapse"><i
                                                            class="fas fa-plus text-white"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="card-body p-3">
                                                <div class="row">
                                                    <template
                                                        v-for="(datalistkategori, urutlistkategori) in datalist.kategori"
                                                        :key="urutlistkategori">
                                                        <div class="col-lg-6">
                                                            <div class="card">
                                                                <div class="card-header border-0">
                                                                    <div class="d-flex justify-content-between">
                                                                        <h3 class="card-title">{{ datalistkategori.nama
                                                                            }}
                                                                        </h3>
                                                                        <a href="javascript:void(0);">
                                                                            <span
                                                                                :style="'color:' + datalistkategori.warnakondisiskor_fungsi">
                                                                                (SKOR:
                                                                                {{
                                                                                    G_numKoma(datalistkategori.skor_fungsi)
                                                                                }}
                                                                                <span
                                                                                    :class="[(datalistkategori.nama_kondisiskor_fungsi == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]"
                                                                                    :style="[(datalistkategori.nama_kondisiskor_fungsi == 'belum ada data' ? 'color: ' + datalistkategori.warnakondisiskor_fungsi : 'color: ' + datalistkategori.warnakondisiskor_fungsi)]">{{
                                                                                        datalistkategori.nama_kondisiskor_fungsi
                                                                                    }}
                                                                                </span>
                                                                                )
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                <div class="card-body">
                                                                    <template
                                                                        v-for="(datalistaset, urutlistaset) in datalistkategori.data_aset"
                                                                        :key="urutlistaset">
                                                                        <div
                                                                            class="d-flex justify-content-between align-items-center border-bottom mb-2">
                                                                            <img :src="folder_fotoaset + datalistaset.filenya_aset"
                                                                                class="img-size-75 d-flex flex-column text-left animation__wobble"
                                                                                alt="Gedung Image"
                                                                                style="height: 100px;">
                                                                            <p class="d-flex flex-column text-left">
                                                                                <span class="text-muted">{{
                                                                                    datalistaset.nmjenis_aset }} {{
                                                                                        datalistaset.nmruangan }}</span>
                                                                            </p>
                                                                            <p class="d-flex flex-column text-right">
                                                                                <span
                                                                                    :style="'color:' + datalistaset.warnakondisiskor_fungsi">
                                                                                    (SKOR:
                                                                                    {{
                                                                                        G_numKoma(datalistaset.skor_fungsi)
                                                                                    }}
                                                                                    <span
                                                                                        :class="[(datalistaset.nama_kondisiskor_fungsi == 'belum ada data' ? 'badge badge-danger' : 'badge badge-default')]"
                                                                                        :style="[(datalistaset.nama_kondisiskor_fungsi == 'belum ada data' ? 'color: ' + datalistaset.warnakondisiskor_fungsi : 'color: ' + datalistaset.warnakondisiskor_fungsi)]">{{
                                                                                            datalistaset.nama_kondisiskor_fungsi
                                                                                        }}
                                                                                    </span>
                                                                                    )
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div class="card-footer clearfix text-center">
                                    <button type="button" class="btn btn-sm btn-primary float-left text-white lebar"
                                        @click="Kembali()"><i class="fas fa-reply"></i> Kembali</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu
    },
    data() {
        return {
            halamanloading: true,
            namalokasi: '',
            iduser: JSON.parse(localStorage.getItem("usistem")),
            lokasiluser: JSON.parse(localStorage.getItem("loksistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
            datamaster: [],
            datapesanmaster: [],
            carikata: '',
            folder: process.env.VUE_APP_URL_API + 'view_oripro-GambarGedung?f=',
            folderlantai: process.env.VUE_APP_URL_API + 'view_oripro-GambarLantai?f=',
            folderruangan: process.env.VUE_APP_URL_API + 'view_oripro-GambarRuangan?f=',
            folder_fotoaset: process.env.VUE_APP_URL_API + 'dataaset_oripro-GambarAset?f=',
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        Kembali() {
            window.location.replace("/KinerjaAset/");
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async AmbilData() {
            this.halamanloading = true;
            this.datamaster = [];
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("m_oripro-GetbyIDLokasi?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.namalokasi = Response.data.content.data[0].nama;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
            var random = Math.random();
            await mainAPI.get("kinaset_oripro-GetKinerjaFisikbyIDLokasi?kdlokasi=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
        },

    },
    mounted() {
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>