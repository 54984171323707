<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Detail Kinerja Pegawai</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/KinerjaPersonil">Infografis Pegawai</a></li>
                                <li class="breadcrumb-item active">Detail Kinerja Pegawai</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardhijau">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-11 float-left pb-2">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                        <tbody>
                                            <tr v-if="urutlist == 0">
                                                <td class="text-center">
                                                    <img :src="folder_foto + datalist.foto"
                                                        class="img-size-75 img-circle animation__wobble"
                                                        alt="User Image" style="height: 100px;">
                                                </td>
                                                <td colspan="4">
                                                    {{ datalist.nip }}<br>
                                                    <span class="text-primary">{{ datalist.nama }}</span><br>
                                                    <span class="text-info">{{ datalist.namajabatan
                                                        }}</span><br>
                                                    <span class="text-primary">{{ datalist.namabidang
                                                        }}</span><br>
                                                    {{ datalist.namasubbidang }}<br>
                                                    <span class="badge badge-mulai">Bergabung: {{
                                                        G_formatDate_Tanpa_WIB(datalist.tgl_gabung)
                                                        }}</span><br>
                                                    <span class="badge badge-info">{{
                                                        G_formatDate_Tanpa_WIB(datalist.tgl_kontrak_mulai)
                                                        }}</span> s/d
                                                    <span
                                                        :class="[(Date.parse(datalist.tgl_kontrak_selesai) >= tanggalsetahundarisekarang ? 'badge badge-success'
                                                            : Date.parse(datalist.tgl_kontrak_selesai) >= tanggaltigabulandarisekarang ? 'badge badge-warning' : 'badge badge-danger')]">
                                                        {{ G_formatDate_Tanpa_WIB(datalist.tgl_kontrak_selesai)
                                                        }}
                                                    </span><br>
                                                    <span class="text-info">{{ datalist.namalevel
                                                        }}</span> {{ datalist.namalokasi }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;">No</th>
                                                    <th style="width: 200px;">Aset Yang Dirawat<br>/ Tindakan<br>/
                                                        Jadwal Perawatan</th>
                                                    <th style="width: 200px;">
                                                        Tugas / Status Hasil Tindakan / Hasil Kondisi Aset / Hasil
                                                        Fungsi Aset
                                                    </th>
                                                    <th style="width: 200px;">Jam Selesai Perawatan<br> / Status
                                                        Keterlambatan Perawatan / Posisi Perawatan</th>
                                                    <th style="width: 100px;">Skoring</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td>
                                                            {{ datalist.nmlokasi }} {{ datalist.nmgedung }} {{
                                                                datalist.nmlantai }}<br>
                                                            <span class="text-info">{{ datalist.nmruangan }}</span><br>
                                                            <span class="text-primary">
                                                                {{ datalist.nmmetode_perawatan }}
                                                                {{ datalist.nmjenis_aset }}
                                                                {{ datalist.nmjenis_perawatan }}
                                                                {{ datalist.nmdurasi_perawatan }}
                                                            </span><br>
                                                            <span class="text-success">
                                                                {{ G_formatDateNonWIB(datalist.tanggaljadwal) }}
                                                                {{ datalist.jamjadwalmulai }} s/d {{
                                                                    datalist.jamjadwalselesai }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {{ datalist.status_pengganti }}<br>
                                                            <span class="text-info">
                                                                {{ datalist.namastatusselesai }}<br>
                                                            </span>
                                                            <span class="text-primary">
                                                                {{ datalist.namakondisi }}<br>
                                                            </span>
                                                            <span class="text-success">
                                                                {{ datalist.namafungsi }}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span class="text-success">
                                                                {{ G_formatDate_Tanpa_WIB(datalist.tanggalperawatan) }}
                                                                {{ datalist.jamjadwalselesaitugas }}<br>
                                                            </span>
                                                            <span class="text-info">
                                                                {{ datalist.namahitungwaktu }}<br>
                                                            </span>
                                                            <span class="text-primary">
                                                                {{ datalist.namajarak }}
                                                            </span>
                                                        </td>
                                                        <td class="text-center">
                                                            <span :style="'color:' + datalist.vervalwarnanilai">{{
                                                                G_numKoma(datalist.vervalskorakhir) }}</span><br>
                                                            <span :style="'color:' + datalist.vervalwarnanilai">{{
                                                                datalist.vervalnamaskorakhir }}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div class="col-md-12 text-center" v-if="(datapesanmaster == 'data kosong')">
                                            Data
                                            Masih Kosong</div>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    Jumlah Data: {{ G_numFormat(JumlahfilteredKataData) }}
                                    <button type="button" class="btn btn-sm btn-primary float-right text-white lebar"
                                        @click="Kembali()"><i class="fas fa-reply"></i> Kembali</button>
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import CryptoJS from 'crypto-js';
import { inject } from 'vue';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import swal from 'sweetalert2';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            carikata: '',
            datamaster: [],
            datapesan: [],
            datapesanmaster: [],
            folder_foto: process.env.VUE_APP_URL_API + 'view_oripro-GambarUser?f=',
            tanggalsetahundarisekarang: Date.parse(new Date()) + (365 * 24 * 60 * 60 * 1000),
            tanggaltigabulandarisekarang: Date.parse(new Date()) + (90 * 24 * 60 * 60 * 1000),

            bidang: [],
            databidang: [],
            defaultSelectedBidang: {
                code: 0,
                label: 'Semua Data',
            },
            selectedbidang: 0,
            subbidang: [],
            datasubbidang: [],
            defaultSelectedSubBidang: {
                code: 0,
                label: 'Semua Data',
            },
            selectedsubbidang: 0,
            kdorganisasi: JSON.parse(localStorage.getItem("orsistem")),

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        Kembali() {
            window.location.replace("/KinerjaPersonil/");
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async AmbilData() {
            this.halamanloading = true;
            this.datamaster = [];
            var kodebidang = '';
            var kodesubbidang = '';
            if ((this.selectedbidang != 0) && (this.selectedbidang != undefined)) {
                kodebidang = this.selectedbidang;
            }
            if ((this.selectedsubbidang != 0) && (this.selectedsubbidang != undefined)) {
                kodesubbidang = this.selectedsubbidang;
            }
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("kinpeg_oripro-GetDetailKinerjaPegawai?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
                //window.location.replace("/");
            })
        },
    },
    mounted() {
        this.selectedbidang = JSON.parse(localStorage.getItem("bdlsistem"));
        this.selectedsubbidang = JSON.parse(localStorage.getItem("sbdsistem"));
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>