<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Pengaturan Jadwal Pegawai</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Jadwal Pegawai</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="col-sm-12">&nbsp;</div>
                                <div class="col-sm-12">
                                    <div class="card cardinfo">
                                        <div class="cardinfo card-header border-transparent">
                                            Filter Data
                                            <div class="card-tools">
                                                <button type="button" class="btn btn-tool"
                                                    data-card-widget="collapse"><i class="fas fa-minus text-white"></i>
                                                </button>
                                            </div>
                                            <button type="button" class="btn btn-tool" id="kosong" @click="datakosong()"
                                                style="display: none;"><i class="fas fa-minus text-white"></i></button>
                                            <button type="button" class="btn btn-tool" id="sukses"
                                                @click="datatersimpan()" style="display: none;"><i
                                                    class="fas fa-minus text-white"></i></button>
                                            <button type="button" class="btn btn-tool" id="eror" @click="dataeror()"
                                                style="display: none;"><i class="fas fa-minus text-white"></i></button>
                                        </div>
                                        <div class="card-body p-3">
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <label>Divisi / Bidang</label>
                                                    <v-select :options="bidang" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedBidang"
                                                        @update:modelValue="onbidangChange"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Unit / SubBidang</label>
                                                    <v-select :options="subbidang" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedSubBidang"
                                                        @update:modelValue="onsubbidangChange"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Lokasi</label>
                                                    <v-select :options="lokasi" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedLokasi"
                                                        @update:modelValue="onlokasiChange"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Gedung Area Aset</label>
                                                    <v-select :options="gedung" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedGedung"
                                                        @update:modelValue="ongedungChange"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Lantai Area Aset</label>
                                                    <v-select :options="lantai" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedLantai"
                                                        @update:modelValue="onlantaiChange"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Ruangan Area Aset</label>
                                                    <v-select :options="ruangan" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedruangan"
                                                        @update:modelValue="onruanganChange"></v-select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Pegawai</label>
                                                    <v-select :options="pegawai" :reduce="(label) => label.code"
                                                        label="label" v-model="defaultSelectedpegawai"
                                                        @update:modelValue="onpegawaiChange"></v-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Add the bg color to the header using any of the bg-* classes -->
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Manajemen Aktivitas</h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <iframe :src="sourcenya" class="col-sm-12 text-center" style="height: 130vh;"
                                        frameborder="0" scrolling="no"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, swal, vSelect
    },
    data() {
        return {
            halamanloading: true,
            linknya: process.env.VUE_APP_URL_API,
            sourcenya: './calendar.html',
            datamaster: [],
            datapesan: [],
            datapesanmaster: [],
            bidang: [],
            databidang: [],
            defaultSelectedBidang: {
                code: 0,
                label: 'Semua Data',
            },
            selectedbidang: 0,
            subbidang: [],
            datasubbidang: [],
            defaultSelectedSubBidang: {
                code: 0,
                label: 'Semua Data',
            },
            selectedsubbidang: 0,
            kdorganisasi: JSON.parse(localStorage.getItem("orsistem")),

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,

            gedung: [],
            datagedung: [],
            defaultSelectedGedung: {
                code: 0,
                label: 'Semua Data',
            },
            selectedgedung: 0,

            lantai: [],
            datalantai: [],
            defaultSelectedLantai: {
                code: 0,
                label: 'Semua Data',
            },
            selectedlantai: 0,

            ruangan: [],
            dataruangan: [],
            defaultSelectedruangan: {
                code: 0,
                label: 'Semua Data',
            },
            selectedruangan: 0,

            pegawai: [],
            datapegawai: [],
            defaultSelectedpegawai: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpegawai: 0,

            leveluser: JSON.parse(localStorage.getItem("lsistem")),

            kdeorganisasi: JSON.parse(localStorage.getItem("orsistem")),
            nmkdorganisasi: JSON.parse(localStorage.getItem("nmorsistem")),
            kdebidang: JSON.parse(localStorage.getItem("bdlsistem")),
            nmkdbidang: JSON.parse(localStorage.getItem("nmbdlsistem")),
            kdesubbidang: JSON.parse(localStorage.getItem("sbdsistem")),
            nmkdsubbidang: JSON.parse(localStorage.getItem("nmsbdsistem")),
        }
    },
    methods: {
        onpegawaiChange(a) {
            try {
                this.selectedpegawai = this.pegawai[a].tag;
                this.lihataktivitas(this.selectedlokasi, this.selectedbidang, this.selectedsubbidang, this.selectedpegawai, this.selectedruangan)
            }
            catch (err) {
                return;
            }
        },
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
                this.defaultSelectedGedung = {
                    code: 0,
                    label: 'Semua Data',
                };
                this.selectedgedung = 0;
                this.AmbilGedungTerpilih(this.selectedlokasi);
                this.defaultSelectedLantai = {
                    code: 0,
                    label: 'Semua Data',
                };
                this.selectedlantai = 0;
                this.AmbilLantaiTerpilih(this.selectedlokasi, this.selectedgedung);
                this.defaultSelectedruangan = {
                    code: 0,
                    label: 'Semua Data',
                };
                this.selectedruangan = 0;
                this.AmbilRuanganTerpilih(this.selectedlokasi, this.selectedgedung, this.selectedlantai);
                this.datapegawai = [];
                this.pegawai = [];
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        onlantaiChange(a) {
            try {
                this.selectedlantai = this.lantai[a].tag;
                this.defaultSelectedruangan = {
                    code: 0,
                    label: 'Semua Data',
                };
                this.selectedruangan = 0;
                this.AmbilRuanganTerpilih(this.selectedlokasi, this.selectedgedung, this.selectedlantai);
                this.datapegawai = [];
                this.pegawai = [];
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        onruanganChange(a) {
            try {
                this.selectedruangan = this.ruangan[a].tag;
                this.datapegawai = [];
                this.pegawai = [];
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        ongedungChange(a) {
            try {
                this.selectedgedung = this.gedung[a].tag;
                this.defaultSelectedLantai = {
                    code: 0,
                    label: 'Semua Data',
                };
                this.selectedlantai = 0;
                this.AmbilLantaiTerpilih(this.selectedlokasi, this.selectedgedung);
                this.defaultSelectedruangan = {
                    code: 0,
                    label: 'Semua Data',
                };
                this.selectedruangan = 0;
                this.AmbilRuanganTerpilih(this.selectedlokasi, this.selectedgedung, this.selectedlantai);
                this.datapegawai = [];
                this.pegawai = [];
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        AmbilLantaiTerpilih(kdlokasi, kdgedung) {
            if (kdlokasi === undefined) {
                var kode = '';
            }
            else {
                var kode = kdlokasi;
            }

            if (kdgedung === undefined) {
                var kodegedung = '';
            }
            else {
                var kodegedung = kdgedung;
            }

            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI2.get("m_oripro-GetLantai?kdlokasi=" + kode + "&kdgedung=" + kodegedung + "&random=" + random).then(
                Response => {
                    this.datalantai = [];
                    this.lantai = [];
                    var datapesan = Response.data.message;
                    if (datapesan == 'data diketemukan') {
                        this.datalantai = Response.data.content.data;
                        this.datalantai.forEach((item) => {
                            this.lantai.push({
                                label: item.nama,
                                code: this.datalantai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                    }

                    this.lantai.unshift({
                        label: 'Semua Data',
                        code: '0',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        AmbilRuanganTerpilih(kdlokasi, kdgedung, kdlantai) {
            if (kdlokasi === undefined) {
                var kode = '';
            }
            else {
                var kode = kdlokasi;
            }

            if (kdgedung === undefined) {
                var kodegedung = '';
            }
            else {
                var kodegedung = kdgedung;
            }

            if (kdlantai === undefined) {
                var kodelantai = '';
            }
            else {
                var kodelantai = kdlantai;
            }

            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI2.get("m_oripro-GetRuangan?kdlokasi=" + kode + "&kdgedung=" + kodegedung + "&kdlantai=" + kodelantai + "&random=" + random).then(
                Response => {
                    this.dataruangan = [];
                    this.ruangan = [];
                    var datapesan = Response.data.message;
                    if (datapesan == 'data diketemukan') {
                        this.dataruangan = Response.data.content.data;
                        this.dataruangan.forEach((item) => {
                            this.ruangan.push({
                                label: item.nama,
                                code: this.dataruangan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                    }

                    this.ruangan.unshift({
                        label: 'Semua Data',
                        code: '0',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        async AmbilLokasiTerpilih() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("m_oripro-GetLokasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lokasi.unshift({
                            label: 'Semua Data',
                            code: '0',
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        AmbilGedungTerpilih(kdlokasi) {
            if (kdlokasi === undefined) {
                var kode = '';
            }
            else {
                var kode = kdlokasi;
            }

            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI2.get("m_oripro-GetGedung?kdlokasi=" + kode + "&random=" + random).then(
                Response => {
                    this.datagedung = [];
                    this.gedung = [];
                    var datapesan = Response.data.message;
                    if (datapesan == 'data diketemukan') {
                        this.datagedung = Response.data.content.data;
                        this.datagedung.forEach((item) => {
                            this.gedung.push({
                                label: item.nama,
                                code: this.datagedung.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                    }

                    this.gedung.unshift({
                        label: 'Semua Data',
                        code: '0',
                    });
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        async AmbilDataBidangTerpilih() {
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });

            var kdorganisasi = this.kdorganisasi;
            if (kdorganisasi == 0) {
                kdorganisasi = '';
            }

            var kode_bidang = '';
            if (this.leveluser >= 4) {
                kode_bidang = this.kdebidang;
            }
            var random = Math.random();
            await mainAPI2.get("morg_oripro-GetBidangInstitusi?kdorganisasi=" + kdorganisasi + "&kd=" + kode_bidang + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databidang = [];
                        this.bidang = [];
                        this.databidang = Response.data.content.data;
                        this.databidang.forEach((item) => {
                            this.bidang.push({
                                label: item.nama,
                                code: this.databidang.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        if (this.leveluser < 4) {
                            this.bidang.unshift({
                                label: 'Semua Data',
                                code: '0',
                            });
                        }
                        if (this.leveluser >= 4) {
                            this.defaultSelectedBidang = {
                                code: this.kdebidang,
                                label: this.nmkdbidang,
                                tag: this.kdebidang,
                            };
                            this.selectedbidang = this.kdebidang;
                        }
                    }
                    if (this.leveluser < 4) {
                        this.AmbilDataSubBidangTerpilih('');
                    }
                    if (this.leveluser >= 4) {
                        this.AmbilDataSubBidangTerpilih(this.selectedbidang);
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
            this.halamanloading = true;
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLokasi?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lokasi.unshift({
                            label: 'Semua Data',
                            code: 0,
                            tag: 0,
                        });
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        onbidangChange(a) {
            try {
                this.selectedbidang = this.bidang[a].tag;
                this.AmbilDataSubBidangTerpilih(this.selectedbidang);
                this.datapegawai = [];
                this.pegawai = [];
                this.AmbilData();

            }
            catch (err) {
                return;
            }
        },
        async AmbilDataSubBidangTerpilih(kdbidang) {
            var kode_subbidang = '';
            var kdorganisasi = this.kdorganisasi;
            if (kdorganisasi == 0) {
                kdorganisasi = '';
            }

            if (kdbidang === undefined) {
                var kodebidang = '';
            }
            else if (kdbidang == 0) {
                var kodebidang = '';
            }
            else {
                var kodebidang = kdbidang;
            }

            if (this.leveluser >= 4) {
                kode_subbidang = this.kdesubbidang;
            }
            this.halamanloading = true;
            const mainAPI2 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI2.get("morg_oripro-GetSubBidangInstitusi?kdorganisasi=" + kdorganisasi + "&kdbidang=" + kodebidang + "&kd=" + kode_subbidang + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasubbidang = [];
                        this.subbidang = [];
                        this.datasubbidang = Response.data.content.data;
                        this.datasubbidang.forEach((item) => {
                            this.subbidang.push({
                                label: item.nama,
                                code: this.datasubbidang.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });

                        if (this.leveluser < 4) {
                            this.subbidang.unshift({
                                label: 'Semua Data',
                                code: '0',
                            });

                            this.selectedsubbidang = 0;
                            this.defaultSelectedSubBidang = {
                                code: 0,
                                label: 'Semua Data',
                            };
                        }
                        if (this.leveluser >= 4) {
                            this.selectedsubbidang = this.kdebidang;
                            this.defaultSelectedSubBidang = {
                                code: this.kdebidang,
                                label: this.nmkdbidang,
                            };
                        }

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            })
        },
        onsubbidangChange(a) {
            try {
                this.selectedsubbidang = this.subbidang[a].tag;
                this.datapegawai = [];
                this.pegawai = [];
                this.AmbilData();

            }
            catch (err) {
                return;
            }
        },
        KosongkanSubBidang() {
            this.datasubbidang = [];
            this.subbidang = [];
            this.defaultSelectedSubBidang = {
                code: 0,
                label: 'Semua Data',
            };
            this.selectedsubbidang = 0;
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        dataeror() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Autentifikasi Tidak Valid..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        datatersimpan() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Tersimpan..',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        datakosong() {
            swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            }).fire({
                icon: 'warning',
                title: 'Data Kosong',
            });
            $('.swal2-container').css("z-index", '999999');
        },
        async AmbilData() {
            this.halamanloading = true;
            var kodebidang = this.selectedbidang;
            if ((kodebidang == 0) || (kodebidang === undefined)) {
                kodebidang = '';
            }
            var kodesubbidang = this.selectedsubbidang;
            if ((kodesubbidang == 0) || (kodesubbidang === undefined)) {
                kodesubbidang = '';
            }
            var kodelokasi = this.selectedlokasi;
            if ((kodelokasi == 0) || (kodelokasi === undefined)) {
                kodelokasi = '';
            }
            var kodegedung = this.selectedgedung;
            if ((kodegedung == 0) || (kodegedung === undefined)) {
                kodegedung = '';
            }
            var kodelantai = this.selectedlantai;
            if ((kodelantai == 0) || (kodelantai === undefined)) {
                kodelantai = '';
            }
            var koderuangan = this.selectedruangan;
            if ((koderuangan == 0) || (koderuangan === undefined)) {
                koderuangan = '';
            }

            this.datamaster = [];
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("dataasetpj_barang_oripro-GetPegawaiDataAset?kdbidang=" + kodebidang + "&kdsubbidang=" + kodesubbidang
                + "&kdlokasi=" + kodelokasi + "&kdgedung=" + kodegedung + "&kdlantai=" + kodelantai
                + "&kdruangan=" + koderuangan + "&random=" + random).then(
                    Response => {
                        this.datapesanmaster = Response.data.message;
                        if (this.datapesanmaster == 'data diketemukan') {
                            this.datapegawai = [];
                            this.pegawai = [];
                            this.datapegawai = Response.data.content.data;
                            this.datapegawai.forEach((item) => {
                                this.pegawai.push({
                                    label: item.nmpj_aset,
                                    code: this.datapegawai.indexOf(item) + 1,
                                    tag: item.pj_aset,
                                });
                            });
                            this.pegawai.unshift({
                                label: 'Pilih Data',
                                code: 0,
                                tag: 0,
                            });
                        }
                        this.defaultSelectedpegawai = {
                            code: 0,
                            label: 'Pilih Data',
                            tag: 0,
                        };
                        this.selectedpegawai = 0;
                        this.lihataktivitas(kodelokasi, kodebidang, kodesubbidang, this.selectedpegawai, koderuangan);
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    console.log(error);
                    this.ClearlocalStorage();
                    this.halamanloading = false;
                    //window.location.replace("/");
                })
        },
        lihataktivitas(lokasi, bidang, subbidang, pegawai, ruangan) {
            const message = this.linknya + '|' + lokasi + '|' + bidang + '|' + subbidang + '|' + pegawai + '|' + ruangan;
            const iframe = document.querySelector("iframe");
            this.sourcenya = '';
            this.sourcenya = './calendar.html';
            iframe.contentWindow.postMessage(message, "*");
        },
    },
    mounted() {
        this.selectedbidang = JSON.parse(localStorage.getItem("bdlsistem"));
        this.selectedsubbidang = JSON.parse(localStorage.getItem("sbdsistem"));
        this.getOtentifikasi();
        this.AmbilDataBidangTerpilih();
        this.AmbilData();
        this.halamanloading = false;
    }
}
</script>
<style></style>