<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <div class="modal fade" id="costumModaldetail" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
            aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
            style="margin: auto;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Data Informasi Lantai</h4>
                    </div>
                    <div class="modal-body text-justify">
                        <p>
                        <div class="row">
                            <div class="col-sm-12">
                                <label>Nama Lantai</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="far fa-building"></i></span>
                                    </div>
                                    <input type="hidden" class="form-control form-control-sm" id="kdlokasi"
                                        name="kdlokasi" placeholder="Kode Lokasi" v-model="kdlokasi" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="kdgedung"
                                        name="kdgedung" placeholder="Kode Gedung" v-model="kdgedung" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="kdlantai"
                                        name="kdlantai" placeholder="Kode Lantai" v-model="kdlantai" readonly>
                                    <input type="hidden" class="form-control form-control-sm" id="id_tabel_data_lantai"
                                        name="id_tabel_data_lantai" placeholder="Kode Tabel Lantai"
                                        v-model="id_tabel_data_lantai" readonly>
                                    <input type="text" class="form-control form-control-sm" id="nama" name="nama"
                                        placeholder="Nama Aset" v-model="nama" readonly>

                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Panjang Lantai (m)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-arrows-alt-v"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="panjang"
                                        name="panjang" placeholder="Panjang Lantai" v-model="panjang">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Lebar Lantai (m)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-arrows-alt-h"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="lebar" name="lebar"
                                        placeholder="Lebar Lantai" v-model="lebar">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Tinggi Lantai (m)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-ellipsis-v"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="tinggi" name="tinggi"
                                        placeholder="Tinggi Lantai" v-model="tinggi">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Luas Lantai (m<sup>2</sup>)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-expand"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="luas_m2"
                                        name="luas_m2" placeholder="Luas Lantai" v-model="luas_m2">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Bentuk Bangunan</label>
                                <v-select :options="bentuk_bangunan" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedbentuk_bangunan"
                                    @update:modelValue="onbentuk_bangunanChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Penggunaan Bangunan</label>
                                <v-select :options="status_penggunaan" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstatus_penggunaan"
                                    @update:modelValue="onstatus_penggunaanChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Atap</label>
                                <v-select :options="struktur_atap" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_atap" @update:modelValue="onstruktur_atapChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Atap</label>
                                <v-select :options="atap" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedatap" @update:modelValue="onatapChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Dinding</label>
                                <v-select :options="struktur_dinding" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_dinding"
                                    @update:modelValue="onstruktur_dindingChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Dinding</label>
                                <v-select :options="dinding" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelecteddinding" @update:modelValue="ondindingChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Lantai</label>
                                <v-select :options="struktur_lantai" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_lantai"
                                    @update:modelValue="onstruktur_lantaiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Lantai</label>
                                <v-select :options="lantai" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedlantai" @update:modelValue="onlantaiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Struktur Pondasi</label>
                                <v-select :options="struktur_pondasi" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedstruktur_pondasi"
                                    @update:modelValue="onstruktur_pondasiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Pondasi</label>
                                <v-select :options="pondasi" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedpondasi" @update:modelValue="onpondasiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Air Bersih</label>
                                <v-select :options="air_bersih" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedair_bersih" @update:modelValue="onair_bersihChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Pengelolaan Limbah</label>
                                <v-select :options="pengelolaan_limbah" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedpengelolaan_limbah"
                                    @update:modelValue="onpengelolaan_limbahChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Limbah</label>
                                <v-select :options="limbah" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedlimbah" @update:modelValue="onlimbahChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Pengelolaan Sampah</label>
                                <v-select :options="pengelolaan_sampah" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedpengelolaan_sampah"
                                    @update:modelValue="onpengelolaan_sampahChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Parkir</label>
                                <v-select :options="parkir" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedparkir" @update:modelValue="onparkirChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Luas Parkir (m<sup>2</sup>)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-expand"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="luas_parkir"
                                        name="luas_parkir" placeholder="Luas Parkir" v-model="luas_parkir">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Jumlah Ruangan</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-archway"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="jumlah_ruangan"
                                        name="jumlah_ruangan" placeholder="Jumlah Lantai" v-model="jumlah_ruangan">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Daya Listrik (Watt)</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fab fa-bandcamp"></i></span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm" id="daya_listrik"
                                        name="daya_listrik" placeholder="Daya Listrik" v-model="daya_listrik">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Kondisi</label>
                                <v-select :options="kondisi" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedkondisi" @update:modelValue="onkondisiChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Penanggungjawab Aset</label>
                                <v-select :options="pj_aset" :reduce="(label) => label.code" label="label"
                                    v-model="defaultSelectedpj_aset" @update:modelValue="onpj_asetChange"
                                    style="background-color: #ffffff;"></v-select>
                            </div>
                            <div class="col-sm-6">
                                <label>Tanggal Data</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-calendar-alt"></i></span>
                                    </div>
                                    <input type="date" class="form-control form-control-sm" id="tanggal_data"
                                        name="tanggal_data" placeholder="Tanggal Data" v-model="tanggal_data">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Catatan / Keterangan</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fas fa-chalkboard"></i></span>
                                    </div>
                                    <input type="text" class="form-control form-control-sm" id="catatan" name="catatan"
                                        placeholder="Catatan" v-model="catatan">
                                </div>
                            </div>
                        </div>
                        </p>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button type="button" class="btn btn-primary btn-sm lebar" @click="simpandata()"><i
                                class="fas fa-edit"></i>
                            Simpan</button>
                        <button type="button" class="btn btn-info btn-sm lebar" data-dismiss="modal"> <i
                                class="far fa-times-circle"></i>
                            Batal</button>
                    </div>
                </div>
                <!-- /.modal-content -->
            </div>
            <!-- /.modal-dialog -->
        </div>
        <!-- /.modal -->
        <div class="section right col-xs-12" v-if="halamanloading"
            style="height: 100%;  position: absolute;z-index: 9999;background-color: #6566aa;top: 0;width: 100%;opacity: 0.8;">
            <h1 class="text-warning blink_me"
                style="height: 50%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
                ..LOADING..
            </h1>
            <img src="../../../assets/img/favicon.png" class="blink_me"
                style="height: 30%;  position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);">
        </div>
        <div class="content-wrapper">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Manajemen Data Informasi Lantai</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                <li class="breadcrumb-item active">Data Informasi Lantai</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-8 float-left pb-2">
                                        <label>Pencarian</label>
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="col-sm-3 float-left pb-2">
                                        <label>Lokasi</label>
                                        <v-select :options="lokasi" :reduce="(label) => label.code" label="label"
                                            v-model="defaultSelectedLokasi" @update:modelValue="onlokasiChange"
                                            style="background-color: #ffffff;"></v-select>
                                    </div>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body p-3">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata">
                                            <thead>
                                                <tr>
                                                    <th style="width: 80px;">No</th>
                                                    <th>Lokasi</th>
                                                    <th style="width: 350px;">Informasi Lantai</th>
                                                    <th style="width: 100px;">Luas (m<sup>2</sup>)</th>
                                                    <th style="width: 100px;">Skoring</th>
                                                    <th style="width: 150px;">Penanggungjawab</th>
                                                    <th style="width: 130px;">Opsi</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr>
                                                        <td :class="datalist.class">{{ datalist.No }}</td>
                                                        <td :class="datalist.class">{{ datalist.nama }}</td>
                                                        <td :class="datalist.class + ' text-center'">
                                                            <span
                                                                :class="[(datalist.id_lantai != 'Informasi detail belum tersedia' ? 'badge badge-success' : 'badge badge-danger')]">
                                                                {{ datalist.id_lantai }}
                                                            </span>
                                                        </td>
                                                        <td :class="datalist.class + ' text-right'">
                                                            {{ G_numFormatKoma(datalist.luas_m2) }}
                                                        </td>
                                                        <td :class="datalist.class + ' text-right'">
                                                            {{ G_numKoma(datalist.skor) }}
                                                        </td>
                                                        <td :class="datalist.class">
                                                            {{ datalist.nmpj_aset }}
                                                        </td>
                                                        <td :class="datalist.class + ' text-center'">
                                                            <button type="button" @click="prosesdata(datalist.kdlokasi, datalist.kdgedung, datalist.kdlantai, datalist.id_tabel_data_lantai, datalist.nama
                                                                , datalist.panjang, datalist.lebar, datalist.tinggi, datalist.luas_m2
                                                                , datalist.bentuk_bangunan, datalist.nmbentuk_bangunan
                                                                , datalist.status_penggunaan, datalist.nmstatus_penggunaan
                                                                , datalist.struktur_atap, datalist.nmstruktur_atap
                                                                , datalist.atap, datalist.nmatap
                                                                , datalist.struktur_dinding, datalist.nmstruktur_dinding
                                                                , datalist.dinding, datalist.nmdinding
                                                                , datalist.struktur_lantai, datalist.nmstruktur_lantai
                                                                , datalist.lantai, datalist.nmlantai
                                                                , datalist.struktur_pondasi, datalist.nmstruktur_pondasi
                                                                , datalist.pondasi, datalist.nmpondasi
                                                                , datalist.air_bersih, datalist.nmair_bersih
                                                                , datalist.pengelolaan_limbah, datalist.nmpengelolaan_limbah
                                                                , datalist.limbah, datalist.nmlimbah
                                                                , datalist.pengelolaan_sampah, datalist.nmpengelolaan_sampah
                                                                , datalist.parkir, datalist.nmparkir
                                                                , datalist.luas_parkir, datalist.jumlah_ruangan, datalist.daya_listrik
                                                                , datalist.kondisi, datalist.nmkondisi
                                                                , datalist.pj_aset, datalist.nmpj_aset
                                                                , datalist.tanggal_data, datalist.catatan
                                                            )" class="btn btn-info btn-sm lebar text-white"
                                                                v-if="datalist.class == ''">
                                                                <i class="fas fa-edit"></i> Setting
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </table>
                                        <div class="col-md-12 text-center" v-if="(datapesanmaster == 'data kosong')">
                                            Data
                                            Masih Kosong</div>
                                    </div>
                                    <!-- /.table-responsive -->
                                </div>
                                <!-- /.card-body -->
                                <div class="card-footer clearfix">
                                    Jumlah Lokasi: {{ G_numFormat(JumlahfilteredKataData) }}
                                </div>
                                <!-- /.card-footer -->
                            </div>
                            <!-- /.card -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { inject } from 'vue';
import swal from 'sweetalert2';
import xlsx from 'xlsx/dist/xlsx.full.min';
import vSelect from 'vue-select';
import { Vue3ColorPicker } from '@cyhnkckali/vue3-color-picker';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, swal, vSelect, Vue3ColorPicker
    },
    data() {
        return {
            halamanloading: true,
            validasi: false,
            carikata: '',
            tmpdatamaster: [],
            datamaster: [],
            datapesan: [],
            datapesanmaster: [],
            ekportexcel: [],
            kdorganisasi: JSON.parse(localStorage.getItem("orsistem")),

            lokasi: [],
            datalokasi: [],
            defaultSelectedLokasi: {
                code: 0,
                label: 'Semua Data',
                tag: 0,
            },
            selectedlokasi: 0,
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            lokasiuser: JSON.parse(localStorage.getItem("loksistem")),
            nmlokasiuser: JSON.parse(localStorage.getItem("nmloksistem")),
            kodeaset: 0,
            namaaset: 0,
            kdlokasi: 0,
            kdgedung: 0,
            kdlantai: 0,
            id_tabel_data_lantai: '',
            nama: '',
            panjang: 0,
            lebar: 0,
            tinggi: 0,
            luas_m2: 0,

            bentuk_bangunan: [],
            databentuk_bangunan: [],
            defaultSelectedbentuk_bangunan: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedbentuk_bangunan: 0,

            status_penggunaan: [],
            datastatus_penggunaan: [],
            defaultSelectedstatus_penggunaan: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstatus_penggunaan: 0,

            struktur_atap: [],
            datastruktur_atap: [],
            defaultSelectedstruktur_atap: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_atap: 0,

            atap: [],
            dataatap: [],
            defaultSelectedatap: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedatap: 0,

            struktur_dinding: [],
            datastruktur_dinding: [],
            defaultSelectedstruktur_dinding: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_dinding: 0,

            dinding: [],
            datadinding: [],
            defaultSelecteddinding: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selecteddinding: 0,

            struktur_lantai: [],
            datastruktur_lantai: [],
            defaultSelectedstruktur_lantai: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_lantai: 0,

            lantai: [],
            datalantai: [],
            defaultSelectedlantai: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedlantai: 0,

            struktur_pondasi: [],
            datastruktur_pondasi: [],
            defaultSelectedstruktur_pondasi: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedstruktur_pondasi: 0,

            pondasi: [],
            datapondasi: [],
            defaultSelectedpondasi: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpondasi: 0,

            air_bersih: [],
            dataair_bersih: [],
            defaultSelectedair_bersih: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedair_bersih: 0,

            pengelolaan_limbah: [],
            datapengelolaan_limbah: [],
            defaultSelectedpengelolaan_limbah: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpengelolaan_limbah: 0,

            limbah: [],
            datalimbah: [],
            defaultSelectedlimbah: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedlimbah: 0,

            pengelolaan_sampah: [],
            datapengelolaan_sampah: [],
            defaultSelectedpengelolaan_sampah: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpengelolaan_sampah: 0,

            parkir: [],
            dataparkir: [],
            defaultSelectedparkir: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedparkir: 0,
            luas_parkir: 0,
            jumlah_ruangan: 0,
            daya_listrik: 0,

            kondisi: [],
            datakondisi: [],
            defaultSelectedkondisi: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedkondisi: 0,

            pj_aset: [],
            datapj_aset: [],
            defaultSelectedpj_aset: {
                code: 0,
                label: 'Pilih Data',
                tag: 0,
            },
            selectedpj_aset: 0,
            tanggal_data: '',
            catatan: '',

        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        onlokasiChange(a) {
            try {
                this.selectedlokasi = this.lokasi[a].tag;
                this.AmbilData();
            }
            catch (err) {
                return;
            }
        },
        onbentuk_bangunanChange(a) {
            try {
                this.selectedbentuk_bangunan = this.bentuk_bangunan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstatus_penggunaanChange(a) {
            try {
                this.selectedstatus_penggunaan = this.status_penggunaan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_atapChange(a) {
            try {
                this.selectedstruktur_atap = this.struktur_atap[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onatapChange(a) {
            try {
                this.selectedatap = this.atap[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_dindingChange(a) {
            try {
                this.selectedstruktur_dinding = this.struktur_dinding[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ondindingChange(a) {
            try {
                this.selecteddinding = this.dinding[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_lantaiChange(a) {
            try {
                this.selectedstruktur_lantai = this.struktur_lantai[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onlantaiChange(a) {
            try {
                this.selectedlantai = this.lantai[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onstruktur_pondasiChange(a) {
            try {
                this.selectedstruktur_pondasi = this.struktur_pondasi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpondasiChange(a) {
            try {
                this.selectedpondasi = this.pondasi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onair_bersihChange(a) {
            try {
                this.selectedair_bersih = this.air_bersih[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpengelolaan_limbahChange(a) {
            try {
                this.selectedpengelolaan_limbah = this.pengelolaan_limbah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onlimbahChange(a) {
            try {
                this.selectedlimbah = this.limbah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpengelolaan_sampahChange(a) {
            try {
                this.selectedpengelolaan_sampah = this.pengelolaan_sampah[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onparkirChange(a) {
            try {
                this.selectedparkir = this.parkir[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onkondisiChange(a) {
            try {
                this.selectedkondisi = this.kondisi[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onpj_asetChange(a) {
            try {
                this.selectedpj_aset = this.pj_aset[a].tag;
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async AmbilData() {
            this.halamanloading = true;
            var kodelokasi = '';
            if ((this.selectedlokasi != 0) && (this.selectedlokasi != undefined)) {
                kodelokasi = this.selectedlokasi;
            }
            if (this.leveluser >= 4) {
                kodelokasi = this.lokasiuser;
            }
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("datalantai_oripro-GetDataLantai?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesanmaster = Response.data.message;
                    if (this.datapesanmaster == 'data diketemukan') {
                        this.tmpdatamaster = [];
                        this.tmpdatamaster = Response.data.content.data;
                    }

                    this.datamaster = [];
                    for (let i = 0; i < this.tmpdatamaster.length; i++) {
                        this.datamaster.push({
                            "No": i + 1,
                            "id": this.tmpdatamaster[i].id,
                            "kdlokasi": this.tmpdatamaster[i].kdlokasi,
                            "nama": this.tmpdatamaster[i].nama,
                            "tabel": this.tmpdatamaster[i].tabel,
                            "class": this.tmpdatamaster[i].class,
                            "id_lantai": '',
                            "id_tabel_data_lantai": '',
                            "kdgedung": '',
                            "panjang": '',
                            "lebar": '',
                            "tinggi": '',
                            "luas_m2": this.tmpdatamaster[i].luas_m2,
                            "bentuk_bangunan": '',
                            "nmbentuk_bangunan": '',
                            "status_penggunaan": '',
                            "nmstatus_penggunaan": '',
                            "struktur_atap": '',
                            "nmstruktur_atap": '',
                            "atap": '',
                            "nmatap": '',
                            "struktur_dinding": '',
                            "nmstruktur_dinding": '',
                            "dinding": '',
                            "nmdinding": '',
                            "struktur_lantai": '',
                            "nmstruktur_lantai": '',
                            "lantai": '',
                            "nmlantai": '',
                            "struktur_pondasi": '',
                            "nmstruktur_pondasi": '',
                            "pondasi": '',
                            "nmpondasi": '',
                            "air_bersih": '',
                            "nmair_bersih": '',
                            "pengelolaan_limbah": '',
                            "nmpengelolaan_limbah": '',
                            "limbah": '',
                            "nmlimbah": '',
                            "pengelolaan_sampah": '',
                            "nmpengelolaan_sampah": '',
                            "parkir": '',
                            "nmparkir": '',
                            "luas_parkir": '',
                            "jumlah_ruangan": '',
                            "daya_listrik": '',
                            "kondisi": '',
                            "nmkondisi": '',
                            "pj_aset": '',
                            "nmpj_aset": '',
                            "tanggal_data": '',
                            "catatan": '',
                            "skor": this.tmpdatamaster[i].skor,
                        })
                        for (let z = 0; z < this.tmpdatamaster[i].gedung.length; z++) {
                            this.datamaster.push({
                                "No": (i + 1) + "." + (z + 1),
                                "id": this.tmpdatamaster[i].gedung[z].id,
                                "kdlokasi": this.tmpdatamaster[i].gedung[z].kdlokasi,
                                "nama": this.tmpdatamaster[i].gedung[z].nama,
                                "tabel": this.tmpdatamaster[i].gedung[z].tabel,
                                "class": this.tmpdatamaster[i].gedung[z].class,
                                "id_lantai": '',
                                "id_tabel_data_lantai": '',
                                "kdgedung": '',
                                "panjang": '',
                                "lebar": '',
                                "tinggi": '',
                                "luas_m2": this.tmpdatamaster[i].gedung[z].luas_m2,
                                "bentuk_bangunan": '',
                                "nmbentuk_bangunan": '',
                                "status_penggunaan": '',
                                "nmstatus_penggunaan": '',
                                "struktur_atap": '',
                                "nmstruktur_atap": '',
                                "atap": '',
                                "nmatap": '',
                                "struktur_dinding": '',
                                "nmstruktur_dinding": '',
                                "dinding": '',
                                "nmdinding": '',
                                "struktur_lantai": '',
                                "nmstruktur_lantai": '',
                                "lantai": '',
                                "nmlantai": '',
                                "struktur_pondasi": '',
                                "nmstruktur_pondasi": '',
                                "pondasi": '',
                                "nmpondasi": '',
                                "air_bersih": '',
                                "nmair_bersih": '',
                                "pengelolaan_limbah": '',
                                "nmpengelolaan_limbah": '',
                                "limbah": '',
                                "nmlimbah": '',
                                "pengelolaan_sampah": '',
                                "nmpengelolaan_sampah": '',
                                "parkir": '',
                                "nmparkir": '',
                                "luas_parkir": '',
                                "jumlah_ruangan": '',
                                "daya_listrik": '',
                                "kondisi": '',
                                "nmkondisi": '',
                                "pj_aset": this.tmpdatamaster[i].gedung[z].pj_aset,
                                "nmpj_aset": this.tmpdatamaster[i].gedung[z].nmpj_aset,
                                "tanggal_data": '',
                                "catatan": '',
                                "skor": this.tmpdatamaster[i].gedung[z].skor,
                            })
                            for (let m = 0; m < this.tmpdatamaster[i].gedung[z].lantai.length; m++) {
                                this.datamaster.push({
                                    "No": (i + 1) + "." + (z + 1) + "." + (m + 1),
                                    "id": this.tmpdatamaster[i].gedung[z].lantai[m].id,
                                    "kdlokasi": this.tmpdatamaster[i].gedung[z].lantai[m].kdlokasi,
                                    "kdgedung": this.tmpdatamaster[i].gedung[z].lantai[m].kdgedung,
                                    "kdlantai": this.tmpdatamaster[i].gedung[z].lantai[m].kdlantai,
                                    "nama": this.tmpdatamaster[i].gedung[z].lantai[m].nama,
                                    "tabel": this.tmpdatamaster[i].gedung[z].lantai[m].tabel,
                                    "class": this.tmpdatamaster[i].gedung[z].lantai[m].class,
                                    "id_lantai": this.tmpdatamaster[i].gedung[z].lantai[m].id_lantai,
                                    "id_tabel_data_lantai": this.tmpdatamaster[i].gedung[z].lantai[m].id_tabel_data_lantai,
                                    "kdgedung": this.tmpdatamaster[i].gedung[z].lantai[m].kdgedung,
                                    "panjang": this.tmpdatamaster[i].gedung[z].lantai[m].panjang,
                                    "lebar": this.tmpdatamaster[i].gedung[z].lantai[m].lebar,
                                    "tinggi": this.tmpdatamaster[i].gedung[z].lantai[m].tinggi,
                                    "luas_m2": this.tmpdatamaster[i].gedung[z].lantai[m].luas_m2,
                                    "bentuk_bangunan": this.tmpdatamaster[i].gedung[z].lantai[m].bentuk_bangunan,
                                    "nmbentuk_bangunan": this.tmpdatamaster[i].gedung[z].lantai[m].nmbentuk_bangunan,
                                    "status_penggunaan": this.tmpdatamaster[i].gedung[z].lantai[m].status_penggunaan,
                                    "nmstatus_penggunaan": this.tmpdatamaster[i].gedung[z].lantai[m].nmstatus_penggunaan,
                                    "struktur_atap": this.tmpdatamaster[i].gedung[z].lantai[m].struktur_atap,
                                    "nmstruktur_atap": this.tmpdatamaster[i].gedung[z].lantai[m].nmstruktur_atap,
                                    "atap": this.tmpdatamaster[i].gedung[z].lantai[m].atap,
                                    "nmatap": this.tmpdatamaster[i].gedung[z].lantai[m].nmatap,
                                    "struktur_dinding": this.tmpdatamaster[i].gedung[z].lantai[m].struktur_dinding,
                                    "nmstruktur_dinding": this.tmpdatamaster[i].gedung[z].lantai[m].nmstruktur_dinding,
                                    "dinding": this.tmpdatamaster[i].gedung[z].lantai[m].dinding,
                                    "nmdinding": this.tmpdatamaster[i].gedung[z].lantai[m].nmdinding,
                                    "struktur_lantai": this.tmpdatamaster[i].gedung[z].lantai[m].struktur_lantai,
                                    "nmstruktur_lantai": this.tmpdatamaster[i].gedung[z].lantai[m].nmstruktur_lantai,
                                    "lantai": this.tmpdatamaster[i].gedung[z].lantai[m].lantai,
                                    "nmlantai": this.tmpdatamaster[i].gedung[z].lantai[m].nmlantai,
                                    "struktur_pondasi": this.tmpdatamaster[i].gedung[z].lantai[m].struktur_pondasi,
                                    "nmstruktur_pondasi": this.tmpdatamaster[i].gedung[z].lantai[m].nmstruktur_pondasi,
                                    "pondasi": this.tmpdatamaster[i].gedung[z].lantai[m].pondasi,
                                    "nmpondasi": this.tmpdatamaster[i].gedung[z].lantai[m].nmpondasi,
                                    "air_bersih": this.tmpdatamaster[i].gedung[z].lantai[m].air_bersih,
                                    "nmair_bersih": this.tmpdatamaster[i].gedung[z].lantai[m].nmair_bersih,
                                    "pengelolaan_limbah": this.tmpdatamaster[i].gedung[z].lantai[m].pengelolaan_limbah,
                                    "nmpengelolaan_limbah": this.tmpdatamaster[i].gedung[z].lantai[m].nmpengelolaan_limbah,
                                    "limbah": this.tmpdatamaster[i].gedung[z].lantai[m].limbah,
                                    "nmlimbah": this.tmpdatamaster[i].gedung[z].lantai[m].nmlimbah,
                                    "pengelolaan_sampah": this.tmpdatamaster[i].gedung[z].lantai[m].pengelolaan_sampah,
                                    "nmpengelolaan_sampah": this.tmpdatamaster[i].gedung[z].lantai[m].nmpengelolaan_sampah,
                                    "parkir": this.tmpdatamaster[i].gedung[z].lantai[m].parkir,
                                    "nmparkir": this.tmpdatamaster[i].gedung[z].lantai[m].nmparkir,
                                    "luas_parkir": this.tmpdatamaster[i].gedung[z].lantai[m].luas_parkir,
                                    "jumlah_ruangan": this.tmpdatamaster[i].gedung[z].lantai[m].jumlah_ruangan,
                                    "daya_listrik": this.tmpdatamaster[i].gedung[z].lantai[m].daya_listrik,
                                    "kondisi": this.tmpdatamaster[i].gedung[z].lantai[m].kondisi,
                                    "nmkondisi": this.tmpdatamaster[i].gedung[z].lantai[m].nmkondisi,
                                    "pj_aset": this.tmpdatamaster[i].gedung[z].lantai[m].pj_aset,
                                    "nmpj_aset": this.tmpdatamaster[i].gedung[z].lantai[m].nmpj_aset,
                                    "tanggal_data": this.tmpdatamaster[i].gedung[z].lantai[m].tanggal_data,
                                    "catatan": this.tmpdatamaster[i].gedung[z].lantai[m].catatan,
                                    "skor": this.tmpdatamaster[i].gedung[z].lantai[m].skor,
                                })
                            }
                        }
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            this.halamanloading = true;
            if (this.leveluser < 4) {
                kodelokasi = '';
            }
            const mainAPI4 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI4.get("m_oripro-GetLokasi?kdlokasi=" + kodelokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalokasi = [];
                        this.lokasi = [];
                        this.datalokasi = Response.data.content.data;
                        this.datalokasi.forEach((item) => {
                            this.lokasi.push({
                                label: item.nama,
                                code: this.datalokasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        if (this.leveluser < 4) {
                            this.lokasi.unshift({
                                label: 'Semua Data',
                                code: 0,
                                tag: 0,
                            });
                        }
                        if (this.leveluser >= 4) {
                            this.defaultSelectedLokasi = {
                                code: this.lokasiuser,
                                label: this.nmlokasiuser,
                                tag: this.lokasiuser,
                            };
                            this.selectedlokas = this.lokasiuser;
                        }

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
            const mainAPI5 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI5.get("minfsipil_oripro-GetBentukBangunan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databentuk_bangunan = [];
                        this.bentuk_bangunan = [];
                        this.databentuk_bangunan = Response.data.content.data;
                        this.databentuk_bangunan.forEach((item) => {
                            this.bentuk_bangunan.push({
                                label: item.nama,
                                code: this.databentuk_bangunan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.bentuk_bangunan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI6 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI6.get("minfsipil_oripro-GetStatusPenggunaan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastatus_penggunaan = [];
                        this.status_penggunaan = [];
                        this.datastatus_penggunaan = Response.data.content.data;
                        this.datastatus_penggunaan.forEach((item) => {
                            this.status_penggunaan.push({
                                label: item.nama,
                                code: this.datastatus_penggunaan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.status_penggunaan.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI7 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI7.get("minfsipil_oripro-GetStrukturBangunan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datastruktur_atap = [];
                        this.struktur_atap = [];
                        this.datastruktur_atap = Response.data.content.data;
                        this.datastruktur_atap.forEach((item) => {
                            this.struktur_atap.push({
                                label: item.nama,
                                code: this.datastruktur_atap.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_atap.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.dataatap = [];
                        this.atap = [];
                        this.dataatap = Response.data.content.data;
                        this.dataatap.forEach((item) => {
                            this.atap.push({
                                label: item.nama,
                                code: this.dataatap.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.atap.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datastruktur_dinding = [];
                        this.struktur_dinding = [];
                        this.datastruktur_dinding = Response.data.content.data;
                        this.datastruktur_dinding.forEach((item) => {
                            this.struktur_dinding.push({
                                label: item.nama,
                                code: this.datastruktur_dinding.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_dinding.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datadinding = [];
                        this.dinding = [];
                        this.datadinding = Response.data.content.data;
                        this.datadinding.forEach((item) => {
                            this.dinding.push({
                                label: item.nama,
                                code: this.datadinding.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.dinding.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datastruktur_lantai = [];
                        this.struktur_lantai = [];
                        this.datastruktur_lantai = Response.data.content.data;
                        this.datastruktur_lantai.forEach((item) => {
                            this.struktur_lantai.push({
                                label: item.nama,
                                code: this.datastruktur_lantai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_lantai.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datalantai = [];
                        this.lantai = [];
                        this.datalantai = Response.data.content.data;
                        this.datalantai.forEach((item) => {
                            this.lantai.push({
                                label: item.nama,
                                code: this.datalantai.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.lantai.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datastruktur_pondasi = [];
                        this.struktur_pondasi = [];
                        this.datastruktur_pondasi = Response.data.content.data;
                        this.datastruktur_pondasi.forEach((item) => {
                            this.struktur_pondasi.push({
                                label: item.nama,
                                code: this.datastruktur_pondasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.struktur_pondasi.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datapondasi = [];
                        this.pondasi = [];
                        this.datapondasi = Response.data.content.data;
                        this.datapondasi.forEach((item) => {
                            this.pondasi.push({
                                label: item.nama,
                                code: this.datapondasi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.pondasi.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI8 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI8.get("minfsipil_oripro-GetAirBersih?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.dataair_bersih = [];
                        this.air_bersih = [];
                        this.dataair_bersih = Response.data.content.data;
                        this.dataair_bersih.forEach((item) => {
                            this.air_bersih.push({
                                label: item.nama,
                                code: this.dataair_bersih.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.air_bersih.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI9 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI9.get("m_eksist_oripro-GetStatAda?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapengelolaan_limbah = [];
                        this.pengelolaan_limbah = [];
                        this.datapengelolaan_limbah = Response.data.content.data;
                        this.datapengelolaan_limbah.forEach((item) => {
                            this.pengelolaan_limbah.push({
                                label: item.nama,
                                code: this.datapengelolaan_limbah.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.pengelolaan_limbah.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.datapengelolaan_sampah = [];
                        this.pengelolaan_sampah = [];
                        this.datapengelolaan_sampah = Response.data.content.data;
                        this.datapengelolaan_sampah.forEach((item) => {
                            this.pengelolaan_sampah.push({
                                label: item.nama,
                                code: this.datapengelolaan_sampah.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.pengelolaan_sampah.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                        this.dataparkir = [];
                        this.parkir = [];
                        this.dataparkir = Response.data.content.data;
                        this.dataparkir.forEach((item) => {
                            this.parkir.push({
                                label: item.nama,
                                code: this.dataparkir.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.parkir.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI10 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI10.get("minfsipil_oripro-GetLimbah?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalimbah = [];
                        this.limbah = [];
                        this.datalimbah = Response.data.content.data;
                        this.datalimbah.forEach((item) => {
                            this.limbah.push({
                                label: item.nama,
                                code: this.datalimbah.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.limbah.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            const mainAPI11 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI11.get("mb_oripro-GetKondisiBarang?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datakondisi = [];
                        this.kondisi = [];
                        this.datakondisi = Response.data.content.data;
                        this.datakondisi.forEach((item) => {
                            this.kondisi.push({
                                label: item.nama,
                                code: this.datakondisi.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.kondisi.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        prosesdata(kdlokasi, kdgedung, kdlantai, id_tabel_data_lantai, nama, panjang, lebar, tinggi, luas_m2, bentuk_bangunan, nmbentuk_bangunan
            , status_penggunaan, nmstatus_penggunaan, struktur_atap, nmstruktur_atap, atap, nmatap
            , struktur_dinding, nmstruktur_dinding, dinding, nmdinding
            , struktur_lantai, nmstruktur_lantai, lantai, nmlantai
            , struktur_pondasi, nmstruktur_pondasi, pondasi, nmpondasi, air_bersih, nmair_bersih
            , pengelolaan_limbah, nmpengelolaan_limbah, limbah, nmlimbah
            , pengelolaan_sampah, nmpengelolaan_sampah, parkir, nmparkir
            , luas_parkir, jumlah_ruangan, daya_listrik, kondisi, nmkondisi, pj_aset, nmpj_aset
            , tanggal_data, catatan) {
            this.kdlokasi = kdlokasi;
            this.kdlantai = kdlantai;
            this.kdgedung = kdgedung;
            this.id_tabel_data_lantai = id_tabel_data_lantai;
            this.nama = nama;
            this.panjang = panjang;
            this.lebar = lebar;
            this.tinggi = tinggi;
            this.luas_m2 = luas_m2;
            this.defaultSelectedbentuk_bangunan = {
                code: bentuk_bangunan,
                label: nmbentuk_bangunan,
                tag: bentuk_bangunan,
            };
            this.selectedbentuk_bangunan = bentuk_bangunan;

            this.defaultSelectedstatus_penggunaan = {
                code: status_penggunaan,
                label: nmstatus_penggunaan,
                tag: status_penggunaan,
            };
            this.selectedstatus_penggunaan = status_penggunaan;

            this.defaultSelectedstruktur_atap = {
                code: struktur_atap,
                label: nmstruktur_atap,
                tag: struktur_atap,
            };
            this.selectedstruktur_atap = struktur_atap;

            this.defaultSelectedatap = {
                code: atap,
                label: nmatap,
                tag: atap,
            };
            this.selectedatap = atap;

            this.defaultSelectedstruktur_dinding = {
                code: struktur_dinding,
                label: nmstruktur_dinding,
                tag: struktur_dinding,
            };
            this.selectedstruktur_dinding = struktur_dinding;

            this.defaultSelecteddinding = {
                code: dinding,
                label: nmdinding,
                tag: dinding,
            };
            this.selecteddinding = dinding;

            this.defaultSelectedstruktur_lantai = {
                code: struktur_lantai,
                label: nmstruktur_lantai,
                tag: struktur_lantai,
            };
            this.selectedstruktur_lantai = struktur_lantai;

            this.defaultSelectedlantai = {
                code: lantai,
                label: nmlantai,
                tag: lantai,
            };
            this.selectedlantai = lantai;

            this.defaultSelectedstruktur_pondasi = {
                code: struktur_pondasi,
                label: nmstruktur_pondasi,
                tag: struktur_pondasi,
            };
            this.selectedstruktur_pondasi = struktur_pondasi;

            this.defaultSelectedpondasi = {
                code: pondasi,
                label: nmpondasi,
                tag: pondasi,
            };
            this.selectedpondasi = pondasi;

            this.defaultSelectedair_bersih = {
                code: air_bersih,
                label: nmair_bersih,
                tag: air_bersih,
            };
            this.selectedair_bersih = air_bersih;

            this.defaultSelectedpengelolaan_limbah = {
                code: pengelolaan_limbah,
                label: nmpengelolaan_limbah,
                tag: pengelolaan_limbah,
            };
            this.selectedpengelolaan_limbah = pengelolaan_limbah;

            this.defaultSelectedlimbah = {
                code: limbah,
                label: nmlimbah,
                tag: limbah,
            };
            this.selectedlimbah = limbah;

            this.defaultSelectedpengelolaan_sampah = {
                code: pengelolaan_sampah,
                label: nmpengelolaan_sampah,
                tag: pengelolaan_sampah,
            };
            this.selectedpengelolaan_sampah = pengelolaan_sampah;

            this.defaultSelectedparkir = {
                code: parkir,
                label: nmparkir,
                tag: parkir,
            };
            this.selectedparkir = parkir;

            this.luas_parkir = luas_parkir;
            this.jumlah_ruangan = jumlah_ruangan;
            this.daya_listrik = daya_listrik;

            this.defaultSelectedkondisi = {
                code: kondisi,
                label: nmkondisi,
                tag: kondisi,
            };
            this.selectedkondisi = kondisi;

            const mainAPI11 = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI11.get("m_peg_oripro-GetPegawaiNonSuperAdmin?kdlokasi=" + kdlokasi + "&random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datapj_aset = [];
                        this.pj_aset = [];
                        this.datapj_aset = Response.data.content.data;
                        this.datapj_aset.forEach((item) => {
                            this.pj_aset.push({
                                label: item.nama,
                                code: this.datapj_aset.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.pj_aset.unshift({
                            label: 'Pilih Data',
                            code: 0,
                            tag: 0,
                        });

                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });

            this.defaultSelectedpj_aset = {
                code: pj_aset,
                label: nmpj_aset,
                tag: pj_aset,
            };
            this.selectedpj_aset = pj_aset;
            this.tanggal_data = tanggal_data;
            this.catatan = catatan;

            $('#costumModaldetail').modal('show');
        },
        validasidata() {
            if (document.getElementById('nama').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('nama').focus();
                return false;
            }
            if ((document.getElementById('panjang').value == '') || (document.getElementById('panjang').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('panjang').focus();
                return false;
            }
            if ((document.getElementById('lebar').value == '') || (document.getElementById('lebar').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('lebar').focus();
                return false;
            }
            if ((document.getElementById('tinggi').value == '') || (document.getElementById('tinggi').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tinggi').focus();
                return false;
            }
            if ((document.getElementById('luas_m2').value == '') || (document.getElementById('luas_m2').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('luas_m2').focus();
                return false;
            }
            if ((this.selectedbentuk_bangunan === undefined) || (this.selectedbentuk_bangunan == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bentuk Bangunan',
                });
                return false;
            }
            if ((this.selectedstatus_penggunaan === undefined) || (this.selectedstatus_penggunaan == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Status Penggunaan Bangunan',
                });
                return false;
            }
            if ((this.selectedstruktur_atap === undefined) || (this.selectedstruktur_atap == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Atap',
                });
                return false;
            }
            if ((this.selectedatap === undefined) || (this.selectedatap == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Atap Bangunan',
                });
                return false;
            }
            if ((this.selectedstruktur_dinding === undefined) || (this.selectedstruktur_dinding == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Dinding',
                });
                return false;
            }
            if ((this.selecteddinding === undefined) || (this.selecteddinding == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Dinding Bangunan',
                });
                return false;
            }
            if ((this.selectedstruktur_lantai === undefined) || (this.selectedstruktur_lantai == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Lantai',
                });
                return false;
            }
            if ((this.selectedlantai === undefined) || (this.selectedlantai == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Lantai Bangunan',
                });
                return false;
            }
            if ((this.selectedstruktur_pondasi === undefined) || (this.selectedstruktur_pondasi == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Struktur Pondasi',
                });
                return false;
            }
            if ((this.selectedpondasi === undefined) || (this.selectedpondasi == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Bahan Pondasi Bangunan',
                });
                return false;
            }
            if ((this.selectedair_bersih === undefined) || (this.selectedair_bersih == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Sumber Air Bersih',
                });
                return false;
            }
            if ((this.selectedpengelolaan_limbah === undefined) || (this.selectedpengelolaan_limbah == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Pengelolaan Limbah',
                });
                return false;
            }
            if ((this.selectedlimbah === undefined) || (this.selectedlimbah == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Limbah',
                });
                return false;
            }
            if ((this.selectedpengelolaan_sampah === undefined) || (this.selectedpengelolaan_sampah == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Pengelolaan Sampah',
                });
                return false;
            }
            if ((this.selectedparkir === undefined) || (this.selectedparkir == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Keberadaan Parkir',
                });
                return false;
            }
            if (document.getElementById('luas_parkir').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Luas Parkir',
                });
                document.getElementById('luas_parkir').focus();
                return false;
            }
            if ((document.getElementById('jumlah_ruangan').value == '') || (document.getElementById('jumlah_ruangan').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Jumlah Lantai',
                });
                document.getElementById('jumlah_ruangan').focus();
                return false;
            }
            if ((document.getElementById('daya_listrik').value == '') || (document.getElementById('daya_listrik').value == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Daya Listrik',
                });
                document.getElementById('daya_listrik').focus();
                return false;
            }
            if ((this.selectedkondisi === undefined) || (this.selectedkondisi == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Kondisi Bangunan',
                });
                return false;
            }
            if ((this.selectedpj_aset === undefined) || (this.selectedpj_aset == 0)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Penanggungjawab Bangunan',
                });
                return false;
            }
            if (document.getElementById('tanggal_data').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Tanggal Data',
                });
                document.getElementById('tanggal_data').focus();
                return false;
            }
            this.validasi = true;
            return;
        },
        simpandata() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }


            var fd = new FormData();
            fd.append("id", this.id_tabel_data_lantai);
            fd.append("lokasi", this.kdlokasi);
            fd.append("gedung", this.kdgedung);
            fd.append("kd_lantai", this.kdlantai);
            fd.append("id_tabel_data_lantai", this.id_tabel_data_lantai);
            fd.append("nama", this.nama);
            fd.append("panjang", this.panjang.toString().replaceAll(",", "."));
            fd.append("lebar", this.lebar.toString().replaceAll(",", "."));
            fd.append("tinggi", this.tinggi.toString().replaceAll(",", "."));
            fd.append("luas_m2", this.luas_m2.toString().replaceAll(",", "."));
            fd.append("bentuk_bangunan", this.selectedbentuk_bangunan);
            fd.append("status_penggunaan", this.selectedstatus_penggunaan);
            fd.append("struktur_atap", this.selectedstruktur_atap);
            fd.append("atap", this.selectedatap);
            fd.append("struktur_dinding", this.selectedstruktur_dinding);
            fd.append("dinding", this.selecteddinding);
            fd.append("struktur_lantai", this.selectedstruktur_lantai);
            fd.append("lantai", this.selectedlantai);
            fd.append("struktur_pondasi", this.selectedstruktur_pondasi);
            fd.append("pondasi", this.selectedpondasi);
            fd.append("air_bersih", this.selectedair_bersih);
            fd.append("pengelolaan_limbah", this.selectedpengelolaan_limbah);
            fd.append("limbah", this.selectedlimbah);
            fd.append("pengelolaan_sampah", this.selectedpengelolaan_sampah);
            fd.append("parkir", this.selectedparkir);
            fd.append("luas_parkir", this.luas_parkir.toString().replaceAll(",", "."));
            fd.append("jumlah_ruangan", this.jumlah_ruangan.toString().replaceAll(",", "."));
            fd.append("daya_listrik", this.daya_listrik.toString().replaceAll(",", "."));
            fd.append("kondisi", this.selectedkondisi);
            fd.append("pj_aset", this.selectedpj_aset);
            fd.append("tanggal_data", this.tanggal_data);
            fd.append("catatan", this.catatan);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));

            if (this.id_tabel_data_lantai != '') {
                swal.fire({
                    title: "Konfirmasi",
                    text: "Data sudah ada, simpan sebagai history data?",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonText: "Update Data",
                    confirmButtonColor: "#33bff2",
                    cancelButtonColor: "#6566aa",
                    confirmButtonText: "Simpan History Data"
                }).then((result) => {
                    if (result.isConfirmed) {
                        const mainAPI = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                "Content-Type": "form-data",
                                Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            },
                        });
                        mainAPI.post("datalantai_oripro-AddDataLantai", fd).then(
                            Response => {
                                if (Response.data.response == 'error') {
                                    swal.fire('Peringatan', Response.data.message, 'error');
                                    $('.swal2-container').css("z-index", '999999');
                                }
                                else {
                                    swal.fire('Informasi', Response.data.message, 'success').then(function () {
                                        window.location.replace("/DataLantai");
                                    });
                                    $('.swal2-container').css("z-index", '999999');
                                }
                            }
                        ).catch(function (error) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: error
                            });
                        }
                        )
                    }
                    else {
                        const mainAPI = axios.create({
                            baseURL: process.env.VUE_APP_URL_API,
                            headers: {
                                "Content-Type": "form-data",
                                Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            },
                        });
                        mainAPI.post("datalantai_oripro-UpdateDataLantai", fd).then(
                            Response => {
                                if (Response.data.response == 'error') {
                                    swal.fire('Peringatan', Response.data.message, 'error');
                                    $('.swal2-container').css("z-index", '999999');
                                }
                                else {
                                    swal.fire('Informasi', Response.data.message, 'success').then(function () {
                                        window.location.replace("/DataLantai");
                                    });
                                    $('.swal2-container').css("z-index", '999999');
                                }
                            }
                        ).catch(function (error) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: error
                            });
                        }
                        )
                    }
                });
                $('.swal2-container').css("z-index", '999999');
            } else {
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("datalantai_oripro-AddDataLantai", fd).then(
                    Response => {
                        if (Response.data.response == 'error') {
                            swal.fire('Peringatan', Response.data.message, 'error');
                            $('.swal2-container').css("z-index", '999999');
                        }
                        else {
                            swal.fire('Informasi', Response.data.message, 'success').then(function () {
                                window.location.replace("/DataLantai");
                            });
                            $('.swal2-container').css("z-index", '999999');
                        }
                    }
                ).catch(function (error) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error
                    });
                }
                )
            }
            return false;
        },
    },
    mounted() {
        this.selectedbidang = JSON.parse(localStorage.getItem("bdlsistem"));
        this.selectedsubbidang = JSON.parse(localStorage.getItem("sbdsistem"));
        this.getOtentifikasi();
        this.AmbilData();
    }
}
</script>
<style></style>